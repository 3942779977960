import { atom } from 'jotai';
import { Result } from 'src/services/types';
import { inactiveSignup } from 'src/services/user';
import { getSubscriptionPlans } from 'src/services/metering';
import { CreateInactiveRequest } from 'src/services/user/inactive-signup/types';
import { setUserSessionAtom } from './setUserSessionAtom';
import { setUserSingleValueAtom } from './setUserSingleValueAtom';
import { useNativeCommunication } from 'src/hooks';

const createInactiveUserAtom = atom(
  null,
  async (_get, set, inactiveSignupPayload: CreateInactiveRequest): Promise<Result> => {
    // user.partnerId is set when the user enters the flow with an invite, otherwhise we default to the Bizcuit ID.

    const result = await inactiveSignup(inactiveSignupPayload);
    const { accessToken, user, userSession, success, error } = result;
    const { isNativeApp, setNativeAppUser } = useNativeCommunication();

    if (success && userSession && user && accessToken) {
      set(setUserSessionAtom, user, userSession, accessToken);

      if (isNativeApp()) setNativeAppUser(user);

      const { data: subsPlans } = await getSubscriptionPlans();
      set(setUserSingleValueAtom, 'subscription', subsPlans?.[0]);
    }

    return { success, error };
  },
);

export { createInactiveUserAtom };
