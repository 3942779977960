import { CustomIconProps } from '../icons.types';

// TODO: Refactor these loading components
const Succeeded = ({ widthClassName = 'w-32', heightClassName = 'h-32' }: CustomIconProps) => (
  <div className={`relative rounded-full ${widthClassName} ${heightClassName}`}>
    <svg
      className={`absolute ${widthClassName} ${heightClassName}`}
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.6667 52L59.0833 78.5833L47 66.5"
        stroke="#003A1E"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default Succeeded;
