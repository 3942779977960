import { atom } from 'jotai';
import { deleteBillingAddress } from 'src/services/metering/deleteBillingAddress';
import { Result } from 'src/services/types';
import { updateBillingName } from 'src/services/user';
import { userAtom } from '../user-state-atoms';
import { setUserValuesAtom } from './setUserValuesAtom';

const removeBillingAddressAtom = atom(null, async (_get, set): Promise<Result> => {
  const { billingAddress } = _get(userAtom);
  const { success, error } = await updateBillingName('');
  if (!success) {
    return { success, error };
  }

  if (billingAddress?.id) {
    const billingAddressId = billingAddress.id;
    const { success, error } = await deleteBillingAddress({ billingAddressId });
    if (!success) {
      return { success, error };
    }
  }

  set(setUserValuesAtom, {
    kvkResult: undefined,
    billingAddress: undefined,
  });

  return { success: true };
});

export { removeBillingAddressAtom };
