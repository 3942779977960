import { useAtomValue, useSetAtom } from 'jotai';
import {
  cleanLoginAtom,
  setLoginToAtom,
} from 'src/contexts/session-global-state/session-atoms/session-actions-atoms';
import { loginToAtom } from 'src/contexts/session-global-state/session-atoms/session-state-atoms';

const useSession = () => {
  return {
    state: {
      loginTo: useAtomValue(loginToAtom),
    },
    actions: {
      setLoginTo: useSetAtom(setLoginToAtom),
      cleanLoginTo: useSetAtom(cleanLoginAtom),
    },
  };
};

export default useSession;
