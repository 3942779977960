enum ComponentTestIdKeys {
  PrimaryButton = 'primary-button',
  SecondaryButton = 'secondary-button',
  TextInput = 'text-input',
  Card = 'card',
  Input = 'checkbox',
  PincodeInput = 'text-input',
  IconButton = 'icon-button',
}

export { ComponentTestIdKeys };
