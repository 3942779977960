import { atom } from 'jotai';
import { globalStore } from 'src/contexts/globalStore';
import { getPersistedPartnerState, setPersistedPartnerState } from '../partner-actions-atoms';

const brandingPartnerIdAtom = atom<string | undefined>(
  getPersistedPartnerState()?.brandingPartnerId,
);

globalStore.sub(brandingPartnerIdAtom, () => {
  setPersistedPartnerState({
    ...getPersistedPartnerState(),
    brandingPartnerId: globalStore.get(brandingPartnerIdAtom),
  });
});

if (process.env.NODE_ENV !== 'production') {
  brandingPartnerIdAtom.debugLabel = 'Branding Partner Id';
}

export { brandingPartnerIdAtom };
