import { useAtomValue, useSetAtom } from 'jotai';
import { menuAtom, setMenuVisibleAtom } from 'src/contexts/menu-global-state/menu-atoms';

const useMenu = () => {
  return {
    state: {
      isMenuVisible: useAtomValue(menuAtom).isMenuVisible,
    },
    actions: {
      setMenuVisible: useSetAtom(setMenuVisibleAtom),
    },
  };
};

export default useMenu;
