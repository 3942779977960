import { FC, ReactNode } from 'react';
import { useMenu } from 'src/hooks';

interface PageContentProps {
  spaceBetween?: boolean;
  disablePadding?: boolean;
  flowContainer?: boolean;
  flowContent?: boolean;
  children: ReactNode;
}

const PageContent: FC<PageContentProps> = ({
  children,
  disablePadding,
  flowContainer,
  flowContent,
  spaceBetween,
}) => {
  const {
    state: { isMenuVisible },
  } = useMenu();
  const leftMargin = isMenuVisible ? ' md:ml-24' : ' lg:ml-0';

  return (
    <section
      className={`
       flex-1 auto flex flex-col md:pt-6 pt-2 text-left overflow-auto${
         spaceBetween ? ' justify-between' : ''
       }${
        disablePadding ? '' : ' xl:px-80 lg:px-56 md:px-36 px-4'
      }${leftMargin} dark:bg-biz-black h-[calc(100vh-3rem)] xl:h-[calc(100vh-4rem)]`}
    >
      <div
        className={`${flowContainer ? 'md:w-[41rem] self-center' : ''}${
          flowContent ? ' mb-flowContentFooter' : ''
        }`}
      >
        {children}
      </div>
    </section>
  );
};

export default PageContent;
