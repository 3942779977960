import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { usePartnerStore } from 'src/hooks';
import getAppTitle from 'src/helpers/getAppTitle';
import HeadMetaProps from './HeadMetaProps';

const HeadMeta: FC<HeadMetaProps> = ({ title, description }) => {
  const { state: partnerState } = usePartnerStore();
  const [headerTitle, setHeaderTitle] = useState(title);
  const [favIcon, setFavIcon] = useState('');

  useEffect(() => {
    setHeaderTitle(getAppTitle(partnerState.partner, title));
  }, [partnerState.partner, title]);

  useEffect(() => {
    if (partnerState.partner?.theme?.icon) {
      const icon = partnerState.partner.theme.icon;
      if (process.env.NODE_ENV === 'development' && !icon.startsWith('http')) {
        setFavIcon(`https://dev.bizcuit.nl${icon}`);
      } else {
        setFavIcon(icon);
      }
    }
  }, [partnerState.partner?.theme?.icon]);

  return (
    <Helmet>
      <title>{headerTitle}</title>
      <link rel="apple-touch-icon" href={favIcon} />
      <link rel="apple-touch-icon" href={favIcon} sizes="120x120" />
      <link rel="apple-touch-icon" href={favIcon} sizes="152x152" />
      <link rel="apple-touch-icon" href={favIcon} sizes="180x180" />
      <link rel="icon" href={favIcon} />
      <link rel="shortcut icon" href={favIcon} />
      <meta name="msapplication-TileImage" content={favIcon} />
      <meta name="msapplication-config" content={favIcon} />
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}
    </Helmet>
  );
};

export default HeadMeta;
