import React, { FC } from 'react';
import { Route, Routes } from 'react-router';
import Paths from '../../tokens/Paths';
const IdentifyIdin = React.lazy(() => import('./identify-idin/IdentifyIdin'));
const MethodError = React.lazy(() => import('./method-error/MethodError'));
const MismatchSupport = React.lazy(() => import('./mismatch-support/MismatchSupport'));
const Mismatch = React.lazy(() => import('./mismatch/Mismatch'));
const Success = React.lazy(() => import('./success/Success'));

const UserIdentityRouter: FC = () => {
  const { segments } = Paths.userIdentity;

  return (
    <Routes>
      <Route path={segments.identifyIDIN} element={<IdentifyIdin />} />
      <Route path={segments.methodError} element={<MethodError />} />
      <Route path={`${segments.success}/:method`} element={<Success />} />
      <Route
        path={`${segments.mismatch.root}${segments.mismatch.segments.support}`}
        element={<MismatchSupport />}
      />
      <Route path={`${segments.mismatch}/:method`} element={<Mismatch />} />
    </Routes>
  );
};

export default UserIdentityRouter;
