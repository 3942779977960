import { t } from 'i18next';
import { logger } from 'src/utils';

const getTranslation = (key: string, params?: Record<string, string | null>) => {
  const translation = t(key, params);

  if (key.includes(translation)) {
    logger.warn(`Translation key ${key} not found`);
    return undefined;
  }
  return translation;
};

export default getTranslation;
