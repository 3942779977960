import { atom } from 'jotai';
import { Result } from 'src/services/types';
import { updateEmail } from 'src/services/user';
import { setUserValuesAtom } from './setUserValuesAtom';

const setEmailAtom = atom(null, async (_get, set, email: string): Promise<Result> => {
  const result = await updateEmail(email);

  if (!result.success) return result;

  set(setUserValuesAtom, { email });

  return result;
});

export { setEmailAtom };
