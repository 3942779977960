import React, { memo, ReactNode } from 'react';
import PageHeader from './PageHeader';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';

type FlowLayoutProps = {
  children?: ReactNode;
  pageHeaderText?: string;
  spaceBetween?: boolean;
  showBackButton?: boolean;
  backToUrl?: string;
  flowContainer?: boolean;
  disablePadding?: boolean;
  onCloseClick?: () => void;
  footer?: ReactNode;
  showLanguagePicker?: boolean;
};

const FlowLayout = ({
  pageHeaderText,
  showBackButton = true,
  backToUrl,
  children,
  footer,
  onCloseClick,
  showLanguagePicker = true,
}: FlowLayoutProps) => {
  document.querySelector('body')?.classList.add('overflow-hidden');

  return (
    <div className="bg-gradient-to-t md:bg-gradient-to-r from-transparent to-primary-100 dark:from-biz-black dark:to-biz-black h-[100svh] max-h-[100svh] md:min-h-screen w-screen overflow-hidden">
      <div className="relative flex flex-col md:w-[832px] md:mx-auto md:rounded-3xl md:mt-16 md:overflow-hidden h-full md:h-[calc(100vh-theme(margin.16)*2)] shadow-md md:bg-white dark:bg-biz-gray-900">
        {pageHeaderText && (
          <div className="relative">
            <PageHeader
              title={pageHeaderText}
              showBackButton={showBackButton}
              backToUrl={backToUrl}
              suffix={
                onCloseClick && {
                  element: (
                    <BizcuitIcon icon="close" className="self-right h-6 w-6 dark:text-white" />
                  ),
                  onClick: onCloseClick,
                }
              }
              showLanguagePicker={showLanguagePicker}
            />
            <hr className="hidden md:block absolute bottom-0 bg-gradient-to-t from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 w-full -mb-4 h-4 dark:h-2 dark:-mb-2 border-none z-10" />
          </div>
        )}
        <div className="flex-grow px-3 pb-4 md:px-20 md:mx-6 overflow-y-auto">{children}</div>
        {footer && (
          <div className="flex flex-col w-full relative dark:md:bg-biz-gray-900 px-4 md:px-0">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(FlowLayout);
