import React, { FC, ReactNode } from 'react';
import { useMenu } from 'src/hooks';

type PageFooterProps = {
  contentAlign?: 'start' | 'center' | 'end';
  children?: ReactNode;
  flowContainer?: boolean;
};

const PageFooter: FC<PageFooterProps> = ({
  children,
  contentAlign = 'end',
  flowContainer = false,
}: PageFooterProps) => {
  const positioning = {
    start: 'flex-start md:justify-start',
    center: 'flex-center',
    end: 'flex-end md:justify-end',
  }[contentAlign];

  const {
    state: { isMenuVisible },
  } = useMenu();

  return (
    <footer
      className={`flex justify-center px-6 py-3 ${positioning} ${
        flowContainer ? 'md:w-[41rem] md:self-center' : 'lg:px-48'
      } ${isMenuVisible ? 'lg:mb-20 mb-16 md:mb-0' : ''}`}
    >
      <div className={`w-full ${flowContainer ? 'md:w-3/4' : 'xl:w-1/3 md:w-2/4'}`}>{children}</div>
    </footer>
  );
};

export default PageFooter;
