import { OpenApiPaths } from '../MockData';
import { getPartnerSecret } from '../utils';

const deleteTestUser = async (userId: string, partnerId: string) => {
  const response = await fetch(`${OpenApiPaths.users}/${userId}`, {
    method: 'DELETE',
    headers: {
      'x-bizcuit-client-id': partnerId,
      'x-bizcuit-client-secret': getPartnerSecret(partnerId),
      'Content-Type': 'application/json',
    },
  });

  return response;
};

export default deleteTestUser;
