import { atom } from 'jotai';
import { userAtom } from './userAtom';
import { getCookie } from 'src/utils';
import { CookieKeys } from 'src/tokens';

const isActiveUserAtom = atom((get) => {
  const hasAccessToken = Boolean(getCookie(CookieKeys.accessToken));
  return () => get(userAtom) && get(userAtom).active && hasAccessToken;
});
if (process.env.NODE_ENV !== 'production') {
  isActiveUserAtom.debugLabel = 'Is Active User';
}

export { isActiveUserAtom };
