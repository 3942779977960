import appleAppStoreBadge from 'src/assets/images/apple-app-store-badge.svg';
import googlePlayStoreBadge from 'src/assets/images/google-play-store-badge.png';
import OSs from 'src/tokens/OSs';

const getOsQrCodeInfo = (os: OSs) => {
  switch (os) {
    case OSs.Android:
      return {
        storeUrl: 'https://play.google.com/store/apps/details?id=nl.bizcuit',
        storeImageUrl: googlePlayStoreBadge,
      };
    case OSs.iOS:
      return {
        storeUrl: 'https://apps.apple.com/nl/app/bizcuit/id1554818911',
        storeImageUrl: appleAppStoreBadge,
      };
    default:
      return {
        storeUrl: 'https://www.bizcuit.nl/',
        storeImageUrl: '',
      };
  }
};

export default getOsQrCodeInfo;
