import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../types/utils/captureError';
import { captureError } from '../../utils';
import restApi from '../restApi';

export const deleteBillingAddress = async (billingAddressData: { billingAddressId: string }) => {
  try {
    const { billingAddressId } = billingAddressData;

    await restApi.delete(`/api/latest/metering/billing_address/${billingAddressId}`);
    return { success: true };
  } catch (error: unknown) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'deleteBillingAddress',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
