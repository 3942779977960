import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import { VerifyEmailTokenRequest, VerifyEmailTokenResponse, VerifyEmailTokenResult } from './types';

export const verifyEmailToken = async (
  payload: VerifyEmailTokenRequest,
): Promise<VerifyEmailTokenResult> => {
  try {
    const { data } = await restApi.get<VerifyEmailTokenResponse>(
      `/api/latest/user/verify_email_token?verificationToken=${payload.token}`,
    );

    return data;
  } catch (error) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'sendVerifyToken',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
