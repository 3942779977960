import { atom } from 'jotai';
import { invoiceStateAtom } from '../invoice-state-atoms';
import { GetInvoiceFinanceOffersResponseListItem } from 'src/types/bizcuitApi';

const setBaseOfferAtom = atom(
  null,
  (_get, set, baseOffer: Nullable<GetInvoiceFinanceOffersResponseListItem>) => {
    set(invoiceStateAtom, { ..._get(invoiceStateAtom), baseOffer });
  },
);

export { setBaseOfferAtom };
