import { ReactNode } from 'react';
import { consent as ConsentTrans } from 'src/translations/nl/signup.json';

export type ConsentTranslations = keyof typeof ConsentTrans;

export interface Permission {
  title: string;
  name: string;
  secondaryText?: string;
  selected?: boolean;
}

export interface PermissionGroup {
  id: string;
  title: string;
  permissions: Permission[];
}

export enum PermissionGroupIds {
  Administration = 'administration',
}

export interface PermissionConsent {
  scope: string;
  title: ConsentTranslations;
  icon: ReactNode;
  description?: ConsentTranslations;
  permissions?: PermissionGroup[];
}
