enum LocalStorageKeys {
  user = 'user',
  partner = 'partner',
  userSession = 'userSession',
  salesInvoiceOfferState = 'salesInvoiceOfferState',
  subscriptionPlan = 'subscriptionPlan',
  apps = 'apps',
  biometricsPublicKey = 'biometricsPublicKey',
  orchestrator = 'orchestrator',
  appearanceMode = 'appearanceMode',
}

export enum BindSessionKeys {
  userRandom = 'user_random',
  encryptedPrivateKey = 'user_key',
  encryptedSessionId = 'session',
  fingerprintHash = 'fingerprint_hash',
}

export default LocalStorageKeys;
