import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePartnerStore } from 'src/hooks';
import { DEFAULT_SUPPORT_EMAIL } from 'src/tokens';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import SupportContainerProps from './SupportContainer.types';
import { Link } from 'react-router-dom';

const SupportContainer: FC<SupportContainerProps> = () => {
  const { t } = useTranslation('common');
  const {
    state: { partner },
  } = usePartnerStore();

  return (
    <Link
      className="flex items-center p-4 mb-2 rounded-2xl bg-white shadow-md dark:bg-biz-gray-700"
      to={`mailto:${partner?.supportEmail || DEFAULT_SUPPORT_EMAIL}`}
    >
      <div className="mr-4 text-primary-500 dark:text-white bg-biz-cream dark:bg-biz-gray-600 rounded-4xl p-1">
        <BizcuitIcon icon={'support'} size={28} />
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-x-small font-weight-400 text-gray-800 dark:text-white">
          {t('support.cantFigureItOut')}
        </p>
        <p className="text-x-small-loose font-weight-500 text-gray-800 dark:text-white">
          <Trans i18nKey="common:support.contactSupport" />
        </p>
      </div>
    </Link>
  );
};

export default SupportContainer;
