import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { FC } from 'react';
import { pollCookie, removeCookie } from 'src/utils';
import fragmentTypes from 'src/graphql/fragmentTypes.json';
import { CookieKeys } from 'src/tokens';
import ApolloClientProviderProps from './ApolloClientProvider.types';
import { publicOperations } from 'src/tokens/PublicOperations';
import { GeneralObject } from 'src/types/generic';

const isPublicOperation = (operation: string | undefined) =>
  operation && publicOperations.includes(operation);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

let bizcuitAccessToken: Nullable<string> = null;

const resetToken = onError(({ networkError }) => {
  // reset cached access token on 401 from the server
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    bizcuitAccessToken = null;
    removeCookie(CookieKeys.accessToken);
  }
});

const addAuthHeader = (headers: GeneralObject) => {
  return {
    headers: {
      Authorization: bizcuitAccessToken,
      ...headers,
    },
  };
};

const authLink = setContext(async (request, { headers }) => {
  if (bizcuitAccessToken) {
    return addAuthHeader(headers);
  }

  if (!isPublicOperation(request.operationName)) {
    bizcuitAccessToken = await pollCookie(CookieKeys.accessToken);
  }
  return addAuthHeader(headers);
});

const client = new ApolloClient({
  link: authLink.concat(resetToken).concat(httpLink),
  cache: new InMemoryCache({
    ...fragmentTypes,
    typePolicies: {
      Query: {
        fields: {
          listInvoices: offsetLimitPagination(),
        },
      },
    },
  }),
});

const ApolloClientProvider: FC<ApolloClientProviderProps> = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default ApolloClientProvider;
