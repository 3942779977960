import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import nl from 'src/translations/nl';
import en from 'src/translations/en';

const languageDetector = new LanguageDetector(null, {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  convertDetectedLanguage: (language) => language.split('-')[0],
});

export const resources = {
  nl,
  en,
} as const;

export const availableLanguages = Object.keys(resources);

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    }),
  )
  .use(languageDetector)
  .init({
    fallbackLng: 'nl',
    resources,
    ns: [
      'ais',
      'common',
      'invoiceFinancing',
      'signup',
      'deviceBinding',
      'bookkeepingSystem',
      'hix',
      'minox',
      'orchestrator',
      'minox',
      'accountingHub',
      'errorTitles',
      'genericErrors',
      'cdd',
    ],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
