import { FC, Suspense } from 'react';
import { Routes } from 'react-router';
import { Route } from 'react-router-dom';
import { PageLayout } from 'src/atoms/layout/menu-page';
import Paths from 'src/tokens/Paths';
import FlowLayout from 'src/atoms/layout/menu-page/FlowLayout';

import ProcessOverview from './process-overview/ProcessOverview';
import UbosFromAnotherCompany from './ubos-from-another-company/UbosFromAnotherCompany';
import UbosFromDirectors from './ubos-from-directors/UbosFromDirectors';
import UbosInProgress from './ubos-in-progress/UbosInProgress';
import UboForm from './ubo-form/UboForm';
import SelectAccountType from './select-account-type/SelectAccountType';
import ProvideKvk from './provide-kvk/ProvideKvk';
import KvkNotListed from './kvk-not-listed/KvkNotListed';

const { segments } = Paths.ais.segments.cdd;
const CddRouter: FC = () => {
  return (
    <Suspense fallback={<FlowLayout />}>
      <PageLayout>
        <Routes>
          <Route path={segments.processOverview} element={<ProcessOverview />} />
          <Route path={segments.ubosFromAnotherCompany} element={<UbosFromAnotherCompany />} />
          <Route path={segments.ubosFromDirector} element={<UbosFromDirectors />} />
          <Route path={segments.ubosInProgress} element={<UbosInProgress />} />
          <Route path={segments.uboform} element={<UboForm />} />
          <Route path={segments.selectAccountType} element={<SelectAccountType />} />{' '}
          <Route path={segments.provideKvk} element={<ProvideKvk />} />
          <Route path={segments.provideManually} element={<KvkNotListed />} />
        </Routes>
      </PageLayout>
    </Suspense>
  );
};

export default CddRouter;
