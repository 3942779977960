import React, { FC } from 'react';
import PrimaryHeaderProps from './PrimaryHeader.types';

const PrimaryHeader: FC<PrimaryHeaderProps> = ({ children, className = '' }) => (
  <h1
    className={`my-6 text-primary-900 dark:text-primary-100 font-weight-700 text-x-large md:mb-8${
      className ? ` ${className}` : ''
    }`}
  >
    {children}
  </h1>
);

export default PrimaryHeader;
