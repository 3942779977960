import { useAtomValue, useSetAtom } from 'jotai';
import {
  getPersistedInvoiceState,
  invoiceStateAtom,
  setAdministrationAtom,
  setAdministrationListAtom,
  setBankAccountsAtom,
  setBaseOfferAtom,
  setDebtorAtom,
  setFundrApiStateAtom,
  setInvoiceFinanceStateAtom,
  setPersistedInvoiceState,
  setSelectedInvoiceAtom,
} from 'src/contexts/invoice-global-state/invoice-atoms';

const useInvoiceStore = () => {
  return {
    state: {
      ...useAtomValue(invoiceStateAtom),
    },
    actions: {
      getPesistedInvoiceState: getPersistedInvoiceState,
      setPersistedInvoicestate: setPersistedInvoiceState,
      setAdministration: useSetAtom(setAdministrationAtom),
      setAdministrationList: useSetAtom(setAdministrationListAtom),
      setBankAccounts: useSetAtom(setBankAccountsAtom),
      setBaseOffer: useSetAtom(setBaseOfferAtom),
      setDebtor: useSetAtom(setDebtorAtom),
      setFundrApiState: useSetAtom(setFundrApiStateAtom),
      setInvoiceFinanceState: useSetAtom(setInvoiceFinanceStateAtom),
      setSelectedInvoice: useSetAtom(setSelectedInvoiceAtom),
    },
  };
};

export default useInvoiceStore;
