import { createElement } from 'react';
import i18n from 'src/i18next';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { PermissionConsent, PermissionGroupIds } from 'src/interfaces/Permissions';
import { Administration, PartnerScopes } from 'src/types/bizcuitApi';
import showScopeCheck from './showScopeCheck';

const getAdministrationsPermissions = (
  administrations: Administration[],
  scopes: PartnerScopes[] = [],
  trustedScopes: PartnerScopes[] = [],
): Nullable<PermissionConsent> => {
  const showScope = showScopeCheck(scopes, trustedScopes);
  const administrationScopes = [PartnerScopes.Administrations, PartnerScopes.ManageAdministrations];

  const hasAdministrationScope = scopes.some((scope) => {
    return administrationScopes.includes(scope);
  });
  const hasVisibleScopes = administrationScopes.some((scope) => showScope(scope));

  if (!hasAdministrationScope || !hasVisibleScopes) return null;

  const permissions = [
    {
      id: PermissionGroupIds.Administration,
      title: i18n.t('signup:consent.selectAllAdministrations'),
      permissions: administrations.map(({ name, id }) => ({
        title: name,
        name: id,
      })),
    },
  ];

  const administrationsDetails = {
    scope: PartnerScopes.ManageAdministrations as string,
    title: 'addAndManageAdministrationsTitle',
    description: 'givePartnerPermissionsManageAdministrations',
    icon: createElement(BizcuitIcon, { icon: 'folder', className: 'w-6 h-6' }),
    permissions,
  };

  if (showScope(PartnerScopes.Administrations)) {
    administrationsDetails.scope = PartnerScopes.Administrations;
    administrationsDetails.description = 'givePartnerPermissionsUseAdministrations';
    administrationsDetails.title = 'administrationTitle';
  }

  if (showScope(PartnerScopes.ManageAdministrations)) {
    administrationsDetails.scope = PartnerScopes.ManageAdministrations;
    administrationsDetails.description = 'givePartnerPermissionsManageAdministrations';
    administrationsDetails.title = 'addAndManageAdministrationsTitle';
  }

  if (showScope(PartnerScopes.ManageAdministrations) && showScope(PartnerScopes.Administrations)) {
    administrationsDetails.scope = [
      PartnerScopes.Administrations,
      PartnerScopes.ManageAdministrations,
    ].join('-');
    administrationsDetails.description = 'givePartnerPermissionsUseManageAdministrations';
    administrationsDetails.title = 'addAndManageAdministrationsTitle';
  }

  return administrationsDetails as PermissionConsent;
};

export default getAdministrationsPermissions;
