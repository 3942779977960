import { getLocalStorageObject, setLocalStorageObject } from 'src/helpers';
import { defaultDemoPartner, defaultUserData } from './MockData';
import { PocSetup } from './OrchestratorFlowDemo.types';

export const encodeFormData = (formData: Record<string, string>) => {
  return Object.keys(formData)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(formData[key]);
    })
    .join('&');
};

export const getExternalId = () => 'test-' + Math.random().toString(36).substring(2);

export const getPocSetup = (key: keyof PocSetup): string => {
  const data = getLocalStorageObject('pocSetup') as PocSetup;
  return data?.[key] ?? defaultUserData[defaultDemoPartner][key];
};

export const setPocSetup = (key: keyof PocSetup, value: string | null) => {
  const data = getLocalStorageObject('pocSetup') as PocSetup;
  if (!data) {
    setLocalStorageObject('pocSetup', { [key]: value });
    return;
  }

  !value ? delete data[key] : (data[key] = value);
  setLocalStorageObject('pocSetup', data);
};

export const getPartnerSecret = (partnerId: string) => {
  const demoData = Object.values(defaultUserData).find((data) => data.partnerId === partnerId);
  return demoData?.partnerSecret || '';
};
