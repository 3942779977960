import React, { FC } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import ButtonProps, { Sizes } from './PrimaryButton.types';
import { ComponentTestIdKeys } from 'src/tokens';

const sizes: Sizes = {
  default: 'w-full h-12 px-6 rounded-2xl',
  small: 'w-min h-9 px-6 rounded-4xl',
};

const PrimaryButton: FC<ButtonProps> = ({
  children,
  onClick,
  size,
  color = 'default',
  isDisabled = false,
  showSpinner = false,
  className,
  justifyContent = 'justify-center',
  testId,
  ...rest
}) => {
  const handleClick = () => {
    if (isDisabled || showSpinner) return;

    onClick && onClick();
  };

  return (
    <button
      // TODO: use theme bg-color in the className and remove style
      data-testid={testId ? `${ComponentTestIdKeys.PrimaryButton}-${testId}` : ''}
      className={
        'flex items-center text-base font-weight-600 text-white focus:outline-none focus:bg-primary-400 ' +
        `${sizes[size ?? 'default']} ` +
        `${color === 'default' ? `bg-primary-500` : 'bg-biz-red-500'} ${
          isDisabled ? 'opacity-40 cursor-not-allowed' : ''
        } ${className} ${showSpinner ? 'justify-center' : justifyContent} `
      }
      onClick={handleClick}
      disabled={isDisabled}
      {...rest}
    >
      {!showSpinner ? children : <BizcuitIcon icon="spinner" className="animate-spin" />}
    </button>
  );
};

export default PrimaryButton;
