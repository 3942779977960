import { setSessionStorageObject } from 'src/helpers';
import { LocalStorageKeys } from 'src/tokens';
import { InvoiceContextState } from '../../invoiceState.types';

const setPersistedInvoiceState = (state?: InvoiceContextState) => {
  if (!state) return;
  setSessionStorageObject(LocalStorageKeys.salesInvoiceOfferState, state);
};

export { setPersistedInvoiceState };
