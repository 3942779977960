import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DEFAULT_SUPPORT_EMAIL } from '../../tokens';
import { Trans, useTranslation } from 'react-i18next';

const AlertDialog = ({
  isVisible,
  onConfirm,
  onClose,
  alertDialogTexts,
  loading = false,
  primaryColorContinueButton = false,
  showContactSupport = false,
}: {
  isVisible: boolean;
  onConfirm?: () => void;
  onClose: () => void;
  alertDialogTexts: {
    title: string;
    description?: string | string[];
    proceed?: string;
    close: string;
  };
  loading?: boolean;
  primaryColorContinueButton?: boolean;
  showContactSupport?: boolean;
}) => {
  const { t } = useTranslation('common');
  useEffect(() => {
    const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
      if (isVisible && e.key === 'Escape') {
        onClose();
      }
    };
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [isVisible, onClose]);

  return (
    <div
      className={`absolute top-0 left-0 w-full h-full flex items-center justify-center${
        isVisible ? '' : ' hidden'
      }`}
    >
      <div
        className="fixed w-screen h-screen bg-gray-800 dark:bg-gray-600 opacity-40 z-10"
        onClick={onClose}
      />
      <div className="w-[280px] h-auto p-5 rounded-xl bg-white dark:bg-biz-gray-800 shadow-md z-20">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3.5">
            <p className="text-medium font-weight-600 text-gray-800 dark:text-white">
              {alertDialogTexts.title}
            </p>
            {Array.isArray(alertDialogTexts.description) ? (
              alertDialogTexts.description.map((text, index) => {
                return <p key={index}>{text}</p>;
              })
            ) : (
              <p>{alertDialogTexts.description}</p>
            )}
            {showContactSupport && (
              <Link
                to={`mailto:${DEFAULT_SUPPORT_EMAIL}`}
                className="text-center w-3/4 self-center"
              >
                <p className="text-x-small-loose text-biz-gray-600 dark:text-gray-200">
                  {t('support.stuckAndNeedHelp')}
                </p>
                <p className="text-small font-weight-500 underline">
                  <Trans
                    i18nKey={'common:support.contactSupportAndEmail'}
                    values={{ supportEmail: DEFAULT_SUPPORT_EMAIL }}
                  />
                </p>
              </Link>
            )}
          </div>
          <div className="flex flex-col items-end gap-3">
            <button
              onClick={onConfirm}
              disabled={loading}
              className={`text-small font-weight-400 ${
                primaryColorContinueButton ? 'text-primary-500' : 'text-red-500'
              } uppercase cursor-pointer ${loading ? 'text-gray-500 cursor-not-allowed' : ''}`}
            >
              {alertDialogTexts.proceed}
            </button>
            <button
              onClick={onClose}
              disabled={loading}
              className={`text-small font-weight-500 text-primary-500 dark:text-white uppercase cursor-pointer ${
                loading ? 'text-gray-500 cursor-not-allowed' : ''
              }`}
            >
              {alertDialogTexts.close}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertDialog;
