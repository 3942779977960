import { atom } from 'jotai';
import { getBillingAddress } from 'src/services/metering';
import { userAtom } from '../user-state-atoms';
import { CompanyItem } from 'src/types/bizcuitApi';
import { setUserValuesAtom } from './setUserValuesAtom';

const fetchBillingAddressAtom = atom(null, async (_get, set) => {
  const { data, success, error } = await getBillingAddress();
  const billingAddress = {
    id: data.id,
    tradeName: data.name,
    houseNumber: data.houseNumber,
    country: data.country,
    postalCode: data.postalCode,
    street: data.street,
    city: data.city,
  };

  if (success && billingAddress.id) {
    set(setUserValuesAtom, {
      billingAddress: billingAddress,
      kvkResult: { ..._get(userAtom).kvkResult, ...billingAddress } as CompanyItem,
    });
  }
  return { success, error };
});

export { fetchBillingAddressAtom };
