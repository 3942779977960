import { FC, lazy } from 'react';
import PageContainer from '../page-container/PageContainer';
import AppContainerProps from './AppContainer.types';
import { useIsDarkMode } from 'src/hooks';
const BackgroundIllustration = lazy(async () => ({
  default: (await import('src/assets/images/illustration.svg')).ReactComponent,
}));
const BackgroundDarkIllustration = lazy(async () => ({
  default: (await import('src/assets/images/illustrationDark.svg')).ReactComponent,
}));

const AppContainer: FC<AppContainerProps> = ({ children }) => {
  const illustrationClasses = 'absolute right-0 bottom-0 w-1/2 h-3/4 z-0 hidden md:block';
  const isDarkMode = useIsDarkMode();

  return (
    <div className="w-full h-full flex dark:bg-biz-black items-start sm:items-center justify-center lg:relative">
      <div className="flex z-10 w-full min-h-screen overflow-auto items-start sm:items-center justify-center bg-right-bottom bg-no-repeat sm:max-h-900 sm:overflow-hidden sm:flex sm:flex-col">
        <div className="w-full sm:w-4/5 sm:py-8">
          <PageContainer>{children}</PageContainer>
        </div>
      </div>
      {isDarkMode ? (
        <BackgroundDarkIllustration className={illustrationClasses} />
      ) : (
        <BackgroundIllustration className={illustrationClasses} />
      )}
    </div>
  );
};

export default AppContainer;
