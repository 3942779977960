import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import {
  SendVerificationTokenEmailRequest,
  SendVerificationTokenEmailResponse,
  SendVerificationTokenEmailResult,
} from './types';

export const sendVerificationTokenEmail = async (
  payload: SendVerificationTokenEmailRequest,
): Promise<SendVerificationTokenEmailResult> => {
  try {
    const result = await restApi.post<SendVerificationTokenEmailResponse>(
      `/api/latest/user/verify_email_token`,
      payload,
    );

    return result.data;
  } catch (error) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'sendVerifyToken',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
