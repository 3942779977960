import './FullPageLoader.css';

const FullPageLoader = () => {
  const loader = 'full-page-loader flex flex-col h-screen relative';
  const loaderBar = 'loader__bar flex flex-1 h-full relative';

  return (
    <div className={loader}>
      <div className={`${loaderBar}`}></div>
      <div className={`${loaderBar} loader__bar--delay-1`}></div>
      <div className={`${loaderBar} loader__bar--delay-2`}></div>
      <div className={`${loaderBar} loader__bar--delay-3`}></div>
      <div className={`${loaderBar} loader__bar--delay-4`}></div>
      <div className={`${loaderBar} loader__bar--delay-5`}></div>
    </div>
  );
};

export default FullPageLoader;
