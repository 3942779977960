import { SVGProps } from 'react';

const SvgExportDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse
      cx="4.80014"
      cy="10.9714"
      rx="1.37143"
      ry="1.37143"
      fill="url(#paint0_linear_1069_6062)"
    />
    <circle cx="15.0856" cy="3.42857" r="0.685714" fill="url(#paint1_linear_1069_6062)" />
    <circle cx="17.8288" cy="20.5714" r="0.685715" fill="url(#paint2_linear_1069_6062)" />
    <path
      d="M18.2654 5.56664C18.3438 5.32533 18.6852 5.32533 18.7636 5.56664C18.7987 5.67456 18.8992 5.74762 19.0127 5.74762C19.2664 5.74762 19.3719 6.0723 19.1666 6.22144C19.0748 6.28813 19.0364 6.40636 19.0715 6.51427C19.1499 6.75558 18.8737 6.95625 18.6684 6.80711C18.5766 6.74041 18.4523 6.74041 18.3605 6.80711C18.1553 6.95625 17.8791 6.75558 17.9575 6.51427C17.9926 6.40636 17.9541 6.28813 17.8623 6.22144C17.6571 6.0723 17.7626 5.74762 18.0163 5.74762C18.1298 5.74762 18.2303 5.67456 18.2654 5.56664Z"
      fill="url(#paint3_linear_1069_6062)"
    />
    <path
      d="M12.7796 19.9667C12.858 19.7254 13.1993 19.7254 13.2778 19.9667C13.3128 20.0746 13.4134 20.1476 13.5269 20.1476C13.7806 20.1476 13.8861 20.4723 13.6808 20.6215C13.589 20.6882 13.5506 20.8064 13.5857 20.9143C13.6641 21.1556 13.3879 21.3563 13.1826 21.2071C13.0908 21.1404 12.9665 21.1404 12.8747 21.2071C12.6694 21.3563 12.3932 21.1556 12.4716 20.9143C12.5067 20.8064 12.4683 20.6882 12.3765 20.6215C12.1712 20.4723 12.2767 20.1476 12.5305 20.1476C12.6439 20.1476 12.7445 20.0746 12.7796 19.9667Z"
      fill="url(#paint4_linear_1069_6062)"
    />
    <path
      d="M3.17946 2.13808C3.25786 1.89677 3.59925 1.89677 3.67766 2.13808C3.71272 2.246 3.81329 2.31906 3.92676 2.31906C4.18049 2.31906 4.28598 2.64374 4.08071 2.79288C3.98891 2.85958 3.9505 2.9778 3.98556 3.08572C4.06397 3.32702 3.78778 3.52769 3.58251 3.37855C3.49071 3.31185 3.3664 3.31185 3.27461 3.37855C3.06934 3.52769 2.79315 3.32702 2.87155 3.08572C2.90662 2.9778 2.8682 2.85958 2.7764 2.79288C2.57113 2.64374 2.67663 2.31906 2.93036 2.31906C3.04383 2.31906 3.14439 2.246 3.17946 2.13808Z"
      fill="url(#paint5_linear_1069_6062)"
    />
    <path
      d="M19.7614 10.669C19.8006 10.5484 19.9713 10.5484 20.0105 10.669C20.028 10.723 20.0783 10.7595 20.135 10.7595C20.2619 10.7595 20.3146 10.9219 20.212 10.9964C20.1661 11.0298 20.1469 11.0889 20.1644 11.1428C20.2036 11.2635 20.0655 11.3638 19.9629 11.2893C19.917 11.2559 19.8548 11.2559 19.8089 11.2893C19.7063 11.3638 19.5682 11.2635 19.6074 11.1428C19.6249 11.0889 19.6057 11.0298 19.5598 10.9964C19.4572 10.9219 19.5099 10.7595 19.6368 10.7595C19.6935 10.7595 19.7438 10.723 19.7614 10.669Z"
      fill="url(#paint6_linear_1069_6062)"
    />
    <path
      d="M17.0182 12.7262C17.0574 12.6055 17.2281 12.6055 17.2673 12.7262C17.2848 12.7801 17.3351 12.8167 17.3918 12.8167C17.5187 12.8167 17.5715 12.979 17.4688 13.0536C17.4229 13.0869 17.4037 13.146 17.4212 13.2C17.4605 13.3207 17.3224 13.421 17.2197 13.3464C17.1738 13.3131 17.1117 13.3131 17.0658 13.3464C16.9631 13.421 16.825 13.3207 16.8642 13.2C16.8818 13.146 16.8626 13.0869 16.8167 13.0536C16.714 12.979 16.7668 12.8167 16.8936 12.8167C16.9504 12.8167 17.0007 12.7801 17.0182 12.7262Z"
      fill="url(#paint7_linear_1069_6062)"
    />
    <path
      d="M3.30384 14.7833C3.34304 14.6627 3.51374 14.6627 3.55294 14.7833C3.57047 14.8373 3.62076 14.8738 3.67749 14.8738C3.80435 14.8738 3.8571 15.0362 3.75447 15.1107C3.70857 15.1441 3.68936 15.2032 3.70689 15.2571C3.7461 15.3778 3.608 15.4781 3.50537 15.4036C3.45947 15.3702 3.39731 15.3702 3.35141 15.4036C3.24878 15.4781 3.11068 15.3778 3.14989 15.2571C3.16742 15.2032 3.14821 15.1441 3.10231 15.1107C2.99968 15.0362 3.05243 14.8738 3.17929 14.8738C3.23603 14.8738 3.28631 14.8373 3.30384 14.7833Z"
      fill="url(#paint8_linear_1069_6062)"
    />
    <g clipPath="url(#clip0_1069_6062)">
      <path
        d="M14.4001 5.48569H8.9144C8.55067 5.48569 8.20184 5.63018 7.94465 5.88737C7.68746 6.14456 7.54297 6.49339 7.54297 6.85712V17.8285C7.54297 18.1923 7.68746 18.5411 7.94465 18.7983C8.20184 19.0555 8.55067 19.2 8.9144 19.2H17.143C17.5067 19.2 17.8555 19.0555 18.1127 18.7983C18.3699 18.5411 18.5144 18.1923 18.5144 17.8285V9.59997L14.4001 5.48569Z"
        fill="#FF8000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01626 4.95923C7.33775 4.63773 7.77379 4.45712 8.22845 4.45712H13.7142C13.8051 4.45712 13.8923 4.49325 13.9566 4.55754L18.0709 8.67183C18.1352 8.73613 18.1713 8.82333 18.1713 8.91427V17.1428C18.1713 17.5975 17.9907 18.0335 17.6692 18.355C17.3477 18.6765 16.9117 18.8571 16.457 18.8571H8.22845C7.77379 18.8571 7.33775 18.6765 7.01626 18.355C6.69477 18.0335 6.51416 17.5975 6.51416 17.1428V6.17141C6.51416 5.71675 6.69477 5.28072 7.01626 4.95923ZM8.22845 5.14284C7.95565 5.14284 7.69403 5.2512 7.50114 5.4441C7.30824 5.63699 7.19987 5.89861 7.19987 6.17141V17.1428C7.19987 17.4156 7.30824 17.6773 7.50114 17.8701C7.69403 18.063 7.95565 18.1714 8.22845 18.1714H16.457C16.7298 18.1714 16.9914 18.063 17.1843 17.8701C17.3772 17.6773 17.4856 17.4156 17.4856 17.1428V9.05628L13.5721 5.14284H8.22845Z"
        fill="#2A2927"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7144 4.45712C13.9038 4.45712 14.0573 4.61063 14.0573 4.79998V8.57141H17.8287C18.0181 8.57141 18.1716 8.72491 18.1716 8.91427C18.1716 9.10362 18.0181 9.25712 17.8287 9.25712H13.7144C13.5251 9.25712 13.3716 9.10362 13.3716 8.91427V4.79998C13.3716 4.61063 13.5251 4.45712 13.7144 4.45712Z"
        fill="#2A2927"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57129 12.3429C8.57129 12.1535 8.72479 12 8.91415 12H15.7713C15.9606 12 16.1141 12.1535 16.1141 12.3429C16.1141 12.5322 15.9606 12.6857 15.7713 12.6857H8.91415C8.72479 12.6857 8.57129 12.5322 8.57129 12.3429Z"
        fill="#2A2927"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57129 15.0857C8.57129 14.8964 8.72479 14.7429 8.91415 14.7429H15.7713C15.9606 14.7429 16.1141 14.8964 16.1141 15.0857C16.1141 15.2751 15.9606 15.4286 15.7713 15.4286H8.91415C8.72479 15.4286 8.57129 15.2751 8.57129 15.0857Z"
        fill="#2A2927"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57129 16.4571C8.57129 16.2678 8.72479 16.1143 8.91415 16.1143H15.7713C15.9606 16.1143 16.1141 16.2678 16.1141 16.4571C16.1141 16.6465 15.9606 16.8 15.7713 16.8H8.91415C8.72479 16.8 8.57129 16.6465 8.57129 16.4571Z"
        fill="#2A2927"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57129 13.7143C8.57129 13.5249 8.72479 13.3714 8.91415 13.3714H15.7713C15.9606 13.3714 16.1141 13.5249 16.1141 13.7143C16.1141 13.9036 15.9606 14.0571 15.7713 14.0571H8.91415C8.72479 14.0571 8.57129 13.9036 8.57129 13.7143Z"
        fill="#2A2927"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57129 6.85714C8.57129 6.66778 8.72479 6.51428 8.91415 6.51428L11.657 6.51428C11.8464 6.51428 11.9999 6.66778 11.9999 6.85714C11.9999 7.04649 11.8464 7.2 11.657 7.2L8.91415 7.2C8.72479 7.2 8.57129 7.04649 8.57129 6.85714Z"
        fill="#2A2927"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57129 8.22857C8.57129 8.03921 8.72479 7.88571 8.91415 7.88571L11.657 7.88571C11.8464 7.88571 11.9999 8.03921 11.9999 8.22857C11.9999 8.41792 11.8464 8.57143 11.657 8.57143L8.91415 8.57143C8.72479 8.57143 8.57129 8.41792 8.57129 8.22857Z"
        fill="#2A2927"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57129 9.6C8.57129 9.41064 8.72479 9.25714 8.91415 9.25714L11.657 9.25714C11.8464 9.25714 11.9999 9.41064 11.9999 9.6C11.9999 9.78935 11.8464 9.94286 11.657 9.94286L8.91415 9.94286C8.72479 9.94286 8.57129 9.78935 8.57129 9.6Z"
        fill="#2A2927"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57129 10.9714C8.57129 10.7821 8.72479 10.6286 8.91415 10.6286L15.7713 10.6286C15.9606 10.6286 16.1141 10.7821 16.1141 10.9714C16.1141 11.1608 15.9606 11.3143 15.7713 11.3143L8.91415 11.3143C8.72479 11.3143 8.57129 11.1608 8.57129 10.9714Z"
        fill="#2A2927"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1069_6062"
        x1="3.42871"
        y1="9.60001"
        x2="3.90415"
        y2="12.6916"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC182" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1069_6062"
        x1="14.3999"
        y1="2.74286"
        x2="14.6376"
        y2="4.28864"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC182" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1069_6062"
        x1="17.1431"
        y1="19.8857"
        x2="17.3808"
        y2="21.4315"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC182" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1069_6062"
        x1="17.1431"
        y1="4.79999"
        x2="17.6185"
        y2="7.89154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC182" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1069_6062"
        x1="11.6572"
        y1="19.2"
        x2="12.1327"
        y2="22.2916"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC182" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1069_6062"
        x1="2.05713"
        y1="1.37143"
        x2="2.53257"
        y2="4.46299"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC182" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1069_6062"
        x1="19.2002"
        y1="10.2857"
        x2="19.4379"
        y2="11.8315"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC182" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1069_6062"
        x1="16.457"
        y1="12.3429"
        x2="16.6948"
        y2="13.8886"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC182" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1069_6062"
        x1="2.74268"
        y1="14.4"
        x2="2.9804"
        y2="15.9458"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC182" />
        <stop offset="1" stopColor="#FF8000" />
      </linearGradient>
      <clipPath id="clip0_1069_6062">
        <rect
          width="16.4571"
          height="16.4571"
          fill="white"
          transform="translate(4.11426 3.42856)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgExportDocument;
