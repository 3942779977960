import { atom } from 'jotai';
import {
  partnerAtom,
  partnerIdAtom,
  partnerReferenceAtom,
  brandingPartnerIdAtom,
  partnerAuthorisationAtom,
} from '../partner-state-atoms';
import { PartnerState } from 'src/contexts/partner-global-state/types';

const setPartnerState = atom(null, (_get, set, updateState: PartnerState) => {
  set(partnerAtom, updateState.partner);
  set(partnerIdAtom, updateState.partnerId);
  set(partnerReferenceAtom, updateState.partnerReference);
  set(brandingPartnerIdAtom, updateState.brandingPartnerId);
  set(partnerAuthorisationAtom, updateState.partnerAuthorisation);
});

export { setPartnerState };
