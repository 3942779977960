import { Outlet, useNavigate } from 'react-router-dom';
import { Paths } from 'src/tokens';
import { useUserStore, useSession, useUserShouldApproveTerms } from 'src/hooks';
import { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import { clearLoginSession } from 'src/helpers';
import { orchestratorAtom } from 'src/contexts/orchestrator-global-state/orchestrator-atoms/orchestrator-global-atoms';

const TermsAcceptedGuard = () => {
  const navigate = useNavigate();
  const [orchestratorState] = useAtom(orchestratorAtom);

  const getOverviewUrl = (flowId: string) => {
    return `${Paths.orchestrator.root}${Paths.orchestrator.segments.overview}`.replace(
      ':flowId',
      flowId,
    );
  };

  const {
    state: { user },
  } = useUserStore();

  const { bizcuit: userShouldApproveTerms } = useUserShouldApproveTerms();

  const {
    actions: { setLoginTo },
  } = useSession();

  useEffect(() => {
    if (!user) return;

    if (userShouldApproveTerms && !user.createdThrough) {
      const flowId = orchestratorState?.flow?.id;
      if (user.embeddedUser && flowId) {
        navigate(getOverviewUrl(flowId));
        return;
      }

      if (location.pathname.includes('/app')) {
        setLoginTo(`${location.pathname}${location.search}`);
      }
      clearLoginSession();
      navigate(Paths.logInV1.root);
    }
  }, [navigate, user, orchestratorState?.flow?.id, setLoginTo, userShouldApproveTerms]);

  return <Outlet />;
};

export default TermsAcceptedGuard;
