import React, { FC } from 'react';
import SecondaryButtonProps from './TertiaryButton.types';

const textColor = {
  'gray-800': 'text-gray-800',
  'primary-500': 'text-primary-500',
};
const TertiaryButton: FC<SecondaryButtonProps> = ({
  className,
  color = 'gray-800',
  underline = false,
  children,
  onClick,
  ...rest
}) => (
  <button
    className={`text-small font-weight-500 px-2 py-1 self-start w-auto focus:underline${
      underline ? ' underline' : ''
    } ${textColor[color]} dark:text-white${className ? ` ${className}` : ''}`}
    onClick={onClick}
    {...rest}
  >
    {children}
  </button>
);
export default TertiaryButton;
