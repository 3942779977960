const parseQueryParamsString = (queryParameters: string): Record<string, string> => {
  const individualParameters = queryParameters?.replace(/^\?/g, '').split('&') || [];

  const queryParamsObject = individualParameters.reduce((paramsObject, paramPair) => {
    const [key, value] = paramPair.split('=');

    if (key && value) {
      paramsObject[key] = value;
    }

    return paramsObject;
  }, {} as Record<string, string>);

  return queryParamsObject;
};

export default parseQueryParamsString;
