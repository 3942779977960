import { SVGProps } from 'react';

const SvgSupport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17157 16.4216C6.92172 15.6714 7.93913 15.25 9 15.25H15C16.0609 15.25 17.0783 15.6714 17.8284 16.4216C18.5786 17.1717 19 18.1891 19 19.25V20.75C19 21.3023 18.5523 21.75 18 21.75C17.4477 21.75 17 21.3023 17 20.75V19.25C17 18.7196 16.7893 18.2109 16.4142 17.8358C16.0391 17.4607 15.5304 17.25 15 17.25H9C8.46957 17.25 7.96086 17.4607 7.58579 17.8358C7.21071 18.2109 7 18.7196 7 19.25V20.75C7 21.3023 6.55228 21.75 6 21.75C5.44772 21.75 5 21.3023 5 20.75V19.25C5 18.1891 5.42143 17.1717 6.17157 16.4216Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 10C4 8.94773 4.20759 7.90581 4.61089 6.93389C5.01418 5.96198 5.60526 5.07917 6.35026 4.33605C7.09525 3.59292 7.97954 3.00406 8.95246 2.60321C9.92539 2.20236 10.9678 1.99738 12.0201 2.00003C13.0724 2.00267 14.1138 2.21288 15.0847 2.61861C16.0556 3.02435 16.9369 3.61764 17.6781 4.3645C18.4194 5.11136 19.006 5.99712 19.4044 6.97105C19.7978 7.93258 20 8.96139 20 10V12.4773C20 12.766 19.766 13 19.4773 13H19.3839C19.3661 13 19.3486 13.0042 19.3324 13.0115L19.2968 13.0276L19.1977 13.0724C19.112 13.111 18.9886 13.1664 18.837 13.2339C18.5341 13.3688 18.1176 13.5525 17.6641 13.7475C16.7428 14.1435 15.534 14.2118 14.6718 14.2092C14.2182 14.2079 13.8144 14.186 13.5238 14.1645C13.3781 14.1537 13.2597 14.143 13.1764 14.1347C13.1348 14.1306 13.1018 14.1271 13.0786 14.1246L13.051 14.1215L13.0429 14.1206L13.039 14.1201C12.4906 14.0549 12.0986 13.5574 12.1638 13.0089C12.2291 12.4607 12.7262 12.0691 13.2744 12.134L13.2781 12.1344L13.296 12.1364C13.3126 12.1383 13.3386 12.141 13.373 12.1444C13.4417 12.1512 13.5437 12.1605 13.6713 12.1699C13.9276 12.1889 14.2827 12.2081 14.6778 12.2093C15.513 12.2117 16.3603 12.1309 16.8742 11.91C16.9506 11.8772 17 11.802 17 11.7189V10V9.42135C17 9.18865 17.1886 9 17.4214 9C17.6821 9 17.8811 8.76509 17.8165 8.51247C17.7483 8.24553 17.6615 7.98302 17.5568 7.72688C17.2578 6.99598 16.8175 6.33125 16.2612 5.77075C15.705 5.21026 15.0436 4.76501 14.3149 4.46052C13.5863 4.15603 12.8048 3.99828 12.0151 3.9963C11.2254 3.99431 10.4431 4.14814 9.71293 4.44897C8.98278 4.74979 8.31916 5.19171 7.76006 5.7494C7.20097 6.30709 6.75739 6.9696 6.45473 7.69899C6.34479 7.96393 6.25423 8.23581 6.18348 8.51247C6.11887 8.76509 6.3179 9 6.57865 9C6.81135 9 7 9.18865 7 9.42135V10V12C7 12.5523 6.55228 13 6 13H5C4.44772 13 4 12.5523 4 12V10Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.036 8.31055C12.0939 7.73394 10.8627 8.03025 10.2861 8.97237C9.7095 9.9145 10.0058 11.1457 10.9479 11.7223C11.419 12.0106 11.5671 12.6262 11.2788 13.0972C10.9905 13.5683 10.3749 13.7165 9.90389 13.4281C8.01964 12.2749 7.42703 9.81257 8.58025 7.92833C9.73347 6.04408 12.1958 5.45147 14.0801 6.60469C15.5352 7.49527 16.2182 9.16497 15.9259 10.7448C15.8255 11.2879 15.3038 11.6467 14.7607 11.5462C14.2176 11.4457 13.8588 10.9241 13.9593 10.381C14.1059 9.58846 13.7616 8.75461 13.036 8.31055Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSupport;
