import { SVGProps } from 'react';

const SvgHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 512 512"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M192 204.8h128c7.070 0 12.8-5.73 12.8-12.8s-5.729-12.8-12.8-12.8h-128c-7.071 0-12.8 5.73-12.8 12.8s5.73 12.8 12.8 12.8z"></path>
    <path d="M192 153.6h25.6c7.071 0 12.8-5.73 12.8-12.8s-5.73-12.8-12.8-12.8h-25.6c-7.071 0-12.8 5.729-12.8 12.8s5.73 12.8 12.8 12.8z"></path>
    <path d="M332.8 243.2c0-7.071-5.729-12.8-12.8-12.8h-128c-7.071 0-12.8 5.73-12.8 12.8s5.73 12.8 12.8 12.8h128c7.070 0 12.8-5.73 12.8-12.8z"></path>
    <path d="M396.8 134.651v-45.051c0-7.070-5.729-12.8-12.8-12.8h-59.51l-60.493-48.394c-4.675-3.738-11.315-3.738-15.99 0l-60.498 48.394h-59.51c-7.070 0-12.8 5.73-12.8 12.8v45.051l-59.197 47.36c-3.036 2.422-4.803 6.103-4.803 9.989v256c0 7.070 5.73 12.8 12.8 12.8h384c7.070 0 12.8-5.729 12.8-12.8v-256c0-3.886-1.767-7.567-4.803-9.994l-59.197-47.355zM396.8 167.434l30.71 24.566-30.71 24.566v-49.131zM256 54.794l27.51 22.006h-55.019l27.51-22.006zM140.8 102.4h230.4v134.651l-115.2 92.16-115.2-92.16v-134.651zM115.2 167.434v49.136l-30.71-24.571 30.71-24.566zM435.2 435.2h-358.4v-216.566l171.202 136.96c0.583 0.466 1.198 0.875 1.838 1.229 3.83 2.104 8.484 2.104 12.314 0 0.64-0.348 1.255-0.758 1.838-1.229l171.208-136.96v216.566z"></path>
  </svg>
);

export default SvgHistory;
