import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useApps, useUserStore } from 'src/hooks';
import useInvoiceStore from 'src/hooks/useInvoiceStore';
import useWindowSize from 'src/hooks/useWindowSize';
import MenuItem from 'src/atoms/menuItem/MenuItem';
import MenuItemProps from 'src/atoms/menuItem/MenuItem.types';
import { bzV1AppBaseUrl, defaultAppIds, miniApps, v2MiniApps } from 'src/types/appConstants';
import { Maybe, PaymentStatus, useGetPayments, useListUserApps } from 'src/types/bizcuitApi';
import { findNthOccurrence } from 'src/utils';
import { bzV2PathPrefix, routerBaseName } from 'src/tokens/Paths';

const Menu: FC = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const {
    state: { user },
  } = useUserStore();
  const {
    state: { apps },
    actions: { setApps },
  } = useApps();
  const {
    state: { administrationList },
  } = useInvoiceStore();
  const { width } = useWindowSize();
  const [menuItems, setMenuItems] = useState<Nullable<MenuItemProps[]>>();
  const [activeItem, setActiveItem] = useState<Nullable<string>>();
  const [hasNewPayments, setHasNewPayments] = useState<boolean>(false);

  useListUserApps({
    skip: !user,
    onCompleted: (data) => {
      data?.listUserApps && setApps(data.listUserApps);
    },
    onError: (error) => {
      console.warn(error);
    },
  });

  useGetPayments({
    fetchPolicy: 'network-only',
    variables: {
      offset: 0,
      limit: 1,
      filter: {
        status: [PaymentStatus.New],
      },
    },
    onCompleted: (data) => {
      setHasNewPayments(!!data?.getPayments?.length);
    },
    onError: (error) => {
      console.warn(error);
    },
  });

  const hasNewDocuments = useCallback(() => {
    if (!administrationList) return false;

    const administrationsWithNewDocuments = administrationList.filter(
      (administration) => administration?.newDocumentCount && administration.newDocumentCount > 0,
    );
    return administrationsWithNewDocuments.length > 0;
  }, [administrationList]);

  const getAmountOfMenuItems = useCallback(() => {
    if (width <= 280) {
      return 3;
    } else if (width <= 540) {
      return 4;
    } else if (width <= 1024) {
      return 9;
    } else return 14;
  }, [width]);

  const isDefaultApp = (appId: string) => defaultAppIds.includes(appId);

  const getAppData = useCallback((appId: string) => apps.find((app) => app?.id === appId), [apps]);

  const getAppLink = useCallback(
    (appId: string) => {
      const appUrl = getAppData(appId)?.appUrl;
      const isV2App = appUrl?.includes(bzV2PathPrefix) || Object.values(v2MiniApps).includes(appId);

      if (isV2App) {
        if (!appUrl) return `/app/${appId}`;

        const urlPathStartIndex =
          routerBaseName === '/'
            ? findNthOccurrence(appUrl, '/', 4)
            : appUrl.indexOf(routerBaseName) + routerBaseName.length;

        const v2AppPath = appUrl.substring(urlPathStartIndex);
        return v2AppPath;
      }

      if (isDefaultApp(appId)) {
        const v1DefaultAppUrl = `${bzV1AppBaseUrl}/${appId}`;
        return v1DefaultAppUrl;
      }

      const v1PartnerAppUrl = `${bzV1AppBaseUrl}/apps/${appId}`;
      return v1PartnerAppUrl;
    },
    [getAppData],
  );

  const getAppsByIds = useCallback(
    (appIds: Maybe<string>[], amount: number): MenuItemProps[] => {
      const localizedAppNames = t('menu', {
        returnObjects: true,
      }) as Record<string, string>;

      const appsData = appIds
        .flatMap((appId) => {
          if (!appId) return [];
          if (isDefaultApp(appId)) {
            return {
              id: appId,
              name: localizedAppNames[appId],
              linkTo: getAppLink(appId),
              icon: appId,
            };
          }
          const appData = getAppData(appId);
          return {
            id: appId,
            name: appData?.name || '',
            linkTo: getAppLink(appId),
            icon: appData?.icon || '',
          };
        })
        .slice(0, amount);

      return appsData;
    },
    [getAppData, getAppLink, t],
  );

  const getMenuItems = useCallback(() => {
    const menuItems = (
      user.starredApps?.length
        ? getAppsByIds(user.starredApps, getAmountOfMenuItems())
        : getAppsByIds(defaultAppIds, 4)
    ).filter((app) => app.id !== miniApps.apps);

    menuItems.push({
      id: 'apps',
      name: 'Apps',
      linkTo: `${bzV1AppBaseUrl}/apps`,
      icon: 'apps',
    });

    return menuItems;
  }, [user.starredApps, getAppsByIds, getAmountOfMenuItems]);

  useEffect(() => {
    setMenuItems(() => getMenuItems());
  }, [getMenuItems]);

  useEffect(() => {
    if (!menuItems) return;
    const bzV2AppRoutes = Object.values(v2MiniApps);

    let activeItem: MenuItemProps | undefined;
    const fallbackActiveAppId = miniApps.apps;

    const route = bzV2AppRoutes.find((item) => location.pathname.includes(item));
    if (route) {
      activeItem = menuItems.find((item) => item.linkTo.includes(route));
    }

    setActiveItem(activeItem?.id ?? fallbackActiveAppId);
  }, [menuItems, location.pathname, apps]);

  const shouldDisplayBadgeOf = {
    [miniApps.documents]: hasNewDocuments(),
    [miniApps.payments]: hasNewPayments,
  };

  return (
    <aside className="flex flex-row md:flex-col md:items-center justify-center md:justify-start bg-white dark:bg-biz-gray-900 border-r border-biz-old-grey-200 dark:border-cool-grey-700 absolute w-screen md:w-24 h-12 md:h-full bottom-0 md:bottom-auto md:mt-10 z-10 md:py-6">
      <ul className="flex flex-row md:flex-col md:space-y-4 py-1.5 basis-full">
        {menuItems?.map((item, index) => {
          return (
            <MenuItem
              id={item.id}
              name={item.name}
              linkTo={item.linkTo}
              icon={item.icon}
              key={index}
              isActive={item.id === activeItem}
              displayBadge={shouldDisplayBadgeOf[item.id]}
            />
          );
        })}
      </ul>
    </aside>
  );
};

export default Menu;
