import { Outlet } from 'react-router-dom';
import { useOrchestrator } from 'src/hooks/useOrchestrator';
import { useEffect } from 'react';
import { useUserStore } from 'src/hooks';
import { useAtom } from 'jotai';
import { orchestratorAtom } from 'src/contexts/orchestrator-global-state/orchestrator-atoms/orchestrator-global-atoms';

const OrchestratorGuard = () => {
  const {
    methods: { requestStatusCheck },
  } = useOrchestrator();
  const {
    state: { user },
  } = useUserStore();
  const [orchestratorState] = useAtom(orchestratorAtom);

  useEffect(() => {
    if (user.id !== orchestratorState?.approverRequest?.approver?.id) return;

    const requestStatusCheckInterval = setInterval(() => requestStatusCheck(user.id), 2000);

    return () => clearInterval(requestStatusCheckInterval);
  }, [requestStatusCheck, orchestratorState?.approverRequest?.approver?.id, user.id]);

  return <Outlet />;
};

export default OrchestratorGuard;
