import { Outlet, useNavigate } from 'react-router-dom';
import { useSession } from 'src/hooks';
import { Paths } from 'src/tokens';
import { useUserStore } from 'src/hooks';
import { useEffect } from 'react';
import { clearLoginSession } from 'src/helpers';
import * as Sentry from '@sentry/react';

const PrivateRoutesGuard = () => {
  const navigate = useNavigate();

  const {
    state: { user },
  } = useUserStore();

  const {
    actions: { setLoginTo },
  } = useSession();

  useEffect(() => {
    if (!user?.id) return;
    Sentry.setUser({ id: user.id });

    if (!user.active && !user.createdThrough) {
      if (location.pathname.includes('/app')) {
        setLoginTo(`${location.pathname}${location.search}`);
      }
      clearLoginSession();
      navigate(Paths.logInV1.root);
    }
  }, [navigate, user, setLoginTo]);

  return <Outlet />;
};

export default PrivateRoutesGuard;
