import axios from 'axios';
import { CookieKeys } from 'src/tokens';
import { getCookie } from 'src/utils';

const accessToken = getCookie(CookieKeys.accessToken);
export const abortController = new AbortController();

const createRestAPI = () => {
  const config = {
    headers: {
      Authorization: `${process.env.REACT_APP_API_AUTH_HEADER_CREDENTIALS}`,
      'x-bizcuit-access-token': accessToken || '',
    },
    controller: abortController,
  };

  return axios.create(config);
};

const restApi = createRestAPI();

export default restApi;
