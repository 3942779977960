import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Paths from '../../tokens/Paths';
const EnterPin = React.lazy(() => import('./enter-pin/EnterPin'));
const ProcessBinding = React.lazy(() => import('./process-binding/ProcessBinding'));

const DeviceBindingRouter: FC = () => {
  const { segments } = Paths.deviceBinding;

  return (
    <Routes>
      <Route path={segments.enter} element={<EnterPin />} />
      <Route path={segments.process} element={<ProcessBinding />} />
    </Routes>
  );
};

export default DeviceBindingRouter;
