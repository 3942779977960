import * as ErrorTranslations from 'src/translations/nl/genericErrors.json';
import { useTranslation } from 'react-i18next';
import { BizcuitErrorTitleKeys, ErrorTranslationParams } from 'src/tokens';
import { useMemo } from 'react';

export const useErrorTranslation = (
  key: keyof typeof ErrorTranslations,
  params?: ErrorTranslationParams,
) => {
  const { t } = useTranslation('genericErrors');

  return useMemo(() => {
    const title = t(`${key}.title` || params?.title) as BizcuitErrorTitleKeys | string;
    const message = t(`${key}.message`) as string;
    const errorSupport = {
      title: t(`${key}.errorSupport.title`) as string,
      description: t(
        `${key}.errorSupport.description`,
        params?.errorTitle ? { errorTitle: params.errorTitle } : undefined,
      ) as BizcuitErrorTitleKeys | string,
      provideToSupport: t(`${key}.errorSupport.provideToSupport`, {
        techDescription: params?.techDescription ? params?.techDescription : 'genericError',
      }) as BizcuitErrorTitleKeys | string,
    };

    return { title, message, errorSupport };
  }, [key, params?.title, params?.errorTitle, params?.techDescription, t]);
};
