import React, { FC } from 'react';
import SkeletonLoaderProps from './SkeletonLoader.types';
import Container from '../../layout/container/Container';

const SkeletonSelectAccountTypeLoader: FC<SkeletonLoaderProps> = (props: SkeletonLoaderProps) => (
  <div className="flex flex-col animate-pulse">
    {Array.from(Array(props.items || 2)).map((x, index) => (
      <div key={index} className="flex flex-col gap-4">
        <div className="flex flex-col mb-2 md:mb-3">
          <div className="w-1/3 h-3 bg-primary-200 dark:bg-primary-800 rounded-2xl mb-2" />
          <div className="w-1/4 h-2 bg-primary-200 dark:bg-primary-800 rounded-2xl mb-2" />
          <div className="grid md:grid-cols-2 gap-2">
            <Container className="border border-primary-200 dark:border-primary-800 flex flex-grow">
              <div className="w-5 h-5 rounded-full bg-primary-200 dark:bg-primary-800 mr-4 flex-none" />
              <div className="w-20 h-3 bg-primary-200 dark:bg-primary-800 rounded-2xl self-center" />
            </Container>
            <Container className="border border-primary-200 dark:border-primary-800 flex flex-grow">
              <div className="w-5 h-5 rounded-full bg-primary-200 dark:bg-primary-800 mr-4 flex-none" />
              <div className="w-12 h-3 bg-primary-200 dark:bg-primary-800 rounded-2xl self-center" />
            </Container>
          </div>
        </div>
        {(props.items || 2) > index + 1 && (
          <div className="w-full border border-grey-200 dark:border-gray-800 mb-6" />
        )}
      </div>
    ))}
  </div>
);

export default SkeletonSelectAccountTypeLoader;
