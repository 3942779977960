import React, {
  createContext,
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useOnClickOutside } from 'src/hooks';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import DialogProps, { DialogAction, DialogProviderProps, OpenCallbackParams } from './Dialog.types';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from 'src/utils';
import { CookieKeys } from 'src/tokens';

const actionButton = (action: DialogAction, index: number) => {
  const ButtonType = action.cancel ? SecondaryButton : PrimaryButton;
  return (
    <ButtonType className="sm:w-3/5" key={index} onClick={action.onClick}>
      {action.label}
    </ButtonType>
  );
};

const DialogCore: ForwardRefRenderFunction<HTMLDivElement, DialogProps> = (
  { children, actions, isVisible, onClose },
  ref,
) => (
  <>
    <div
      className={`sm:absolute sm:z-40 left-0 top-0 w-full h-full bg-black opacity-20 dark:bg-black dark:opacity-40 ${
        isVisible ? '' : 'hidden'
      }`}
    />
    <div
      ref={ref}
      className={`z-50 sm:rounded-4xl sm:shadow-xl h-full sm:h-5/6 m-auto flex flex-col overflow-auto w-full justify-between absolute left-0 right-0 top-1/2 max-w-2xl sm:max-h-[32rem] sm:min-h-96 -translate-y-1/2 bg-gradient-to-t bg-white dark:bg-biz-gray-700 dark:text-biz-off-white ${
        isVisible ? '' : 'hidden'
      }`}
    >
      <div className="pt-6 px-4 sm:px-6">
        <div className="absolute top-5 right-6 h-5 w-5 m-auto">
          <BizcuitIcon onClick={onClose} icon="close" className="w-6 h-6 dark:text-white" />
        </div>
        {children}
      </div>
      <div className="flex flex-col w-full items-center absolute sm:relative bottom-8 px-4">
        {actions.map(actionButton)}
      </div>
    </div>
  </>
);

const Dialog = forwardRef(DialogCore);

const DialogContext = createContext<{
  open: (params: OpenCallbackParams) => void;
  close: () => void;
}>(null as never);

const useDialog = () => {
  return useContext(DialogContext);
};

const initialState = {
  isVisible: false,
  content: null,
};

const DialogProvider: FC<DialogProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  const [
    { content, title, onConfirm, onCancel, cancelText, confirmText, isVisible, redirectUrl },
    setOptions,
  ] = useState<OpenCallbackParams & { isVisible: boolean }>(initialState);

  const open = useCallback((options: OpenCallbackParams) => {
    setOptions({ isVisible: true, ...options });
  }, []);

  const close = useCallback(() => {
    setOptions((prevState) => ({ ...prevState, isVisible: false }));
  }, []);

  const context = useMemo(() => ({ open, close }), [open, close]);

  const dialogRef = useRef(null);

  useOnClickOutside(dialogRef, () => handleClose());

  const handleCancel = useCallback(() => {
    setOptions(initialState);
    onCancel && onCancel();
  }, [onCancel]);

  const handleRedirect = useCallback(
    (link: string) => {
      removeCookie(CookieKeys.redirectUrl);
      if (link.startsWith('http')) {
        window.location.href = link;
      } else {
        navigate(link);
      }
    },
    [navigate],
  );

  const handleConfirm = useCallback(() => {
    setOptions(initialState);
    if (redirectUrl) {
      handleRedirect(redirectUrl);
      return;
    }
    onConfirm && onConfirm();
  }, [handleRedirect, onConfirm, redirectUrl]);

  const handleClose = useCallback(() => {
    if (redirectUrl) {
      handleRedirect(redirectUrl);
      return;
    }
    setOptions((prevState) => ({ ...prevState, isVisible: false }));
    onCancel && onCancel();
  }, [handleRedirect, onCancel, redirectUrl]);

  const actions: DialogAction[] = [];
  if (confirmText) actions.push({ label: confirmText, onClick: handleConfirm });
  if (cancelText) actions.push({ cancel: true, label: cancelText, onClick: handleCancel });

  return (
    <DialogContext.Provider value={context}>
      {children}
      <Dialog ref={dialogRef} isVisible={isVisible} actions={actions} onClose={handleClose}>
        <p className="text-center text-medium font-weight-500 text-primary-500 dark:text-white">
          {title}
        </p>
        {content}
      </Dialog>
    </DialogContext.Provider>
  );
};

export default Dialog;
export { useDialog, DialogProvider };
