import { arrayConvertors } from '../helpers';

const generateDeviceFingerprint = async (randomAb: Uint8Array, deviceName: string) => {
  const [fingerprintAb, fingerprintHashAb] = await Promise.all([
    crypto.subtle.digest('SHA-256', arrayConvertors.str2ab(deviceName)),
    crypto.subtle.digest(
      'SHA-256',
      arrayConvertors.str2ab(`${deviceName}${arrayConvertors.ab2hex(randomAb)}`),
    ),
  ]);

  const fingerprint = arrayConvertors.ab2hex(fingerprintAb);
  const fingerprintHash = arrayConvertors.ab2hex(fingerprintHashAb);

  return {
    fingerprint,
    fingerprintHash,
  };
};

const generateDerivedKey = async (pincode: string, randomAb: Uint8Array, fingerprint: string) => {
  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    arrayConvertors.str2ab(`${fingerprint}${pincode}`),
    { name: 'PBKDF2' },
    false,
    ['deriveKey'],
  );
  const derivedKey = await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: randomAb,
      iterations: 8192,
      hash: 'SHA-256',
    },
    keyMaterial,
    { name: 'AES-GCM', length: 256 },
    true,
    ['wrapKey', 'unwrapKey', 'encrypt', 'decrypt'],
  );

  return derivedKey;
};

export { generateDeviceFingerprint, generateDerivedKey };
