import { atom } from 'jotai';
import { globalStore } from 'src/contexts/globalStore';
import { CookieKeys } from 'src/tokens';
import { removeCookie, setCookie } from 'src/utils';

const loginToAtom = atom<string | undefined>(undefined);

globalStore.sub(loginToAtom, () => {
  const loginToLifeTimeHours = 1;
  const loginToUrl = globalStore.get(loginToAtom);

  if (typeof loginToUrl === 'string') {
    setCookie(CookieKeys.loginTo, loginToUrl, loginToLifeTimeHours);
  }
  if (!loginToUrl) {
    removeCookie(CookieKeys.loginTo);
  }
});

if (process.env.NODE_ENV !== 'production') {
  loginToAtom.debugLabel = 'LoginTo';
}

export { loginToAtom };
