import React, { FC, lazy } from 'react';
import { Route, Routes } from 'react-router';
import Paths from '../../tokens/Paths';
const SupportRequested = lazy(() => import('./support-requested/SupportRequested'));
const Support = lazy(() => import('./support/Support'));

const SupportRouter: FC = () => {
  const { segments } = Paths.support;

  return (
    <Routes>
      <Route path={segments.requested} element={<SupportRequested />} />
      <Route index element={<Support />} />
    </Routes>
  );
};

export default SupportRouter;
