import React, { FC } from 'react';
import { PageContent } from '../../../atoms/layout/menu-page';
import SpinLoader from '../../../atoms/loaders/spin-loader/SpinLoader';
import Paragraph from '../../../atoms/text/paragraph/Paragraph';
import PageFooter from '../../../atoms/layout/menu-page/PageFooter';
import PrimaryButton from '../../../atoms/buttons/primary-button/PrimaryButton';
import { useTranslation } from 'react-i18next';

const HixFallback: FC = () => {
  const { t } = useTranslation('hix');

  return (
    <>
      <PageContent>
        <SpinLoader failed />
        <div className="font-weight-700 text-primary-900 dark:text-primary-50 px-12 md:text-4xl sm:text-2xl text-xl text-center">
          {t('main.header_error')}
        </div>
        <Paragraph className="my-6 text-center">{t('main.paragraph_1_error')}</Paragraph>
        <Paragraph className="text-center">
          {t('main.paragraph_2_error')}
          <a className="underline" href="mailto:support@bizcuit.nl">
            {t('general.support_email')}
          </a>
        </Paragraph>
      </PageContent>
      <PageFooter>
        <PrimaryButton
          onClick={() => {
            location.replace(`${process.env.REACT_APP_LEGACY_APP_URL}/app/apps`);
          }}
        >
          {t('main.go_back')}
        </PrimaryButton>
      </PageFooter>
    </>
  );
};

export default HixFallback;
