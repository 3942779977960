import { useEffect } from 'react';

const googleTagManagerApiURL = 'https://www.googletagmanager.com';
const fallbackGaTagId = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID ?? '';

const useGoogleAnalytics = (gaTagId: string = fallbackGaTagId): void => {
  if (!gaTagId) console.error('Google tag id is not provided');

  useEffect(() => {
    if (window.dataLayer && window.gtag) return;

    const gtagConnectionScript = document.createElement('script');
    gtagConnectionScript.async = true;
    gtagConnectionScript.src = `${googleTagManagerApiURL}/gtag/js?id=${gaTagId}`;
    document.head.appendChild(gtagConnectionScript);

    const dataLayerScript = document.createElement('script');
    dataLayerScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gaTagId}');
    `;
    document.head.appendChild(dataLayerScript);
  }, [gaTagId]);
};

export default useGoogleAnalytics;
