import { SVGProps } from 'react';

const SvgFaceId = (props: SVGProps<SVGSVGElement>) => (
  <svg width={80} height={80} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.114 21.943v-8.914c0-5.036 3.88-8.915 8.915-8.915h8.914a2.057 2.057 0 0 0 0-4.114h-8.914C5.72 0 0 5.721 0 13.029v8.914a2.057 2.057 0 0 0 4.114 0ZM75.886 21.943v-8.914c0-5.036-3.88-8.915-8.915-8.915h-8.914a2.057 2.057 0 0 1 0-4.114h8.914C74.28 0 80 5.721 80 13.029v8.914a2.057 2.057 0 0 1-4.114 0ZM4.114 58.057v8.914c0 5.036 3.88 8.915 8.915 8.915h8.914a2.057 2.057 0 0 1 0 4.114h-8.914C5.72 80 0 74.279 0 66.971v-8.914a2.057 2.057 0 0 1 4.114 0ZM75.886 58.057v8.914c0 5.036-3.88 8.915-8.915 8.915h-8.914a2.057 2.057 0 0 0 0 4.114h8.914C74.28 80 80 74.279 80 66.971v-8.914a2.057 2.057 0 0 0-4.114 0ZM21.754 30.213v5.717c0 1.184.895 2.143 2 2.143 1.104 0 2-.96 2-2.143v-5.717c0-1.184-.896-2.143-2-2.143-1.105 0-2 .96-2 2.143ZM54.736 30.213v5.717c0 1.184.896 2.143 2 2.143 1.105 0 2-.96 2-2.143v-5.717c0-1.184-.895-2.143-2-2.143-1.104 0-2 .96-2 2.143ZM25.933 59.083c3.9 3.64 8.625 5.478 14.068 5.478 5.442 0 10.166-1.837 14.068-5.478a2.105 2.105 0 1 0-2.873-3.078C48.079 58.913 44.383 60.35 40 60.35c-4.383 0-8.08-1.438-11.196-4.346a2.105 2.105 0 1 0-2.872 3.078ZM40 30.175v14.737c0 .943-.46 1.404-1.409 1.404h-1.398a2.105 2.105 0 1 0 0 4.21h1.398c3.273 0 5.62-2.344 5.62-5.614V30.175a2.105 2.105 0 0 0-4.21 0Z"
      fill="#2B2927"
    />
  </svg>
);

export default SvgFaceId;
