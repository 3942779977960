import React from 'react';
import { getAppIcon } from 'src/helpers/getAppIcon';
import MenuItemProps from './MenuItem.types';
import { useNavigate } from 'react-router-dom';

const MenuItem: React.FC<MenuItemProps> = ({ id, name, linkTo, icon, isActive, displayBadge }) => {
  const navigate = useNavigate();

  const onMenuItemClick = () => {
    const isRelativePath = linkTo.startsWith('/');

    if (isRelativePath) navigate(linkTo);
    else window.location.replace(linkTo);
  };

  return (
    <button onClick={onMenuItemClick} className="grow md:grow-0">
      <li
        className={`flex flex-col justify-center items-center ${
          isActive ? 'text-primary-500' : 'text-biz-old-grey-300'
        }`}
      >
        {getAppIcon({
          icon,
          id,
          className: `
            w-[22.4px] h-[22.4px] filter 
            ${
              isActive
                ? 'filter-none fill-primary-500 dark:fill-primary-500'
                : 'fill-biz-old-grey-300 dark:fill-biz-old-grey-300'
            }
          `,
        })}
        {displayBadge ? (
          <span className="absolute p-1 rounded-full bg-primary-500 ml-5 top-2 md:ml-0 md:right-8 md:mb-11 md:top-auto " />
        ) : null}
        <span className="mt-0.5 font-medium text-xs text-center 600 overflow-hidden truncate md:w-20 w-4/5">
          {name}
        </span>
      </li>
    </button>
  );
};

export default MenuItem;
