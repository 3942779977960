import { GeneralObject } from 'src/types/generic';

const generateQueryParamsString = (params: GeneralObject) => {
  return Object.entries(params)
    .filter(([_, val]) => val !== null && val !== undefined)
    .map(([key, val]) => `${key}=${encodeURIComponent(`${val}`)}`)
    .join('&');
};

export default generateQueryParamsString;
