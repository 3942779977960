const isIncrementalNumber = (value: string): boolean => {
  const digits = value.split('');
  const incrementalNumber = digits.reduce((acc, cur, index) => {
    if (index === 0) return acc;
    return acc && Number(cur) === Number(value[index - 1]) + 1;
  }, true);
  return incrementalNumber;
};

const isDecrementalNumber = (value: string): boolean => {
  const digits = value.split('');
  const reversedDigits = digits.reverse();

  return isIncrementalNumber(reversedDigits.join(''));
};

const isTheSameNumber = (value: string) => {
  const sameNumberRegex = /^(\d)\1{5}$/g;
  return value.match(sameNumberRegex);
};

const checkPinIntegrity = (pin: string): boolean => {
  const isPinWeak = isTheSameNumber(pin) || isIncrementalNumber(pin) || isDecrementalNumber(pin);
  return !isPinWeak;
};

export default checkPinIntegrity;
