import { atom } from 'jotai';
import { getSubscriptionPlans } from 'src/services/metering';
import { createInactiveUserSession } from 'src/services/user';
import { fetchBillingAddressAtom } from './fetchBillingAddressAtom';
import { setUserSessionAtom } from './setUserSessionAtom';
import { setUserSingleValueAtom } from './setUserSingleValueAtom';
import { useNativeCommunication } from 'src/hooks';

const createSignUpSessionAtom = atom(null, async (_get, set, userId: string, step: string) => {
  const { success, data, error } = await createInactiveUserSession(userId);
  const { isNativeApp, setNativeAppUser } = useNativeCommunication();

  const steps = [
    'enter-name',
    'enter-account-type',
    'enter-company',
    '/signup/enter-name',
    '/signup/enter-account-type',
    '/signup/enter-company',
  ];

  if (success && data) {
    const { user, userSession, accessToken } = data;
    const displayName = user.displayName;

    set(setUserSessionAtom, user, userSession, accessToken);

    if (displayName) {
      const [firstName, ...lastNames] = displayName.split(' ');
      const lastName = lastNames.join(' ');
      set(setUserSingleValueAtom, 'firstName', firstName);
      set(setUserSingleValueAtom, 'lastName', lastName);
    }

    if (!steps.includes(step)) {
      set(fetchBillingAddressAtom);

      const { data: subsPlans } = await getSubscriptionPlans();
      set(setUserSingleValueAtom, 'subscription', subsPlans?.[0]);
    }

    if (isNativeApp()) setNativeAppUser(user);
  }
  return { success, error };
});

export { createSignUpSessionAtom };
