import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import RadioContainer from 'src/atoms/inputs/radio-container/RadioContainer';
import { useGetAuthConsentList } from 'src/types/bizcuitApi';
import { BankAccountListOption } from 'src/molecules/bank-accounts-list/BankAccountsList.types';
import SkeletonSelectAccountTypeLoader from 'src/atoms/loaders/skeleton-loader/SkeletonSelectAccountTypeLoader';

interface AccountType {
  name: string;
  companyAccount: boolean;
}

const SelectAccountType = () => {
  const { t } = useTranslation(['cdd', 'common']);
  const [showSpinner, setShowSpinner] = useState(false);
  const [indicatedAccountTypes, setIndicatedAccountTypes] = useState<AccountType[]>([]);

  const authConsentList = useGetAuthConsentList({
    fetchPolicy: 'cache-and-network',
  });

  const [groupedBankAccountList, setGroupedBankAccountList] = useState<
    Record<string, BankAccountListOption[]>
  >({});

  useEffect(() => {
    if (!authConsentList.data?.getBankAccounts) return;

    const { getBankAccounts: userBankAccounts, listAdministrations: userAdministrations } =
      authConsentList.data;

    const groupedBankAccounts = userBankAccounts.reduce<Record<string, BankAccountListOption[]>>(
      (previousValue, currentAccount) => {
        if (currentAccount.companyAccount !== null) return previousValue;

        const administrationName =
          userAdministrations?.find((admin) => admin?.id === currentAccount.administrationId)
            ?.name ?? 'Unknown';

        if (!previousValue[administrationName]) {
          previousValue[administrationName] = [];
        }

        previousValue[administrationName].push({
          ...currentAccount,
          bank: currentAccount.bank,
        } as BankAccountListOption);

        return previousValue;
      },
      {},
    );

    setGroupedBankAccountList(groupedBankAccounts);
  }, [authConsentList.data]);

  const handleConfirm = () => {
    setShowSpinner(true);
    //TODO replace with real functionality
    console.log(indicatedAccountTypes);
  };

  const handleTypeSelection = (company: string, companyAccount: boolean) => {
    setIndicatedAccountTypes((prevState) => {
      if (prevState.some((item) => item.name === company)) {
        return prevState.map((item) =>
          item.name === company ? { ...item, companyAccount: companyAccount } : item,
        );
      }

      return [...prevState, { name: company, companyAccount: companyAccount }];
    });
  };

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      showBackButton={false}
      spaceBetween={false}
      footer={
        <footer className="flex justify-center pb-6">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          <PrimaryButton
            className="md:w-80 mt-4 md:mt-6"
            type="submit"
            form="select-account-type"
            showSpinner={showSpinner}
            isDisabled={Object.keys(groupedBankAccountList).length !== indicatedAccountTypes.length}
          >
            {t('common:button.confirm')}
          </PrimaryButton>
        </footer>
      }
    >
      <>
        <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
          {t('cdd:selectAccountType.primaryHeader')}
        </h1>
        {!Object.keys(groupedBankAccountList).length ? (
          <SkeletonSelectAccountTypeLoader />
        ) : (
          <form
            id="select-account-type"
            onSubmit={(e) => {
              e.preventDefault();
              handleConfirm();
            }}
          >
            {Object.entries(groupedBankAccountList).map(([key, value], index) => {
              return (
                <div key={index}>
                  <div className="flex flex-col gap-4 mb-6">
                    <div>
                      <p className="text-medium font-weight-500 text-grey-900 dark:text-white">
                        {key}
                      </p>
                      {value.slice(0, 3).map((account, index) => (
                        <p key={index} className="text-x-small text-grey-700 dark:text-white">
                          {account.iban}
                        </p>
                      ))}
                      {value.length > 3 && (
                        <p className="text-x-small text-grey-700 dark:text-white">...</p>
                      )}
                    </div>
                    <div className="grid md:grid-cols-2 gap-2">
                      <RadioContainer
                        name="business"
                        value="business"
                        isChecked={
                          indicatedAccountTypes.find((item) => item.name === key)
                            ?.companyAccount === true
                        }
                        onSelected={() => handleTypeSelection(key, true)}
                      >
                        <p>{t('common:business')}</p>
                      </RadioContainer>
                      <RadioContainer
                        name="private"
                        value="private"
                        isChecked={
                          indicatedAccountTypes.find((item) => item.name === key)
                            ?.companyAccount === false
                        }
                        onSelected={() => handleTypeSelection(key, false)}
                      >
                        <p>{t('common:private')}</p>
                      </RadioContainer>
                    </div>
                  </div>
                  {Object.keys(groupedBankAccountList).length > index + 1 && (
                    <div className="w-full border border-grey-200 dark:border-gray-800 mb-6" />
                  )}
                </div>
              );
            })}
          </form>
        )}
      </>
    </FlowLayout>
  );
};

export default SelectAccountType;
