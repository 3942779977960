import { CustomIconProps } from '../icons.types';

const ErrorLoading = ({ widthClassName = 'w-20', heightClassName = 'h-20' }: CustomIconProps) => (
  <div className={`relative flex items-center rounded-full w-32 h-32`}>
    <svg
      className={`absolute mx-auto inset-x-0 ${widthClassName} ${heightClassName}`}
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 12 12"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="9" y1="3" x2="3" y2="9"></line>
      <line x1="3" y1="3" x2="9" y2="9"></line>
    </svg>
  </div>
);

export default ErrorLoading;
