const PageTestIdKeys = {
  enterSignup: {
    startSignup: 'confirm-create-account',
    logIn: 'confirm-login',
  },
  enterEmail: {
    emailInput: 'email-input',
    login: 'login',
    confirm: 'confirm-email',
  },
  enterName: {
    inputFirstname: 'firstname',
    inputLastname: 'lastname',
    confirm: 'confirm',
  },
  enterAccountType: {
    confirmBusiness: 'confirm-Zakelijk',
    confirmPrivate: 'confirm-Particulier',
  },
  enterCompany: {
    confirmBusiness: 'confirm-company',
    confirmPrivate: 'confirm-private',
    searchInput: 'search',
    searchClear: 'kvk-search-clear',
  },
  enterSubscription: {
    confirm: 'confirm-subscription',
  },
  enterIban: {
    inputIban: 'enter-iban',
    confirm: 'confirm-iban',
    confirmCheckbox: 'confirm-check',
  },
  verify: {
    confirmCheckbox: 'confirm-check',
    confirm: 'confirm-signup',
  },
  success: {
    confirm: 'confirm-success',
  },
  selectDevice: {
    confirmDevice: 'confirm-device',
  },
  enterPin: {
    inputPin: 'pin',
  },
  gotoApp: {
    confirm: 'confirm-go-to-app',
  },
};

export { PageTestIdKeys };
