import React, { FC } from 'react';
import IconButtonProps from './IconButton.types';
import { ComponentTestIdKeys } from 'src/tokens';

const IconButton: FC<IconButtonProps> = ({ children, testId, onClick }) => (
  <span
    data-testid={testId ? `${ComponentTestIdKeys.IconButton}-${testId}` : ''}
    className="cursor-pointer"
    onClick={onClick}
  >
    {children}
  </span>
);

export default IconButton;
