import { FlowData, FlowType, Maybe } from 'src/types/bizcuitApi';

const isFirstActionOfLinearFlow = (flow: FlowData) => {
  const firstAction = flow.actions[0];

  if (flow.flowType === FlowType.Linear && firstAction?.__typename === 'FlowData') {
    return firstAction.id === firstAction.actions[0]?.id;
  }

  return false;
};

const useShouldShowBackButtonInFlow = (flow?: Maybe<FlowData>) => {
  if (!flow) return false;
  return isFirstActionOfLinearFlow(flow);
};

export { useShouldShowBackButtonInFlow };
