import { Session } from 'src/interfaces/Session';
import User from 'src/interfaces/User';
import { UserAnalytics } from 'src/types/analytics';
import { IdentificationLevel } from 'src/types/bizcuitApi';

const userInitial: User = {
  id: '',
  email: '',
  partnerId: '',
  sessionId: '',
  firstName: '',
  lastName: '',
  billingIban: '',
  billingAccepted: false,
  active: false,
  betaTester: false,
  embeddedUser: false,
  termsApproved: false,
  termsPaymentsApproved: false,
  identificationLevel: IdentificationLevel.None,
};

const userSessionInitial: Session = {
  id: '',
  lastUsedAt: null,
  deviceName: null,
  biometricsPublicKey: null,
};

const userAnalyticsInitial: UserAnalytics = {
  id: '',
  partnerId: '',
  betaTester: false,
  active: false,
  partnerReference: '',
  partnerName: '',
};

export { userInitial, userSessionInitial, userAnalyticsInitial };
