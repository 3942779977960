import { useAtomValue, useSetAtom } from 'jotai';
import {
  partnerAtom,
  partnerIdAtom,
  partnerReferenceAtom,
  brandingPartnerIdAtom,
  partnerAuthorisationAtom,
  setPartnerTheme,
  setPartnerState,
  initializePartnerState,
  areScopesInPartnerAuthorization,
  initializePartnerAuthorisation,
  cleanPersistedPartnerState,
} from 'src/contexts/partner-global-state/partner-atoms';
import { StoreHookContract } from 'src/types/store';

const usePartnerStore = () => {
  return {
    state: {
      partner: useAtomValue(partnerAtom),
      partnerId: useAtomValue(partnerIdAtom),
      partnerReference: useAtomValue(partnerReferenceAtom),
      brandingPartnerId: useAtomValue(brandingPartnerIdAtom),
      partnerAuthorisation: useAtomValue(partnerAuthorisationAtom),
    },
    actions: {
      setPartnerState: useSetAtom(setPartnerState),
      setPartnerTheme: useSetAtom(setPartnerTheme),
      initializePartnerState: useSetAtom(initializePartnerState),
      areScopesInPartnerAuthorization: useSetAtom(areScopesInPartnerAuthorization),
      initializePartnerAuthorisation: useSetAtom(initializePartnerAuthorisation),
      cleanPersistedPartnerState,
    },
  } satisfies StoreHookContract;
};

export default usePartnerStore;
