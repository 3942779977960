import { useAtomValue, useSetAtom } from 'jotai';
import {
  appsAtom,
  getAppIconUrlAtom,
  setAppsAtom,
} from 'src/contexts/apps-global-state/apps-atoms';

const useApps = () => {
  return {
    state: {
      apps: useAtomValue(appsAtom),
      getAppIconUrl: useAtomValue(getAppIconUrlAtom),
    },
    actions: {
      setApps: useSetAtom(setAppsAtom),
    },
  };
};

export default useApps;
