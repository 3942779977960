import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import { SetNameResult } from './types';

export const enterUsername = async (
  firstName: string,
  lastName: string,
  displayName: string,
): Promise<SetNameResult> => {
  try {
    const { data } = await restApi.post<SetNameResult>(`/api/latest/user/set_name`, {
      firstName,
      lastName,
      displayName,
    });
    return { data: data.data, success: true };
  } catch (error: unknown) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'enterUsername',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
