import { getLocalStorageObject } from 'src/helpers';
import { userInitial } from '../user-state-atoms';
import { LocalStorageKeys } from 'src/tokens';
import { UserState } from '../../types';

const getPersistedUserState = () => {
  const storedUserState = getLocalStorageObject<UserState>(LocalStorageKeys.user);

  return {
    userSession: storedUserState?.userSession,
    user: {
      ...userInitial,
      ...(storedUserState?.user || {}),
    },
  };
};

export { getPersistedUserState };
