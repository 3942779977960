import { FC, lazy, Suspense, useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast, ToastItem } from 'react-toastify';
import { useAnalytics } from './hooks/useAnalytics';
import { useErrorDialog, usePartnerStore, useUserStore, useErrorTranslation } from 'src/hooks';
import { parseQueryParamsString, sessionUtil } from 'src/helpers';
import BiometricsRouter from './pages/biometrics/BiometricsRouter';
import DeviceBindingRouter from './pages/device-binding/DeviceBindingRouter';
import SelectDeviceRouter from './pages/select-device/SelectDeviceRouter';
import SignInRouter from './pages/sign-in/SignInRouter';
import SignUpRouter from './pages/sign-up/SignUpRouter';
import SupportRouter from './pages/support/SupportRouter';
import UserIdentityRouter from './pages/user-identity/UserIdentityRouter';
import { LocalStorageKeys } from './tokens';
import Paths from 'src/tokens/Paths';
import { EventNames } from './types/analytics';
import useSession from './hooks/useSession';
import { Partner, useGetPartnerLazyQuery } from 'src/types/bizcuitApi';
import { clearLoginSession } from './helpers/clearLoginSession';
import PublicRoutesGuard from './organisms/public-routes-guard/PublicRoutesGuard';

const Home = lazy(() => import('./pages/home/Home'));

const AuthRouter: FC = () => {
  const { search, pathname } = useLocation();
  const { t } = useTranslation(['signup', 'bookkeepingSystem']);
  const { trackEvent, trackUserIdentity } = useAnalytics();
  const errorDialog = useErrorDialog();
  const navigate = useNavigate();
  const queryParams = parseQueryParamsString(search);
  const resumeStep = queryParams.step ? queryParams.step : pathname;
  const errorMessage = useErrorTranslation('signUp');

  const {
    state: { user },
    actions: { createSignUpSession, cleanPersistedUserState, setUserValue },
  } = useUserStore();

  const {
    actions: { initializePartnerState, initializePartnerAuthorisation, cleanPersistedPartnerState },
    state: { partner: partnerState },
  } = usePartnerStore();

  const {
    actions: { setLoginTo },
  } = useSession();

  const [getPartner, { loading: getPartnerLoading }] = useGetPartnerLazyQuery();

  useEffect(() => {
    const resumeSignUp = async () => {
      const { error } = await createSignUpSession(queryParams.user_id, resumeStep);
      trackEvent(EventNames.signUpResumed, { step: resumeStep });
      if (user.id) {
        trackUserIdentity({ user, ...(partnerState && { partner: partnerState }) });
      }
      if (error && error.message === 'User is already activated') {
        cleanPersistedUserState();
        cleanPersistedPartnerState();
        localStorage.removeItem(LocalStorageKeys.subscriptionPlan);
        navigate('/error'); // TODO implement generic error page (planned separate task)
        const unsubscribe = toast.onChange((payload: ToastItem) => {
          switch (payload.status) {
            case 'removed':
              navigate(Paths.logInV1.root); // TODO replace by: navigate(Paths.signIn.root); when Sign In v2 is ready
              unsubscribe();
              break;
          }
        });

        Sentry.captureException('Something went wrong during sign up process');
        errorDialog.open(errorMessage);
      }
    };

    if (queryParams.user_id && queryParams.user_id !== user.id) {
      resumeSignUp();
    }
  }, [
    createSignUpSession,
    navigate,
    resumeStep,
    t,
    user.id,
    queryParams.user_id,
    queryParams.app,
    cleanPersistedUserState,
    cleanPersistedPartnerState,
    setLoginTo,
    errorDialog,
    errorMessage,
    user,
    trackEvent,
    trackUserIdentity,
    partnerState,
  ]);

  const initPartner = useCallback(
    async (isAuthPath: boolean) => {
      if (getPartnerLoading) {
        return;
      }

      const { data: getPartnerResponse } = await getPartner({
        variables: {
          partnerId: queryParams.client_id,
          brandingPartnerId: queryParams.branding_client_id,
        },
      });

      if (!getPartnerResponse?.getPartner) return;

      setUserValue('partnerId', queryParams.client_id);

      const partner = getPartnerResponse?.getPartner?.partner as Partner;

      const clientReferenceFromUrl = queryParams.client_reference
        ? decodeURIComponent(queryParams.client_reference)
        : queryParams.client_reference;

      const partnerInitParams = {
        clientId: queryParams.client_id,
        clientReference: clientReferenceFromUrl,
        brandingClientId: queryParams.branding_client_id,
        partner,
      };

      await initializePartnerState(partnerInitParams);

      if (isAuthPath) {
        const { partnerAuthorisation, error } = await initializePartnerAuthorisation({
          clientId: queryParams.client_id,
          clientReference: clientReferenceFromUrl,
          brandingClientId: queryParams.branding_client_id,
          responseType: queryParams.response_type,
          redirectUri: queryParams.redirect_uri,
          codeChallenge: queryParams.code_challenge,
          codeChallengeMethod: queryParams.code_challenge_method,
          prompt: queryParams.prompt,
          scope: queryParams.scope,
          state: queryParams.state,
          step: queryParams.step,
          userId: queryParams.user_id,
        });

        if (error) {
          Sentry.captureException('Something went wrong during partner authorisation');
          errorDialog.open(error);
        }

        if (!partnerAuthorisation) return;

        const isValidSession = await sessionUtil.validateSession();

        const { step, clientId, userId } = partnerAuthorisation;

        if (isValidSession) {
          clearLoginSession();
          navigate(Paths.logInV1.root);
          return;
        }

        if (step) {
          navigate(`${Paths.signUp.root}/${step}?user_id=${userId}&client_id=${clientId}`);
        }
      }
    },
    [
      getPartnerLoading,
      queryParams.client_id,
      queryParams.branding_client_id,
      queryParams.client_reference,
      queryParams.response_type,
      queryParams.redirect_uri,
      queryParams.code_challenge,
      queryParams.code_challenge_method,
      queryParams.prompt,
      queryParams.scope,
      queryParams.state,
      queryParams.step,
      queryParams.user_id,
      getPartner,
      setUserValue,
      initializePartnerState,
      initializePartnerAuthorisation,
      errorDialog,
      navigate,
    ],
  );

  useEffect(() => {
    const isSignupPath = [Paths.signUp.root, Paths.home.root].includes(pathname);
    const isAuthPath = pathname === Paths.auth.root;

    if (isSignupPath || isAuthPath) {
      initPartner(isAuthPath);
    }
  }, [initPartner, pathname]);

  //TODO: Fix condition in PublicRoutesGuard to exclude '/auth' BIZCUIT-19222 .
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={`${Paths.auth.root}/*`} element={<SignUpRouter />} />
        <Route path={`${Paths.signUp.root}/*`} element={<SignUpRouter />} />
        <Route element={<PublicRoutesGuard />}>
          <Route path={Paths.home.root} element={<Home />} />
          <Route path={`${Paths.selectDevice.root}/*`} element={<SelectDeviceRouter />} />
          <Route path={`${Paths.signIn.root}/*`} element={<SignInRouter />} />
          <Route path={`${Paths.deviceBinding.root}/*`} element={<DeviceBindingRouter />} />
          <Route path={`${Paths.userIdentity.root}/*`} element={<UserIdentityRouter />} />
          <Route path={`${Paths.support.root}/*`} element={<SupportRouter />} />
          <Route path={`${Paths.biometrics.root}/*`} element={<BiometricsRouter />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AuthRouter;
