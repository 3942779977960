import { getSessionStorageObject } from 'src/helpers';
import { LocalStorageKeys } from 'src/tokens';
import Apps from 'src/interfaces/Apps';

const getPersistedAppsState = (): Apps => {
  const appsContextState = getSessionStorageObject(LocalStorageKeys.apps) as Apps;

  return appsContextState;
};
export { getPersistedAppsState };
