import { OpenApiPaths } from '../MockData';
import { encodeFormData, getPartnerSecret } from '../utils';

const getAccessToken = async (userId: string, partnerId: string) => {
  const formData = {
    user_id: userId,
    client_id: partnerId,
    client_secret: getPartnerSecret(partnerId),
  };

  const oAuthForm = encodeFormData({
    ...formData,
    scope: 'openid account_information administrations manage_bank_accounts manage_administrations',
  });

  const oAuthResult = await fetch(OpenApiPaths.oAuth, {
    method: 'POST',
    body: oAuthForm,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const code = (await oAuthResult.json()).code;

  const tokenForm = encodeFormData({
    ...formData,
    code,
    grant_type: 'authorization_code',
  });

  const tokenResult = await fetch(OpenApiPaths.token, {
    method: 'POST',
    body: tokenForm,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const accessToken = (await tokenResult.json()).access_token;

  return accessToken;
};

export default getAccessToken;
