import React, { FC } from 'react';
import TextProps from './Text.types';

const Text: FC<TextProps> = ({
  variant,
  weight,
  color,
  isCentered,
  className = '',
  as = 'span',
  ...props
}) => {
  const composedClassName = `${variant} ${weight} ${color} ${
    isCentered ? 'text-center' : ''
  } ${className}`;

  switch (as) {
    case 'h1':
      return <h1 {...props} className={composedClassName} />;
    case 'h2':
      return <h2 {...props} className={composedClassName} />;
    case 'h3':
      return <h3 {...props} className={composedClassName} />;
    case 'p':
      return <p {...props} className={composedClassName} />;
    case 'span':
      return <span {...props} className={composedClassName} />;
  }
};

export default Text;
