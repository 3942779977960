import { atom } from 'jotai';
import { getPersistedPartnerState, setPersistedPartnerState } from '../partner-actions-atoms';
import { PartnerAuthorisation } from 'src/interfaces/Partner';
import { globalStore } from 'src/contexts/globalStore';

const partnerAuthorisationAtom = atom<PartnerAuthorisation | undefined>(
  getPersistedPartnerState()?.partnerAuthorisation,
);

globalStore.sub(partnerAuthorisationAtom, () => {
  setPersistedPartnerState({
    ...getPersistedPartnerState(),
    partnerAuthorisation: globalStore.get(partnerAuthorisationAtom),
  });
});

if (process.env.NODE_ENV !== 'production') {
  partnerAuthorisationAtom.debugLabel = 'Partner Authorisation';
}

export { partnerAuthorisationAtom };
