import React, { FC } from 'react';
import Accordion from '../accordion/Accordion';
import { Trans, useTranslation } from 'react-i18next';
import Container from 'src/atoms/layout/container/Container';
import { Link } from 'react-router-dom';

type ErrorContentProps = {
  mainErrorDescription?: string;
  errorSupportTitle?: string;
  errorSupportDescription?: string;
  supportEmail: string;
  provideToSupport?: string;
};

const ErrorContent: FC<ErrorContentProps> = ({
  mainErrorDescription,
  errorSupportTitle,
  errorSupportDescription,
  supportEmail,
  provideToSupport,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col gap-6 my-6">
      <p className="sm:text-center text-x-large font-weight-700 text-gray-800 dark:text-white">
        {mainErrorDescription}
      </p>
      <div>
        <div className="sm:hidden">
          <Accordion
            title={{
              value: errorSupportTitle,
              isBold: true,
            }}
            summaryDescription={errorSupportDescription}
          >
            <p className="pt-3 text-medium-loose text-gray-700 dark:text-white">
              {errorSupportDescription}
            </p>
            <p className="pt-4 text-medium-loose text-gray-600 dark:text-white">
              {provideToSupport}
            </p>
          </Accordion>
        </div>
        <div className="hidden sm:block">
          <Container className="max-h-48 overflow-auto">
            <p className="text-medium-loose font-weight-600 text-gray-700 dark:text-white">
              {errorSupportTitle}
            </p>
            <p className="pt-4 text-medium-loose text-gray-700 dark:text-white">
              {errorSupportDescription}
            </p>
            <p className="pt-4 text-medium-loose text-gray-600 dark:text-white">
              {provideToSupport}
            </p>
          </Container>
        </div>
      </div>
      <div className="flex flex-col gap-1 mb-6">
        <p className="text-center text-x-small-loose sm:text-medium-loose dark:text-slate-300">
          {t('support.cantFigureItOut')}
        </p>
        <Link
          to={`mailto:${supportEmail}`}
          className="text-center text-x-small-loose sm:text-medium underline text-gray-800 dark:text-white"
        >
          <Trans i18nKey={'common:support.contactSupportAndEmail'} values={{ supportEmail }} />
        </Link>
      </div>
    </div>
  );
};

export default ErrorContent;
