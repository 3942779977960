import { logger } from 'src/utils';

const getLocalStorageObject = <T>(entry: string) => {
  try {
    const persistedString = localStorage.getItem(entry) || '';
    if (!persistedString) {
      return null;
    }
    return JSON.parse(persistedString) as T;
  } catch (error) {
    logger.warn(`localStorage error for key ${entry}:\n`, error);
  }

  return null;
};

export default getLocalStorageObject;
