import { ScopeContext } from '@sentry/types/types/scope';

export enum CaptureErrorType {
  Request = 'request',
  Validation = 'validation',
}

type CaptureErrorPropsBase = {
  captureContext?: Omit<Partial<ScopeContext>, 'tags'>;
};

type CaptureErrorPropsRequest = CaptureErrorPropsBase & {
  type: CaptureErrorType.Request;
  request: string;
  error: Error;
};

type CaptureErrorPropsValidation = CaptureErrorPropsBase & {
  type: CaptureErrorType.Validation;
  fields: string[];
};

export type CaptureErrorProps = CaptureErrorPropsRequest | CaptureErrorPropsValidation;

export type CaptureError = (props: CaptureErrorProps) => void;
