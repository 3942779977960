import { setSessionStorageObject } from 'src/helpers';
import { LocalStorageKeys } from 'src/tokens';
import Apps from 'src/interfaces/Apps';

const setPersistedAppsState = (state?: Apps) => {
  if (!state) return;
  setSessionStorageObject(LocalStorageKeys.apps, state);
};

export { setPersistedAppsState };
