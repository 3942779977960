import React, { FC } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import PrimaryHeader from 'src/atoms/text/primary-header/PrimaryHeader';

const SkeletonEmbeddedFlowsTestPageLoader: FC = () => (
  <div className="animate-pulse">
    <PrimaryHeader className="h-12 rounded-xl bg-primary-200 dark:bg-primary-800 cursor-default" />
    <div className="flex flex-col mb-5 gap-8">
      <div className="flex h-16 rounded-2xl shadow-md border border-primary-200 dark:border-primary-800 p-4 py-3 justify-between items-center">
        <div className=" w-[90%] h-4 bg-primary-200 dark:bg-primary-800 rounded-2xl" />
        <BizcuitIcon
          icon="chevron-down"
          className="w-5 h-4 text-primary-500 dark:text-primary-800"
        />
      </div>
      <div className="flex h-16 rounded-2xl shadow-md border border-primary-200 dark:border-primary-800 p-4 py-3 justify-between items-center">
        <div className=" w-44 h-2 bg-primary-200 dark:bg-primary-800 rounded-2xl" />
      </div>
      <div className="flex flex-col items-center">
        <div className="sm:w-[343px] h-12 px-6 bg-primary-200 dark:bg-primary-800 rounded-2xl" />
      </div>
    </div>
    <div className="flex h-16 rounded-2xl shadow-md border border-primary-200 dark:border-primary-800 p-4 py-3 justify-between items-center">
      <div className="w-24 h-2 bg-primary-200 dark:bg-primary-800 rounded-2xl mb-2" />
      <BizcuitIcon icon="chevron-down" className="w-5 h-4 text-primary-500 dark:text-primary-800" />
    </div>
  </div>
);

export default SkeletonEmbeddedFlowsTestPageLoader;
