import { SVGProps } from 'react';

const SvgDesktop = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill={'none'} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m21.958 18.411-.98-.204.98.204Zm-19.916 0-.978.205.978-.205Zm.25-.704.708.707-.707-.707ZM6 5h12V3H6v2Zm12 0a1 1 0 0 1 1 1h2a3 3 0 0 0-3-3v2Zm2.979 13.207A1 1 0 0 1 20 19v2a3 3 0 0 0 2.936-2.384l-1.957-.41ZM20 19H4v2h16v-2ZM4 19a1 1 0 0 1-.979-.793l-1.957.409A3 3 0 0 0 4 21v-2ZM5 6a1 1 0 0 1 1-1V3a3 3 0 0 0-3 3h2ZM3 18.414l1.707-1.707-1.414-1.414L1.586 17 3 18.414ZM5 16V6H3v10h2ZM19 6v10h2V6h-2Zm.293 10.707L21 18.414 22.414 17l-1.707-1.707-1.414 1.414Zm-16.272 1.5a.333.333 0 0 1-.01.183c-.011.029-.02.033-.011.024L1.586 17c-.34.34-.668.922-.522 1.616l1.957-.41Zm19.915.409c.146-.694-.182-1.277-.522-1.616L21 18.414c.009.01 0 .005-.01-.024a.334.334 0 0 1-.011-.183l1.957.409ZM20 15H4v2h16v-2Z"
      fill={props.fill ?? '#000'}
    />
    <path d="M24 18a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z" fill="url(#Desktop_svg__a)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.989 15.845a.75.75 0 0 1 0 1.06l-3.323 3.323a.75.75 0 0 1-1.06 0l-1.511-1.51a.75.75 0 1 1 1.06-1.06l.98.98 2.793-2.793a.75.75 0 0 1 1.06 0Z"
      fill="#003A1E"
    />
    <defs>
      <linearGradient
        id="Desktop_svg__a"
        x1={12}
        y1={12}
        x2={14.08}
        y2={25.526}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#41FFA4" />
        <stop offset={1} stopColor="#53FFD6" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgDesktop;
