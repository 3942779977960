import { createContext, FC, ReactNode, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'src/utils';
import { useDialog } from 'src/organisms/dialog/Dialog';
import { DEFAULT_PARTNER_NAME, DEFAULT_SUPPORT_EMAIL } from 'src/tokens';
import { BizcuitError, BizcuitErrorTitleKeys } from 'src/tokens/BizcuitErrors';
import { usePartnerStore } from 'src/hooks';
import { useErrorTranslation } from './useErrorTranslations';
import ErrorContent from 'src/organisms/dialog/ErrorContent';
import { useAtom } from 'jotai/index';
import { orchestratorAtom } from 'src/contexts/orchestrator-global-state/orchestrator-atoms/orchestrator-global-atoms';
import getTranslation from 'src/helpers/getTranslation';

const ErrorDialogContext = createContext<{
  open: (errKey?: BizcuitError) => void;
}>(null as never);

interface ErrorDialogProviderProps {
  children: ReactNode;
}

const ErrorDialogProvider: FC<ErrorDialogProviderProps> = ({ children }) => {
  const dialog = useDialog();
  const { state: partnerState } = usePartnerStore();
  const [orchestratorState] = useAtom(orchestratorAtom);
  const { t } = useTranslation('common');
  const { t: tErrorTitles } = useTranslation('errorTitles');
  const defaultErrorTranslations = useErrorTranslation('somethingWentWrong');
  const defaultErrorTitlesValue = tErrorTitles('somethingWentWrong');

  const generateErrorByKey = useCallback(
    (error?: BizcuitError) => {
      const values = {
        supportEmail: partnerState?.partner?.supportEmail || DEFAULT_SUPPORT_EMAIL,
        partnerName:
          partnerState?.partner?.appName || partnerState?.partner?.name || DEFAULT_PARTNER_NAME,
        ...error?.values,
      } as Record<string, string>;

      const title = error?.isErrorMessageWithBlankTitle
        ? undefined
        : getTranslation(`accountingHub:${orchestratorState?.flow?.shortTitle?.translationKey}`) ||
          partnerState.partner?.name ||
          tErrorTitles(error?.title as BizcuitErrorTitleKeys, {
            errorTitle: values?.errorTitle,
          }) ||
          defaultErrorTitlesValue;
      const provideToSupport = !error
        ? defaultErrorTranslations.errorSupport.provideToSupport
        : error?.errorSupport?.provideToSupport;
      return {
        title,
        content: (
          <ErrorContent
            mainErrorDescription={error?.message || defaultErrorTranslations.message}
            errorSupportTitle={
              error?.errorSupport?.title || defaultErrorTranslations.errorSupport.title
            }
            errorSupportDescription={
              error?.errorSupport?.description || defaultErrorTranslations.errorSupport.description
            }
            supportEmail={values.supportEmail}
            provideToSupport={provideToSupport}
          />
        ) as ReactNode,
      };
    },
    [
      partnerState.partner?.supportEmail,
      partnerState.partner?.appName,
      partnerState.partner?.name,
      orchestratorState?.flow?.shortTitle?.translationKey,
      tErrorTitles,
      defaultErrorTitlesValue,
      defaultErrorTranslations.errorSupport.provideToSupport,
      defaultErrorTranslations.errorSupport.title,
      defaultErrorTranslations.errorSupport.description,
      defaultErrorTranslations.message,
    ],
  );

  const open = useCallback(
    (err?: BizcuitError) => {
      const error = generateErrorByKey(err);
      requestAnimationFrame(() => {
        dialog.open({
          confirmText: capitalize(t('close')),
          redirectUrl: err?.redirectUrl,
          ...error,
          ...(err?.onConfirm && { onConfirm: err.onConfirm }),
          ...(err?.onCancel && { onCancel: err.onCancel }),
        });
      });
    },
    [dialog, generateErrorByKey, t],
  );
  const context = useMemo(() => ({ open }), [open]);
  return (
    <div>
      <ErrorDialogContext.Provider value={context}>{children}</ErrorDialogContext.Provider>
    </div>
  );
};

const useErrorDialog = () => {
  return useContext(ErrorDialogContext);
};

export { ErrorDialogProvider, useErrorDialog };
