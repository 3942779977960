import { isValid, parseISO } from 'date-fns';
import { getSessionStorageObject } from 'src/helpers';
import { LocalStorageKeys } from 'src/tokens';
import { DateToString } from 'src/types/utils/dateToString';
import { InvoiceContextState } from '../../invoiceState.types';

const parseDateString = (dateString: string | null | undefined): Date | null => {
  if (dateString && dateString.length) {
    try {
      const parsedDate = parseISO(dateString);
      if (isValid(parsedDate)) {
        return parsedDate;
      }
    } catch (err) {
      console.warn(err);
    }
  }
  return null;
};

const getPersistedInvoiceState = (): InvoiceContextState => {
  const restoredState: DateToString<InvoiceContextState> | null = getSessionStorageObject(
    LocalStorageKeys.salesInvoiceOfferState,
  );

  if (!restoredState) {
    return {};
  }

  const contextState: InvoiceContextState = {
    administration: restoredState?.administration,
    debtor: restoredState?.debtor,
    baseOffer: restoredState?.baseOffer,
    administrationList: restoredState?.administrationList,
    bankAccounts: restoredState?.bankAccounts,
    fundrApiState: {},
    invoiceFinanceState: restoredState?.invoiceFinanceState,
  };

  if (restoredState.selectedInvoice) {
    contextState.selectedInvoice = {
      ...restoredState.selectedInvoice,
      loanEndDate: parseDateString(restoredState.selectedInvoice.loanEndDate),
      invoiceIssueDate:
        parseDateString(restoredState.selectedInvoice.invoiceIssueDate) ?? new Date(),
      dueDate: parseDateString(restoredState.selectedInvoice.dueDate) ?? new Date(),
    };
  }

  return contextState;
};

export { getPersistedInvoiceState };
