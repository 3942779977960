import ais from './ais.json';
import common from './common.json';
import invoiceFinancing from './invoiceFinancing.json';
import bookkeepingSystem from './bookkeepingSystem.json';
import hix from './hix.json';
import minox from './minox.json';
import signup from './signup.json';
import deviceBinding from './deviceBinding.json';
import orchestrator from './orchestrator.json';
import accountingHub from './accountingHub.json';
import errorTitles from './errorTitles.json';
import genericErrors from './genericErrors.json';
import cdd from './cdd.json';

export default {
  ais,
  common,
  invoiceFinancing,
  bookkeepingSystem,
  hix,
  minox,
  signup,
  deviceBinding,
  orchestrator,
  accountingHub,
  errorTitles,
  genericErrors,
  cdd,
};
