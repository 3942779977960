import React, { lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import useInvoiceStore from 'src/hooks/useInvoiceStore';
import Menu from 'src/molecules/menu/Menu';
import { PageHeader, PageLayout } from 'src/atoms/layout/menu-page';
import Paths from 'src/tokens/Paths';
import {
  useGetInvoiceFinanceStateLazyQuery,
  usePutInvoiceFinanceState,
} from 'src/types/bizcuitApi';
import ErrorState from './error/Error.types';

const ChooseSalesInvoice = lazy(() => import('./choose-sales-invoice/ChooseSalesInvoice'));
const ContactRequestSuccess = lazy(() => import('./contact-request-success/ContactRequestSuccess'));
const ContactRequest = lazy(() => import('./contact-request/ContactRequest'));
const Error = lazy(() => import('./error/Error'));
const Introduction = lazy(() => import('./intro/Introduction'));
const EnterKvk = lazy(() => import('./requester/EnterKvk'));
const Success = lazy(() => import('./success/Success'));
const InvoiceFinancePage = lazy(() => import('./widget/InvoiceFinancePage'));
const PrivateRoutesGuard = lazy(
  () => import('src/organisms/private-routes-guard/PrivateRoutesGuard'),
);
const EmbeddedUserAccessGuard = lazy(
  () => import('src/organisms/embedded-user-access-guard/EmbeddedUserAccessGuard'),
);

const errorPath = `${Paths.raboInvoiceFinancing.root}${Paths.raboInvoiceFinancing.segments.error}`;
const RaboInvoiceFinancingRouter: React.FC = () => {
  const { segments } = Paths.raboInvoiceFinancing;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('invoiceFinancing');
  const {
    state: { invoiceFinanceState },
    actions: { setInvoiceFinanceState },
  } = useInvoiceStore();

  const [putInvoiceFinanceState] = usePutInvoiceFinanceState();

  const [getInvoiceFinanceState] = useGetInvoiceFinanceStateLazyQuery({
    onCompleted: (data) => {
      data?.getInvoiceFinanceState && setInvoiceFinanceState(data.getInvoiceFinanceState);
    },
    onError: (error) => {
      if (error?.message == '404 - Not found.') {
        putInvoiceFinanceState();
      } else {
        navigate(errorPath, {
          state: {
            errorMessage: t('error_page.standard_error', {
              errorMessage: error.message,
            }),
            buttonText: t('error_page.button_text'),
            redirectPath: location.pathname,
          } as ErrorState,
        });
      }
    },
  });

  useEffect(() => {
    getInvoiceFinanceState();
  }, [getInvoiceFinanceState]);

  return (
    <PageLayout>
      <PageHeader title={t('app_header')} />
      <Menu />
      <Routes>
        <Route element={<PrivateRoutesGuard />}>
          <Route element={<EmbeddedUserAccessGuard />}>
            <Route path={segments.chooseSalesInvoice} element={<ChooseSalesInvoice />} />
            <Route path={segments.enterAdministrationKvK} element={<EnterKvk />} />
            <Route path={segments.completeRequest} element={<InvoiceFinancePage />} />
            <Route
              path={`${segments.contactRequest.root}${segments.contactRequest.segments.success}`}
              element={<ContactRequestSuccess />}
            />
            <Route path={segments.contactRequest.root} element={<ContactRequest />} />
            <Route path={segments.error} element={<Error />} />
            <Route path={segments.success} element={<Success />} />
            <Route
              index
              element={
                invoiceFinanceState?.completedAmount != 0 ? (
                  <ChooseSalesInvoice />
                ) : (
                  <Introduction />
                )
              }
            />
          </Route>
        </Route>
      </Routes>
    </PageLayout>
  );
};

export default RaboInvoiceFinancingRouter;
