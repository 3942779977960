import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import { SetIbanResponse, SetIbanResult } from './types';

export const enterIban = async (
  billingIban: string,
  billingAccepted: boolean,
): Promise<SetIbanResult> => {
  try {
    const {
      data: { success },
    } = await restApi.post<SetIbanResponse>(`/api/latest/user/set_iban`, {
      billing_iban: billingIban,
      billing_accepted: billingAccepted,
    });

    return { success };
  } catch (error: unknown) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'enterIban',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
