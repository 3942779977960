import { Outlet, useNavigate } from 'react-router-dom';
import { useUserStore } from 'src/hooks';

const PublicRoutesGuard = () => {
  const navigate = useNavigate();

  const {
    state: { user },
  } = useUserStore();

  if (user.active) {
    navigate('/app/home');
  }
  return <Outlet />;
};

export default PublicRoutesGuard;
