import Fingerprint2 from '@fingerprintjs/fingerprintjs';
import { Component } from 'fingerprintjs2';
import stringify from 'json-stable-stringify';
import { FingerprintConfig } from 'src/tokens';

const getDeviceIdentifier = async (): Promise<Nullable<string>> => {
  const components: Component[] = await Fingerprint2.getPromise(FingerprintConfig);

  const filteredComponents = components.filter((c) =>
    [
      'deviceMemory',
      'hardwareConcurrency',
      'platform',
      'cpuClass',
      'plugins',
      'webgl',
      'webglVendorAndRenderer',
      'fonts',
      'audio',
    ].includes(c.key),
  );

  return stringify(filteredComponents);
};

export default getDeviceIdentifier;
