import { createElement } from 'react';
import IsCheckedIcon from 'src/organisms/is-checked-icon/IsCheckedIcon';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { PermissionConsent } from 'src/interfaces/Permissions';
import { GeneralScopeCombinations as GSC } from 'src/interfaces/Scopes';
import showScopeCheck from './showScopeCheck';
import { PartnerScopes } from 'src/types/bizcuitApi';

type PermissionData = {
  [key in
    | `${PartnerScopes.Openid}`
    | `${PartnerScopes.Profile}`
    | `${PartnerScopes.Email}`
    | `${GSC.OpenidEmailAndProfile}`]: PermissionConsent;
};

const permissionData: PermissionData = {
  [PartnerScopes.Openid]: {
    scope: PartnerScopes.Openid,
    title: 'knowWhoYouAreOnBizcuit',
    icon: createElement(BizcuitIcon, { icon: 'user', className: 'w-6 h-6', isChecked: true }),
  },
  [PartnerScopes.Email]: {
    scope: PartnerScopes.Email,
    title: 'viewYourEmail',
    icon: createElement(IsCheckedIcon, {
      icon: createElement(BizcuitIcon, { icon: 'email', className: 'w-6 h-6' }),
      isChecked: true,
    }),
  },
  [PartnerScopes.Profile]: {
    scope: PartnerScopes.Profile,
    title: 'viewYourNameAndProfile',
    icon: createElement(IsCheckedIcon, {
      icon: createElement(BizcuitIcon, { icon: 'profile', className: 'w-6 h-6' }),
      isChecked: true,
    }),
  },
  [GSC.OpenidEmailAndProfile]: {
    scope: [PartnerScopes.Email, PartnerScopes.Profile].join('-'),
    title: 'viewYourNameProfileAndEmail',
    icon: createElement(IsCheckedIcon, {
      icon: createElement(BizcuitIcon, { icon: 'profile', className: 'w-6 h-6' }),
      isChecked: true,
    }),
  },
};

const getBasicConsentCopies = (
  scopes: PartnerScopes[] = [],
  trustedScopes: PartnerScopes[] = [],
): PermissionConsent[] => {
  const showScope = showScopeCheck(scopes, trustedScopes);

  if (
    showScope(PartnerScopes.Openid) &&
    showScope(PartnerScopes.Profile) &&
    showScope(PartnerScopes.Email)
  ) {
    return [permissionData[GSC.OpenidEmailAndProfile]] as PermissionConsent[];
  }

  const permissions: PermissionConsent[] = [];

  if (showScope(PartnerScopes.Openid)) permissions.push(permissionData[PartnerScopes.Openid]);
  if (showScope(PartnerScopes.Email)) permissions.push(permissionData[PartnerScopes.Email]);
  if (showScope(PartnerScopes.Profile)) permissions.push(permissionData[PartnerScopes.Profile]);

  return permissions;
};

export default getBasicConsentCopies;
