import React, { useState } from 'react';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import PrimaryHeader from 'src/atoms/text/primary-header/PrimaryHeader';
import { useTranslation } from 'react-i18next';
import KvkSearch from 'src/organisms/kvk-search/KvKSearch';
import KvkResultContainer from 'src/molecules/layout/kvk-result-container/KvkResultContainer';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { TextColor } from 'src/atoms/text/text/Text.types';
import { CompanyItem } from 'src/types/bizcuitApi';
import { useNavigate } from 'react-router';
import Paths from 'src/tokens/Paths';

const ProvideKvk = () => {
  const { t } = useTranslation(['cdd', 'common']);
  const [kvkResult, setKvkResult] = useState<CompanyItem>();
  const [companyAmount, setCompanyAmount] = useState<number>(0);
  const navigate = useNavigate();

  //TODO replace with real data
  const mockCompany = 'Vereniging het Zonnetje';

  const handleConfirm = () => {
    //TODO replace with logic
    console.log(kvkResult);
  };

  const onClearButtonClick = () => {
    setKvkResult(undefined);
  };

  const multipleCompaniesFound = companyAmount > 1;
  const descriptionTranslation = multipleCompaniesFound
    ? 'cdd:provideKvk.descriptionMultipleFound'
    : 'cdd:provideKvk.description';
  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex pb-6 items-center w-full flex-wrap flex-col">
          <SecondaryButton
            underline
            onClick={() =>
              navigate(
                `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.provideManually}`,
              )
            }
          >
            {t('cdd:provideKvk.secondaryButton')}
          </SecondaryButton>
          <PrimaryButton className="md:w-80" onClick={handleConfirm}>
            {t('common:button.confirm')}
          </PrimaryButton>
        </footer>
      }
    >
      <>
        <PrimaryHeader className="text-warm-grey-800 dark:text-white">
          {t('cdd:provideKvk.primaryHeader')}
        </PrimaryHeader>
        <div className="flex flex-col gap-6">
          <p className="text-medium-loose">{t(descriptionTranslation)}</p>
          <p className="text-medium font-weight-500 text-grey-900 dark:text-white">{mockCompany}</p>
          {!kvkResult ? (
            <KvkSearch
              onSelected={(value) => setKvkResult(value)}
              companyCount={(amount) => setCompanyAmount(amount)}
              initialSearch={mockCompany}
              showCreateAccount={false}
            />
          ) : (
            <KvkResultContainer
              subText={kvkResult.street}
              companyName={kvkResult.tradeName}
              cocNumber={kvkResult.kvkNumber}
              suffix={{
                element: (
                  <BizcuitIcon
                    icon="x-circle"
                    primaryColor={TextColor.bizRed500}
                    className="w-6 h-6"
                  />
                ),
                onClick: onClearButtonClick,
              }}
              shadowClass="shadow-sm"
            />
          )}
        </div>
      </>
    </FlowLayout>
  );
};

export default ProvideKvk;
