import * as Sentry from '@sentry/react';
import {
  ErrorBoundary,
  replayIntegration,
  captureConsoleIntegration,
  browserTracingIntegration,
} from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import './i18next';
import './index.css';
import reportWebVitals from './reportWebVitals';

const isLocal = process.env.NODE_ENV === 'development';

if (!isLocal) {
  Sentry.init({
    dsn: 'https://6c5a32e6da1c4ff1a7c5ccbb0fd063a5@o475155.ingest.sentry.io/4504282985660416',
    integrations: [
      captureConsoleIntegration({ levels: ['error'] }),
      browserTracingIntegration(),
      replayIntegration({ blockAllMedia: false }),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_VERSION,
    environment: isLocal ? 'local' : process.env.REACT_APP_BUILD_STAGE,
  });
}
const container = document.getElementById('root');

if (!container) throw Error('Root element not found!');

const root = createRoot(container);

root.render(
  <React.Suspense fallback="loading">
    <React.StrictMode>
      {isLocal ? (
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      ) : (
        <App />
      )}
    </React.StrictMode>
  </React.Suspense>,
),
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
