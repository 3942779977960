import { AxiosError } from 'axios';
import { BillingAddress } from 'src/interfaces/User';
import { CaptureErrorType } from '../../types/utils/captureError';
import { captureError } from '../../utils';
import restApi from '../restApi';
import { SetBillingAddressDataResult } from './types';

export const setBillingAddress = async (
  billingAddressData: BillingAddress,
): Promise<SetBillingAddressDataResult> => {
  try {
    const { country, street, city, tradeName, houseNumber, postalCode } = billingAddressData;
    const { data } = await restApi.post<BillingAddress>(`/api/latest/metering/billing_address`, {
      country,
      street,
      city,
      name: tradeName,
      house_number: houseNumber,
      postal_code: postalCode,
    });

    return { data: data, success: true };
  } catch (error: unknown) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'setBillingAddress',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
