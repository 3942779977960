import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import {
  SendUserExistEmailRequest,
  SendUserExistEmailResponse,
  SendUserExistEmailResult,
} from './types';

export const sendUserExistEmail = async (
  payload: SendUserExistEmailRequest,
): Promise<SendUserExistEmailResult> => {
  try {
    const { data } = await restApi.post<SendUserExistEmailResponse>(
      `/api/latest/user/send_user_exists_email`,
      payload,
    );

    return data;
  } catch (error) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'sendUserExistEmail',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
