import { atom } from 'jotai';
import { invoiceStateAtom } from '../invoice-state-atoms';
import { SalesInvoiceOffer } from 'src/interfaces/Invoice';

const setSelectedInvoiceAtom = atom(
  null,
  (_get, set, selectedInvoice: Nullable<SalesInvoiceOffer>) => {
    set(invoiceStateAtom, { ..._get(invoiceStateAtom), selectedInvoice });
  },
);

export { setSelectedInvoiceAtom };
