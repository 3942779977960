import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KvkResultContainer from 'src/molecules/layout/kvk-result-container/KvkResultContainer';
import TextInput from 'src/atoms/inputs/text-input/TextInput';
import IconButton from 'src/atoms/buttons/icon-button/IconButton';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { TextColor } from 'src/atoms/text/text/Text.types';
import { useSearchCompanies } from 'src/types/bizcuitApi';
import KvKSearchProps from './KvKSearch.types';
import { PageTestIdKeys } from 'src/tokens';
import { useIsDarkMode } from 'src/hooks';

const KvkSearch: FC<KvKSearchProps> = ({
  onSelected,
  companyCount,
  initialSearch,
  showCreateAccount = true,
}: KvKSearchProps) => {
  const { t } = useTranslation('invoiceFinancing');
  const [search, setSearch] = useState(initialSearch ?? '');
  const MIN_SEARCH_LENGTH = 3;
  const isDarkMode = useIsDarkMode();
  const isKvkNumber = (value: string): boolean => {
    // KvK states that valid numbers are always 8 digits
    // https://www.kvk.nl/english/registration/kvk-number-all-you-need-to-know/
    return /\d{8}/.test(value);
  };
  const { data, loading } = useSearchCompanies({
    skip: search.length < MIN_SEARCH_LENGTH,
    variables: {
      search: isKvkNumber(search) ? null : search,
      kvkNumber: isKvkNumber(search) ? search : null,
    },
  });

  const items = data?.searchCompanies?.items;
  const showCreateBox = !loading && showCreateAccount && search.length >= MIN_SEARCH_LENGTH;

  if (companyCount) companyCount(items?.length ?? 0);

  const onInputChange = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const onInputClear = useCallback(() => {
    setSearch('');
  }, []);

  const textInputSuffix = {
    element: loading ? (
      <BizcuitIcon
        icon="spinner"
        data-testid="spinner-icon"
        fill={isDarkMode ? '#00934c' : undefined}
      />
    ) : search.length > 0 ? (
      <IconButton
        testId={PageTestIdKeys.enterCompany.searchClear}
        data-testid="btn-clear"
        onClick={onInputClear}
      >
        <BizcuitIcon icon="x-circle" className="w-6 h-6" primaryColor={TextColor.bizGray500} />
      </IconButton>
    ) : null,
  };

  const searchResultSuffix = {
    element: (
      <BizcuitIcon icon="arrow-right" className="w-6 h-6" primaryColor={TextColor.bizGreen500} />
    ),
  };

  return (
    <div className="flex flex-col">
      <TextInput
        data-testid="search-input"
        testId={PageTestIdKeys.enterCompany.searchInput}
        label={t('kvk.label')}
        name="Bedrijf"
        placeHolder={t('kvk.placeholder')}
        subText={t('kvk.sub_text')}
        prefixImgSrc="search.svg"
        suffix={textInputSuffix}
        onChange={onInputChange}
        value={search}
        focusOnRender
      />
      {search.length > 0 && (
        <div
          className={`max-h-80 overflow-y-scroll overflow-x-auto rounded-lg-plus shadow-md bg-white dark:bg-biz-gray-800 ${
            showCreateBox ? 'scrollbar-hide' : ''
          }`}
        >
          {items &&
            items.map(
              (item, index) =>
                item && (
                  <KvkResultContainer
                    key={`${item.kvkNumber}-${index}`}
                    onClick={() => onSelected(item)}
                    subText={item.street && item.city ? `${item.street}, ${item.city}` : ``}
                    companyName={item.tradeName}
                    cocNumber={item.kvkNumber}
                    suffix={searchResultSuffix}
                  />
                ),
            )}
          {showCreateBox && (
            <KvkResultContainer
              onClick={() =>
                onSelected({
                  kvkNumber: '',
                  branchNumber: '',
                  type: '',
                  tradeName: search,
                  links: [],
                })
              }
              subText={t('kvk.createAccountUnderThisName')}
              companyName={search}
              suffix={searchResultSuffix}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default KvkSearch;
