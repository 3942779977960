import * as ErrorTitleKeys from 'src/translations/nl/errorTitles.json';
import * as GenericErrorKeys from 'src/translations/nl/genericErrors.json';

import { PartnerScopes } from 'src/types/bizcuitApi';

export type BizcuitErrorTitleKeys = keyof typeof ErrorTitleKeys;
export type BizcuitGenericErrorKeys = keyof typeof GenericErrorKeys;

export type BizcuitError = {
  title?: BizcuitErrorTitleKeys | string;
  message: string;
  wrapWithGenericTemplate?: boolean;
  values?: Record<string, string>;
  errorSupport?: {
    title?: string;
    description?: string;
    provideToSupport?: string;
  };
  isErrorMessageWithBlankTitle?: boolean;
  redirectUrl?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export type ErrorTranslationParams = {
  title?: BizcuitErrorTitleKeys | string;
  errorTitle?: BizcuitGenericErrorKeys | string;
  techDescription?: string;
};

export type GenericBizcuitError = {
  [key in BizcuitGenericErrorKeys]: BizcuitError;
};

export const BizcuitErrors: Record<string, BizcuitError> = {
  generic: {
    title: 'somethingWentWrong',
    message: '',
  },
  clientIdIsMissing: {
    title: 'somethingWentWrong',
    message: 'paramXIsMissing',
    wrapWithGenericTemplate: true,
    values: {
      paramName: 'client_id',
    },
  },
  accountNotActive: {
    title: 'ais',
    message: 'accountNotActiveMessage',
    errorSupport: {
      title: 'accountNotActiveErrorSupportTitle',
      description: 'accountNotActiveErrorSupportDescription',
    },
  },
  notAuthorised: {
    title: 'hix',
    message: 'accountNotActiveMessage',
    errorSupport: {
      title: 'notAuthorisedErrorSupportTitle',
      description: 'notAuthorisedErrorSupportDescription',
    },
  },
  sessionHasExpired: {
    title: 'raboInvoiceFinancing',
    message: 'sessionHasExpiredMessage',
    errorSupport: {
      title: 'sessionHasExpiredErrorSupportTitle',
      description: 'sessionHasExpiredErrorSupportDescription',
    },
  },
  responseTypeIsMissing: {
    title: 'somethingWentWrong',
    message: 'paramXIsMissing',
    wrapWithGenericTemplate: true,
    values: {
      paramName: 'response_type',
    },
  },
  redirectUriIsMissing: {
    title: 'somethingWentWrong',
    message: 'paramXIsMissing',
    wrapWithGenericTemplate: true,
    values: {
      paramName: 'redirect_uri',
    },
  },
  scopeIsMissing: {
    title: 'somethingWentWrong',
    message: 'paramXIsMissing',
    wrapWithGenericTemplate: true,
    values: {
      paramName: 'scope',
    },
  },
  codeChallengeMethodIsRequired: {
    title: 'somethingWentWrong',
    message: 'paramXIsMissing',
    wrapWithGenericTemplate: true,
    values: {
      paramName: 'code_challenge_method',
    },
  },
  onlyResponseTypeCode: {
    title: 'somethingWentWrong',
    message: 'onlyResponseTypeCodeIsSupported',
    wrapWithGenericTemplate: true,
  },
  invalidScope: {
    title: 'somethingWentWrong',
    message: 'invalidScopeProvided',
    wrapWithGenericTemplate: true,
    values: {
      scopeList: `${Object.values(PartnerScopes).join(', ')}`,
    },
  },
  openidIsRequired: {
    title: 'somethingWentWrong',
    message: 'scopeOpenidIsRequired',
    wrapWithGenericTemplate: true,
  },
  supportedCodeChallengeMethod: {
    title: 'somethingWentWrong',
    message: 'supportedCodeChallengeMethod',
    wrapWithGenericTemplate: true,
  },
  invalidEmail: {
    title: 'somethingWentWrong',
    message: 'invalidEmail',
  },
  emailAlreadyExists: {
    title: 'somethingWentWrong',
    message: 'emailAlreadyExists',
  },
  partnerDoesNotExist: {
    title: 'somethingWentWrong',
    message: 'partnerDoesNotExist',
  },
  partnerHasNoPermission: {
    title: 'somethingWentWrong',
    message: 'partnerHasNoPermission',
  },
  failedCreatingPrivateAdministration: {
    title: 'somethingWentWrong',
    message: 'failedCreatingPrivateAdministration',
  },
  emptyNameFields: {
    title: 'somethingWentWrong',
    message: 'missingNames',
  },
  noUserIdSupplied: {
    title: 'somethingWentWrong',
    message: 'missingUserId',
  },
  noUserFound: {
    title: 'somethingWentWrong',
    message: 'noUserFound',
  },
  invalidIban: {
    title: 'somethingWentWrong',
    message: 'invalidIban',
  },
  noIdOrFullNameProvided: {
    title: 'somethingWentWrong',
    message: 'noIdOrFullNameProvided',
  },
  noPartnerFound: {
    title: 'somethingWentWrong',
    message: 'noPartnerFound',
  },
  ibanMissing: {
    title: 'somethingWentWrong',
    message: 'ibanMissing',
  },
  billingNotAccepted: {
    title: 'somethingWentWrong',
    message: 'billingNotAccepted',
  },
  failedCreateInitBankAccount: {
    title: 'somethingWentWrong',
    message: 'failedCreateInitBankAccount',
  },
  failedSendingAgreement: {
    title: 'somethingWentWrong',
    message: 'failedSendingBizcuitAgreement',
  },
  failedCreateMinoxCustomer: {
    title: 'somethingWentWrong',
    message: 'failedCreateMinoxCustomer',
  },
  provideSessionId: {
    title: 'somethingWentWrong',
    message: 'provideSessionId',
  },
  provideDeviceNameAndPublicKey: {
    title: 'somethingWentWrong',
    message: 'provideDeviceNameAndPublicKey',
  },
  sessionNotFound: {
    title: 'somethingWentWrong',
    message: 'sessionNotFound',
  },
  sessionExpired: {
    title: 'somethingWentWrong',
    message: 'sessionExpired',
  },
  noUserIdOrPartnerIdProvided: {
    title: 'somethingWentWrong',
    message: 'noUserIdOrPartnerIdProvided',
  },
  dontSupplyBothPartnerAndUser: {
    title: 'somethingWentWrong',
    message: 'dontSupplyBothPartnerAndUser',
  },
  missingCompanyRequirements: {
    title: 'somethingWentWrong',
    message: 'missingCompanyRequirements',
  },
  billingAddressNotExisting: {
    title: 'somethingWentWrong',
    message: 'billingAddressNotExisting',
  },
};
