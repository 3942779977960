import { removeCookie, setCookie } from 'src/utils';
import CookieKeys from 'src/tokens/CookieKeys';

const setAccessToken = (accessToken: string, path?: string) => {
  if (!accessToken) {
    removeCookie(CookieKeys.accessToken);
  }
  setCookie(CookieKeys.accessToken, accessToken, 12, path);
};

export default setAccessToken;
