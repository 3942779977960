import { SVGProps } from 'react';

const SvgHistory = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.58008 11.6899L4.40851 14.5184L5.82272 13.1042L7.23693 11.6899"
      stroke="#141210"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.91016 19.796C10.2339 20.5617 11.7709 21 13.4102 21C18.3807 21 22.4102 16.9706 22.4102 12C22.4102 7.02944 18.3807 3 13.4102 3C8.43959 3 4.41016 7.02944 4.41016 12V14.25"
      stroke="#141210"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.5 7V12" stroke="#141210" strokeWidth={2} strokeLinecap="round" />
    <path d="M13.5 12L16.9641 14" stroke="#141210" strokeWidth={2} strokeLinecap="round" />
  </svg>
);

export default SvgHistory;
