import { atom } from 'jotai';
import { getUserSession } from 'src/services/user';
import { userSessionAtom } from '../user-state-atoms';

const updateSessionAtom = atom(null, async (get, set) => {
  const result = await getUserSession();
  const { data: userSession, error } = result;

  if (error || !userSession) {
    return error;
  }

  set(userSessionAtom, userSession);
});

export { updateSessionAtom };
