const SvgHourglass = ({ ...rest }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15.6927 4.0356C16.115 3.36981 15.6366 2.5 14.8482 2.5H5.15174C4.36332 2.5 3.88499 3.36981 4.30727 4.0356L7.9756 9.81933C8.19215 10.1608 8.1818 10.599 7.94937 10.9298L4.43978 15.9251C3.97424 16.5877 4.44821 17.5 5.25802 17.5H14.7419C15.5517 17.5 16.0257 16.5877 15.5602 15.9251L12.0506 10.9298C11.8182 10.599 11.8078 10.1608 12.0244 9.81933L15.6927 4.0356Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line x1="6.66669" y1="7.3335" x2="13.3334" y2="7.3335" stroke="currentColor" strokeWidth="2" />
    <rect
      x="9.16669"
      y="11.6665"
      width="1.66667"
      height="1.66667"
      rx="0.833333"
      fill="currentColor"
    />
    <rect
      x="9.16669"
      y="14.1665"
      width="1.66667"
      height="1.66667"
      rx="0.833333"
      fill="currentColor"
    />
    <rect
      x="9.16669"
      y="9.1665"
      width="1.66667"
      height="1.66667"
      rx="0.833333"
      fill="currentColor"
    />
  </svg>
);

export default SvgHourglass;
