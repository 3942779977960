import { OpenApiPaths } from '../MockData';
import { OrchestratorFlowDemoState } from '../useOrchestratorFlowDemo';
import { getPartnerSecret } from '../utils';
import createUserData from './createUserData';

type CreateUserParams = Pick<
  OrchestratorFlowDemoState,
  'partnerId' | 'demoPartner' | 'flowId' | 'termsAccepted' | 'email' | 'externalId'
>;

const createUser = async ({
  partnerId,
  demoPartner,
  flowId,
  termsAccepted,
  email,
  externalId,
}: CreateUserParams) => {
  const response = await fetch(OpenApiPaths.users, {
    method: 'POST',
    headers: {
      'x-bizcuit-client-id': partnerId,
      'x-bizcuit-client-secret': getPartnerSecret(partnerId),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      createUserData({
        demoPartner,
        flowId,
        termsAccepted,
        email,
        externalId,
      }),
    ),
  });

  return response;
};

export default createUser;
