import { OpenApiPaths, mockAdministrations } from '../MockData';
import { CreateAdministrationResponse } from '../OrchestratorFlowDemo.types';

const createAdministration = async (
  accessToken: string,
  administration: (typeof mockAdministrations)[0],
): Promise<string> => {
  const response = await fetch(OpenApiPaths.createAdministration, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(administration),
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }
  const result = (await response.json()) as CreateAdministrationResponse;
  return result.id;
};

export default createAdministration;
