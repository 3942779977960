import { atom } from 'jotai';
import { globalStore } from 'src/contexts/globalStore';
import { getPersistedAppsState, setPersistedAppsState } from '../apps-actions-atoms';
import { AppInfo } from 'src/interfaces/Apps';

const appsAtom = atom<AppInfo[]>(getPersistedAppsState()?.apps ?? []);

globalStore.sub(appsAtom, () => {
  setPersistedAppsState({
    ...getPersistedAppsState(),
    apps: globalStore.get(appsAtom),
  });
});

if (process.env.NODE_ENV !== 'production') {
  appsAtom.debugLabel = 'Apps';
}

export { appsAtom };
