import { atom } from 'jotai';
import { partnerAuthorisationAtom } from '../partner-state-atoms';
import { initializePartnerState } from '../partner-actions-atoms';
import { AuthorisationInitializationProps } from 'src/contexts/partner-global-state/types';
import { validatePartnerAuthQueryParams } from './validatePartnerAuthQueryParams';

const initializePartnerAuthorisation = atom(
  null,
  async (_get, set, authParams: AuthorisationInitializationProps) => {
    const { error, partnerAuthorisation } = validatePartnerAuthQueryParams(authParams);

    if (!partnerAuthorisation || error) return { error };
    const { clientId, clientReference, brandingClientId } = partnerAuthorisation;
    await set(initializePartnerState, {
      clientId: clientId,
      clientReference: clientReference,
      brandingClientId,
      partnerAuthorisation,
    });

    set(partnerAuthorisationAtom, partnerAuthorisation);
    return { partnerAuthorisation, error };
  },
);

export { initializePartnerAuthorisation };
