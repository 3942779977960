export enum DemoNames {
  Visa = 'Visa Spend Clarity for Business',
  Volksbank = 'Volksbank Business Banking',
  TestPartner = 'Test Partner',
  AsnBoekhoudkoppelingAcc = 'ASN boekhoudkoppeling - Demo',
  SnsBoekhoudkoppelingAcc = 'SNS boekhoudkoppeling - Demo',
  RegioBankBoekhoudkoppelingAcc = 'RegioBank boekhoudkoppeling - Demo',
  AsnBoekhoudkoppelingProd = 'ASN boekhoudkoppeling',
  SnsBoekhoudkoppelingProd = 'SNS boekhoudkoppeling',
  RegioBankBoekhoudkoppelingProd = 'RegioBank boekhoudkoppeling',
  ConnectAccountingSystem = 'Connect Accounting System for AP/AR',
  ConnectAccountingSystemMollieApAr = 'Mollie boekhoudkoppeling - Demo',
  ConnectBankAccountsFlow = 'Connect Bank Accounts',
}

export interface UserPayloadData {
  partner_reference: string;
  initials: string;
  first_name: string;
  last_name: string;
  terms_accepted: boolean;
  billing_accepted: boolean;
  verified_user: boolean;
  date_of_birth: string;
  company_name: string;
  phone_number: string;
  embedded_user: boolean;
  external_id?: string;
  email: Nullable<string>;
}

export interface VerifiedUserPayloadData {
  user: UserPayloadData;
  bizcuit_customer?: {
    business_account: boolean;
    billing_address: {
      name: string;
      street: string;
      street2: string;
      postal_code: string;
      city: string;
      province: string;
      country: string;
    };
    iban: string;
  };
  bank_account?: {
    api_account?: boolean;
    iban?: string;
    display_name: string;
    currency: string;
  };
  administration?: {
    name: string;
    send_ubl: boolean;
    kvk_number: string;
  };
}

export interface InitiateFlowResponse {
  id: string;
  redirect: string;
}

export interface PocSetup {
  userId: string;
  partnerId: string;
  partnerSecret: string;
}

export interface JwtPayload {
  exp: number;
  iat: number;
  userId: string;
  partnerId: string;
  flowId: string;
}

export type CreateAdministrationResponse = {
  id: string;
};

export type CreateBankAccountResponse = {
  id: string;
};
