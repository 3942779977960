import { captureException } from '@sentry/react';
import { add as addDate, isValid as isValidDate } from 'date-fns';
import { BizcuitError, BizcuitErrors } from 'src/tokens/BizcuitErrors';
import { GeneralObject } from 'src/types/generic';
import { CaptureError, CaptureErrorType } from './types/utils/captureError';
import { PartnerScopes } from './types/bizcuitApi';
import { Environment } from './types/environment';
import type { JwtPayload } from './pages/orchestratorFlowDemo/OrchestratorFlowDemo.types';

export const isNotNull = <T>(x: T): x is NonNullable<T> => !!x;

export const base64decode = (str: string) => {
  try {
    return window ? window.atob(str) : Buffer.from(str, 'base64').toString();
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('base64decode', e);
    }
  }
  return '';
};

export const base64encode = (str: string) => {
  try {
    return window ? window.btoa(str) : Buffer.from(str).toString('base64');
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('base64encode', e);
    }
  }
  return '';
};
/**
 * isUndefined from `util` is deprecated
 *
 * @format
 * @param {*} object
 * @returns {object is undefined}
 */
export function isUndefined(object: unknown): object is undefined {
  return object === undefined;
}

/**
 * isNull from `util` is deprecated
 *
 * @param {*} object
 * @returns {object is null}
 */
export function isNull(object: unknown): object is null {
  return object === null;
}

/**
 * isNullOrUndefined from `util` is deprecated
 *
 * @param {*} object
 * @returns {object is undefined}
 */
export function isNullOrUndefined(object: unknown): object is null | undefined {
  return object === null || object === undefined;
}

export function isPlainObject(object: unknown) {
  if (typeof object !== 'object' || object === null || object instanceof Date) {
    return false;
  }

  return Object.getPrototypeOf(object) === Object.prototype;
}

/**
 * isString from `util` is deprecated
 *
 * @param {*} object
 * @returns {object is string}
 */
export function isString(object: unknown): object is string {
  return typeof object === 'string';
}

export function isEmail(object: unknown): boolean {
  // eslint-disable-next-line no-control-regex
  let isValid = false;
  const regex = new RegExp(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    'g',
  );

  if (typeof object === 'string') {
    const transformedEmail = object.toLowerCase();
    const regResult = transformedEmail.match(regex);
    isValid = Array.isArray(regResult) && !!regResult.length && regResult[0] === transformedEmail;
  }

  return isValid;
}

export function isPhoneNumber(object: unknown): boolean {
  // Regex may require some tweaking for international numbers;
  const regex = new RegExp(/^(\+|00)?(\d{1,3}[\s\-]?)?[\d\s\-]+$/);
  return (
    typeof object === 'string' && regex.test(object) && object.replace(/[^\d]/g, '').length >= 10
  );
}

export function isDate(object: unknown): object is string {
  return typeof object === 'string' && isValidDate(object as string);
}

export function isIban(object: unknown): boolean {
  const regex = new RegExp(
    /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/,
  );

  if (typeof object !== 'string') {
    return false;
  }

  const iban = object.replace(/\s/g, '').toUpperCase();

  return regex.test(iban); //use regex to validate last part
}

export const formatIBAN = (iban: string): string => {
  return iban.replace(/(.{4})/g, '$1 ').trim();
};

export function isMoney(object: unknown): boolean {
  return typeof object === 'number' && (object as number) >= 0;
}

export function delay(milliSeconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliSeconds));
}

export function capitalize(value: string): string {
  return value.length > 0 ? `${value[0].toUpperCase()}${value.slice(1).toLowerCase()}` : '';
}

/**
 * isObject from `util` is deprecated
 *
 * @param {*} object
 * @returns {boolean}
 */
export function isObject(object: unknown): boolean {
  return object !== null && typeof object === 'object';
}

/**
 * @description `Record<string, unknown>` is the recommended way to specify the type `object`
 *  because it allows better access to the object keys
 * @see https://github.com/microsoft/TypeScript/issues/21732
 */
export type jsObject = Record<string, unknown>;

export const TruthyFilter = <T>(x: T | false | undefined | null | '' | 0): x is T => !!x;

export function isJwtTokenExpired(token: string, secondsRemaining?: number): boolean {
  try {
    const metastr = token.split('.')[1];
    const parsedStr = base64decode(metastr);
    const metadata = JSON.parse(parsedStr);
    const expires = metadata.exp - (secondsRemaining ?? 0);
    return expires <= 0 || expires < new Date().getTime() / 1000;
  } catch (err) {
    console.warn(err);
    return true;
  }
}

export function filterJwtTokenMetadata(tokenMetadata: JwtPayload | null, filters: string[]) {
  if (!tokenMetadata) {
    return {};
  }

  const filteredMetadata = Object.entries(tokenMetadata).reduce(
    (acc: Record<string, string | number>, [key, val]) => {
      if (!filters.includes(key)) {
        acc[key] = val;
      }
      return acc;
    },
    {},
  );

  return filteredMetadata;
}

export function getJwtPayload<T>(token: string): T | null {
  try {
    const metaStr = token.split('.')[1];
    const parsedStr = base64decode(metaStr);
    const metadata = JSON.parse(parsedStr) as T;

    return metadata;
  } catch (err) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(err);
    }

    return null;
  }
}

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const pollCookie = async (CookieKey: string, intervalMs = 100) => {
  const MAX_TIMEOUT = 30000;
  let cookieValue = getCookie(CookieKey);
  let runTime = 0;
  while (!cookieValue) {
    await delay(intervalMs);
    runTime += intervalMs;
    cookieValue = getCookie(CookieKey);
    if (runTime > MAX_TIMEOUT) {
      logger.error(`Timeout waiting for cookie ${CookieKey}`);
      break;
    }
  }
  return cookieValue;
};

export const setCookie = (cname: string, cvalue: string, expirationHours = 1, path = '/') => {
  const currDate = new Date();
  const expiresAt = addDate(currDate, { hours: expirationHours }).toUTCString();
  document.cookie = `${cname}=${cvalue};expires=${expiresAt};path=${path}`;
};

export const removeCookie = (cname: string) => setCookie(cname, '', -1);

export const isEmpty = (obj: unknown) => {
  if (Array.isArray(obj)) {
    return !obj.length;
  } else {
    return !Object.entries(obj || {}).length;
  }
};

export const isEqual = (a: unknown, b: unknown): boolean => {
  if (typeof a !== typeof b) {
    return false;
  }
  if (a === null && b === null) {
    return true;
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length && !a.find((value, index) => !isEqual(value, b[index]));
  }
  if (typeof a === 'object') {
    const first = Object.entries(a || {}).sort();
    const second = Object.entries(b || {}).sort();
    if (first.length !== second.length) {
      return false;
    }
    for (const k in first) {
      if (!isEqual(first[k][1], second[k][1])) {
        return false;
      }
    }
    return true;
  }
  return a === b;
};

type CamelCase<Value extends string> = Value extends `${infer Part1}_${infer Part2}${infer Part3}`
  ? `${Lowercase<Part1>}${Uppercase<Part2>}${CamelCase<Part3>}`
  : Value;

type KeysToCamelCaseObject<Type> = {
  [Key in keyof Type as CamelCase<string & Key>]: Type[Key] extends Date
    ? Type[Key]
    : Type[Key] extends object
    ? KeysToCamelCase<Type[Key]>
    : Type[Key];
};

type KeysToCamelCaseArray<Type> = Type extends Array<infer Element>
  ? Array<KeysToCamelCase<Element>>
  : Type;

export type KeysToCamelCase<Type> = Type extends object
  ? Type extends unknown[]
    ? KeysToCamelCaseArray<Type>
    : KeysToCamelCaseObject<Type>
  : Type;

export const toCamel = (str: string) => {
  return str.replace(/([-_][a-z])/gi, ($1: string) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const keysToCamel = function <Type extends object>(object: Type): KeysToCamelCase<Type> {
  if (!isPlainObject(object) && !Array.isArray(object)) {
    throw new Error('keysToCamel only accepts plain objects and arrays');
  }

  if (Array.isArray(object)) {
    return object.map((i) => {
      return keysToCamel(i);
    }) as KeysToCamelCase<Type>;
  }

  const camelCasedObject: GeneralObject = {};

  Object.keys(object).forEach((key) => {
    const field = key as keyof typeof object;
    camelCasedObject[toCamel(key)] =
      object[field] && isPlainObject(object[field])
        ? keysToCamel(object[field] as GeneralObject)
        : object[field];
  });

  return camelCasedObject as KeysToCamelCase<Type>;
};

type SnakeCase<Value extends string> = Value extends `${infer First}${infer Rest}`
  ? // Check if the next letter is uppercase and the previous one is lowercase, if yes, add underscore
    `${First extends Uppercase<First>
      ? `_${Lowercase<First>}`
      : Lowercase<First>}${SnakeCase<Rest>}`
  : Value;

type KeysToSnakeCaseObject<Type> = {
  [Key in keyof Type as SnakeCase<string & Key>]: Type[Key] extends object
    ? KeysToSnakeCase<Type[Key]>
    : Type[Key];
};

type KeysToSnakeCaseArray<Type> = Type extends Array<infer Element>
  ? Array<KeysToSnakeCase<Element>>
  : Type;

export type KeysToSnakeCase<Type> = Type extends object
  ? Type extends unknown[]
    ? KeysToSnakeCaseArray<Type>
    : KeysToSnakeCaseObject<Type>
  : Type;

export const toSnake = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const keysToSnake = function <Type extends object>(object: Type): KeysToSnakeCase<Type> {
  if (!isPlainObject(object) && !Array.isArray(object)) {
    throw new Error('keysToSnakeCase only accepts plain objects and arrays');
  }

  if (Array.isArray(object)) {
    return object.map((i) => keysToSnake(i)) as KeysToSnakeCase<Type>;
  }

  const snakeCasedObject: GeneralObject = {};

  Object.keys(object).forEach((key) => {
    const field = key as keyof typeof object;
    snakeCasedObject[toSnake(key)] =
      object[field] && isPlainObject(object[field])
        ? keysToSnake(object[field] as GeneralObject)
        : object[field];
  });

  return snakeCasedObject as KeysToSnakeCase<Type>;
};

export const captureError: CaptureError = (args) => {
  let message = 'Something went wrong';

  switch (args.type) {
    case CaptureErrorType.Request:
      message = `${args.request} request failed: ${(args.error as Error).message}`;
      break;

    case CaptureErrorType.Validation:
      const { fields } = args;
      message = `Validation failed for field${fields.length > 1 ? 's' : ''}: ${fields.join(', ')}`;
      break;
  }

  captureException(new Error(message), {
    level: 'error',
    tags: { type: args.type },
    ...args.captureContext,
  });
};

export const scopeValidation = (scopes: string[]): Nullable<BizcuitError> => {
  const supportedScopes = Object.values(PartnerScopes) as string[];

  if (!scopes.includes(PartnerScopes.Openid)) return BizcuitErrors.openidIsRequired;

  const isValid = scopes.reduce(
    (currState, scope) => currState && supportedScopes.includes(scope),
    true,
  );

  return !isValid ? BizcuitErrors.invalidScope : null;
};

export const isHttpUrl = (url: string) => {
  const urlRegExp = new RegExp(
    /^(http)s?:\/\/(w{3,3}\.)?((?=.{3,253}$)(localhost|(([^ ]){1,63}\.[^ ]+)))$/,
  );
  return urlRegExp.test(url);
};

export const splitBySeparators = (string: string, separators: string[]): string[] => {
  const result = [];

  let sequence = '';
  for (const char of string) {
    if (separators.includes(char)) {
      result.push(sequence);
      sequence = '';
    } else {
      sequence += char;
    }
  }

  if (sequence !== '') result.push(sequence);

  return result;
};

/**
 * Finds the index of the nth occurrence of a character in a string.
 * @param str - The string to search in.
 * @param char - The character to search for.
 * @param n - The occurrence number to find.
 * @returns The index of the nth occurrence of the character in the string, or -1 if not found.
 */
export const findNthOccurrence = (str: string, char: string, n: number) => {
  let index = -1;
  for (let i = 0; i < n; i++) {
    index = str.indexOf(char, index + 1);
    if (index === -1) break;
  }
  return index;
};

/**
 * Checks if a string ends with any of the specified suffixes.
 * @param str - The string to check.
 * @param suffixes - An array of suffixes to check against.
 * @returns A boolean indicating whether the string ends with any of the specified suffixes.
 */
export const hasOneOfSuffixes = (str: string, suffixes: string[] = []) => {
  return suffixes?.some((suffix) => str.endsWith(suffix));
};

export const logger = {
  log: (message: string, ...args: unknown[]) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(message, ...args);
    }
  },
  warn: (message: string, ...args: unknown[]) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(message, ...args);
    }
  },
  error: (message: string, ...args: unknown[]) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(message, ...args);
    }
  },
};

export const findSubstrings = <T extends string>(string: string, substrings: T[]): T[] => {
  const substringsSorted = substrings.sort((current, next) => next.length - current.length);

  const inputString = string;

  const result = substringsSorted.reduce<T[]>((acc, scope) => {
    if (inputString.includes(scope)) {
      acc.push(scope);
      inputString.replaceAll(scope, '');
    }
    return acc;
  }, []);

  return result;
};

/**
 * @param inputString
 * @param replaceBy default 'email'
 * @param separator default '/'
 */
export const anonymizeEmail = (
  inputString: string,
  replaceBy = 'email',
  separator = '/',
): string => {
  const regex = new RegExp(separator + '[\\w.+-]+@[a-zA-Zd.-]+\\.[a-zA-Z]{2,}\\b');
  return inputString.replace(regex, separator + replaceBy);
};

/**
 * @param inputString
 * @param replaceBy default 'uuid'
 * @param separator default '/'
 */
export const anonymizeUuid = (inputString: string, replaceBy = 'uuid', separator = '/'): string => {
  const regex = new RegExp(
    separator +
      '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}\\b',
  );
  return inputString.replace(regex, separator + replaceBy);
};

/**
 * Get current environment based on the hostname
 */
export const getEnvironment = () => {
  const url = window.location.hostname;

  if (url.includes('localhost')) return Environment.Development;
  if (url.includes('dev.bizcuit.nl')) return Environment.Staging;
  if (url.includes('tst.bizcuit.nl')) return Environment.Acceptance;
  if (url.includes('app.bizcuit.nl')) return Environment.Production;

  return 'UNKNOWN';
};

/**
 * Normalizes a price by converting it to a string with two decimal places and replacing the decimal point with a comma.
 * If the input is not a number or is falsy, it returns an empty string.
 *
 * @param {number} price - The price to normalize.
 * @returns {string} The normalized price.
 */
export const normalizePrice = (price: number): string => {
  if (typeof price !== 'number' || !isFinite(price)) return '';
  return price.toFixed(2).replace('.', ',');
};

/**
 * Fetches the content of a URL and returns it as a Data URL.
 *
 * @param url - The URL to fetch the content from.
 * @returns A promise that resolves to the fetched content as a Data URL string, or null if the fetch fails.
 */
export const fetchAsDataURL = async (url: string): Promise<string | null> =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string | null);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );
