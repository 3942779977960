import React, { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router';
import Menu from 'src/molecules/menu/Menu';
import { PageHeader, PageLayout } from 'src/atoms/layout/menu-page';
import Paths from '../../tokens/Paths';
import { ErrorBoundary } from '@sentry/react';
import HixFallback from '../../organisms/hix/fallback/HixFallback';
import PrivateRoutesGuard from 'src/organisms/private-routes-guard/PrivateRoutesGuard';
import EmbeddedUserAccessGuard from 'src/organisms/embedded-user-access-guard/EmbeddedUserAccessGuard';
const Connect = lazy(() => import('./connect/Connect'));
const Main = lazy(() => import('./main/Main'));
const SelectDomain = lazy(() => import('./selectDomain/SelectDomain'));
const SuccessfulConnection = lazy(() => import('./successfulConnection/SuccessfulConnection'));
const VerifyEmail = lazy(() => import('./verifyEmail/VerifyEmail'));
const VerifySms = lazy(() => import('./verifySms/VerifySms'));

const { segments } = Paths.hix;
const HixRouter: React.FC = () => {
  const { t } = useTranslation('hix');

  return (
    <Suspense fallback={null}>
      <PageLayout>
        <PageHeader title={t('app_header')} />
        <Menu />
        <ErrorBoundary fallback={<HixFallback />}>
          <Routes>
            <Route element={<PrivateRoutesGuard />}>
              <Route element={<EmbeddedUserAccessGuard />}>
                <Route path={segments.mainMenu} element={<Main />} />
                <Route
                  path={'/*'}
                  element={
                    <Routes>
                      <Route path={segments.connect} element={<Connect />} />
                      <Route path={segments.verifyEmail} element={<VerifyEmail />} />
                      <Route path={segments.verifySms} element={<VerifySms />} />
                      <Route path={segments.selectDomain} element={<SelectDomain />} />
                      <Route
                        path={segments.successfulConnection}
                        element={<SuccessfulConnection />}
                      />
                    </Routes>
                  }
                ></Route>
              </Route>
            </Route>
          </Routes>
        </ErrorBoundary>
      </PageLayout>
    </Suspense>
  );
};

export default HixRouter;
