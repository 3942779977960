import { atom } from 'jotai';
import { Result } from 'src/services/types';
import { enterIban } from 'src/services/user';
import { userAtom } from '../user-state-atoms';
import { setUserValuesAtom } from './setUserValuesAtom';

const setBillingIbanAtom = atom(null, async (_get, set): Promise<Result> => {
  const { billingIban, billingAccepted } = _get(userAtom);

  if (!billingIban) {
    return { success: false, error: { message: 'Please enter a valid IBAN' } };
  }

  const { success, error } = await enterIban(billingIban, billingAccepted || false);

  if (success) {
    set(setUserValuesAtom, {
      billingIban: success ? billingIban : '',
      billingAccepted,
    });
  }
  return { success, error };
});

export { setBillingIbanAtom };
