import { FC } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { HeaderGoBackButtonProps } from './HeaderGoBackButton.types';

const HeaderGoBackButton: FC<HeaderGoBackButtonProps> = ({
  onClick,
  align = 'center',
  size = 6,
}) => {
  const widthClassName = `w-${size}`;
  const heightClassName = `h-${size}`;
  const alignClassName = `self-${align}`;
  return (
    <button className={`justify-self-start ${alignClassName}`} onClick={onClick}>
      <BizcuitIcon
        icon="arrow-left"
        className={`${widthClassName} ${heightClassName} dark:text-white`}
      />
    </button>
  );
};

export default HeaderGoBackButton;
