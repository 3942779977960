import { atom } from 'jotai';
import { globalStore } from 'src/contexts/globalStore';
import { getPersistedUserState, setPersistedUserState } from '../user-actions-atoms';
import { Session } from 'src/interfaces/Session';
import { userSessionInitial } from './userStoreInitial';

const userSessionAtom = atom<Session>(getPersistedUserState().userSession ?? userSessionInitial);

globalStore.sub(userSessionAtom, () => {
  setPersistedUserState({
    ...getPersistedUserState(),
    userSession: globalStore.get(userSessionAtom),
  });
});

if (process.env.NODE_ENV !== 'production') {
  userSessionAtom.debugLabel = 'User Session';
}

export { userSessionAtom };
