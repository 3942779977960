import React, { useEffect, useRef, useState } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { useOnClickOutside } from 'src/hooks';

type Props = {
  defaultLanguage?: string;
  languages: {
    flag: string;
    language: string;
  }[];
  onLanguageChange?: (language: string) => void;
};

const LanguagePicker = ({ defaultLanguage, languages, onLanguageChange }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    defaultLanguage || languages[0].language,
  );

  useOnClickOutside(ref, () => setIsOpen(false));

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
    onLanguageChange?.(language);
    setIsOpen(false);
  };

  const { flag, language } =
    languages.find((lang) => lang.language === selectedLanguage) || languages[0];

  return (
    <div
      className="relative flex flex-col items-center select-none"
      ref={ref}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex items-center gap-1 sm:gap-2.5 hover:cursor-pointer">
        <img
          className="w-4 h-4 text-xs font-weight-500 uppercase text-primary-500 dark:text-white"
          src={flag}
          alt={language}
        />
        <BizcuitIcon
          icon="chevron-down"
          className={`h-6 w-6 dark:text-white transition-transform${isOpen ? ' rotate-180' : ''}`}
        />
      </div>
      {isOpen && (
        <div className="absolute top-10 z-20 bg-white dark:bg-biz-gray-800 rounded-2xl shadow-md border border-biz-gray-200 dark:border-biz-gray-600 overflow-hidden w-20">
          {languages.map((language, index) => (
            <div
              className={`${
                index + 1 != languages.length && 'border-b border-biz-gray-200 dark:border-gray-600'
              } p-3 hover:cursor-pointer hover:dark:bg-biz-gray-900 hover:bg-biz-gray-100 flex gap-1 items-center`}
              onClick={() => {
                handleLanguageChange(language.language);
              }}
              key={index}
            >
              <img
                src={language.flag}
                alt={language.language}
                className="w-4 h-4 text-xs font-weight-500 uppercase text-primary-500 dark:text-white"
              />
              <p className="text-medium font-weight-500 text-primary-500 dark:text-white">-</p>
              <p className="text-medium font-weight-500 text-primary-500 dark:text-white">
                {language.language.toUpperCase()}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguagePicker;
