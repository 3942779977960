import { atom } from 'jotai';
import { Result } from 'src/services/types';
import { enterUsername } from 'src/services/user';
import { setUserValuesAtom } from './setUserValuesAtom';

const setNameAtom = atom(
  null,
  async (_get, set, firstName: string, lastName: string): Promise<Result> => {
    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();
    const displayName = `${trimmedFirstName} ${trimmedLastName}`;

    const { success, error } = await enterUsername(trimmedFirstName, trimmedLastName, displayName);

    if (success) {
      set(setUserValuesAtom, {
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
        displayName,
      });
    }

    return { success, error };
  },
);

export { setNameAtom };
