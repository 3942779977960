import { sendVerificationTokenEmail } from 'src/services/user';

const sendVerificationToken = async (payload: {
  email: string;
  reminder?: boolean;
}): Promise<boolean> => {
  const { success } = await sendVerificationTokenEmail(payload);

  return success;
};

export { sendVerificationToken };
