import { atom } from 'jotai';
import { sendUserExistEmail } from 'src/services/user';
import { PartnerAuthorisation } from 'src/interfaces/Partner';

const sendUserEmailExistEmailAtom = atom(
  null,
  async (
    _get,
    set,
    email: string,
    partnerAuthorisation?: PartnerAuthorisation,
  ): Promise<boolean> => {
    const { success } = await sendUserExistEmail({ email, partnerAuthorisation });
    return success;
  },
);

export { sendUserEmailExistEmailAtom };
