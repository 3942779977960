import React, { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Paths from 'src/tokens/Paths';
import useGoogleAnalytics from 'src/hooks/useGoogleAnalytics';
import PublicRoutesGuard from 'src/organisms/public-routes-guard/PublicRoutesGuard';

const EmailSent = lazy(() => import('./email-sent/EmailSent'));
const EnterAccountType = lazy(() => import('./enter-account-type/EnterAccountType'));
const EnterBankAccount = lazy(() => import('./enter-bank-account/EnterBankAccount'));
const EnterCompany = lazy(() => import('./enter-company/EnterCompany'));
const EnterEmail = lazy(() => import('./enter-email/EnterEmail'));
const EnterName = lazy(() => import('./enter-name/EnterName'));
const EnterSubscription = lazy(() => import('./enter-subscription/EnterSubscription'));
const EnterPartnerConsent = lazy(() => import('./partner-consent/EnterPartnerConsent'));
const Success = lazy(() => import('./success/Success'));
const ValidateEmail = lazy(() => import('./validate-email/ValidateEmail'));
const Verify = lazy(() => import('./verify/Verify'));

const SignUpRouter: FC = () => {
  useGoogleAnalytics();
  const { segments } = Paths.signUp;

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route
          index
          element={<Navigate replace to={`${Paths.signUp.root}${segments.enterEmail}`} />}
        />
        <Route path={`${segments.enterEmail}`} element={<EnterEmail />} />
        <Route element={<PublicRoutesGuard />}>
          <Route path={`${segments.emailSent}`} element={<EmailSent />} />
          <Route path={`${segments.enterName}`} element={<EnterName />} />
          <Route path={`${segments.enterAccountType}`} element={<EnterAccountType />} />
          <Route path={`${segments.enterCompany}`} element={<EnterCompany />} />
          <Route path={`${segments.enterSubscription}`} element={<EnterSubscription />} />
          <Route path={`${segments.enterIBAN}`} element={<EnterBankAccount />} />
          <Route path={`${segments.enterConsent}`} element={<EnterPartnerConsent />} />
          <Route path={`${segments.verify}`} element={<Verify />} />
          <Route path={`${segments.success}`} element={<Success />} />
          <Route path={`${segments.validateEmailAddress}`} element={<ValidateEmail />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default SignUpRouter;
