// URL that Orchestrator redirects to when something unexpected happens
export const ORCHESTRATOR_PANIC_URL = '/';

// URL that Orchestrator redirects to when user comes to Orchestrator but has no active flow
export const ORCHESTRATOR_NO_ACTIVE_FLOW_URL = ORCHESTRATOR_PANIC_URL;

// Mappings between a connection request's flow ID and the translation key to use for i18n strings
export const requestTypeMappings = {
  connectAccountingSystem: 'connectAccountingSystem',
  connectBank: 'connectBank',
  'visa-connect-accounting-system': 'connectAccountingSystem',
  'connect-accounting-system-and-bank': 'connectAccountingSystem',
  default: 'default',
} as const;

export const connectAccountingSystemActions = [
  'connect-accounting-system',
  'connect-accounting-system-bank',
  'connect-accounting-system-mollie-ap-ar',
  'visa-connect-accounting-system',
];
