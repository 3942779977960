import { AxiosError } from 'axios';
import { Result } from 'src/services/types';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import { BindSessionRequest, BindSessionResponse } from './types';

export const bindSession = async ({
  sessionId,
  deviceName,
  devicePublicKey,
}: BindSessionRequest): Promise<Result> => {
  try {
    await restApi.post<BindSessionResponse>(`/api/latest/user/bind_session`, {
      session_id: sessionId,
      device_name: deviceName,
      device_public_key: devicePublicKey,
    });

    return {
      success: true,
    };
  } catch (error) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'bindSession',
      error: error as Error,
      captureContext: {
        level: 'fatal',
      },
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
