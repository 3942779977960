import { atomWithStorage } from 'jotai/utils';
import { getLocalStorageObject } from 'src/helpers';
import { FlowParams } from 'src/pages/authorize-user/types';
import { FlowData, App } from 'src/types/bizcuitApi';
import { Request, Requests } from 'src/types/orchestrator';

type OrchestratorState = {
  approverRequest?: Request;
  requesterRequests?: Requests;
  flow?: FlowData | null;
  appsToConfigure?: App[] | null;
  redirectUrl?: string | null;
  changeSettings?: boolean;
  flowParams?: FlowParams;
} | null;

const orchestratorAtom = atomWithStorage<OrchestratorState>(
  'orchestrator',
  getLocalStorageObject<OrchestratorState>('orchestrator'),
);

export { orchestratorAtom };
