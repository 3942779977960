import React, { FC } from 'react';
import { Route, Routes } from 'react-router';
import Paths from '../../tokens/Paths';
const ConfirmBiometrics = React.lazy(() => import('./confirm-biometrics/ConfirmBiometrics'));
const EnterBiometrics = React.lazy(() => import('./enter-biometrics/EnterBiometrics'));

const BiometricsRouter: FC = () => {
  const { segments } = Paths.biometrics;

  return (
    <Routes>
      <Route path={`${segments.enter}`} element={<EnterBiometrics />} />
      <Route path={`${segments.confirm}`} element={<ConfirmBiometrics />} />
    </Routes>
  );
};

export default BiometricsRouter;
