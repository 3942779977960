import { AxiosError } from 'axios';
import { captureError, keysToCamel } from 'src/utils';
import restApi from 'src/services/restApi';
import { Session } from 'src/interfaces/Session';
import { GeneralObject } from '../../../types/generic';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { createInactiveUserSessionResponse, createInactiveUserSessionResult } from './types';

export const createInactiveUserSession = async (
  userId: string,
): Promise<createInactiveUserSessionResult> => {
  try {
    const { data } = await restApi.post<createInactiveUserSessionResponse>(
      `/api/latest/user/${userId}/session`,
    );

    const user = keysToCamel(data.user);
    const session = keysToCamel(data.userSession) as GeneralObject;
    const accessToken = `${data.accessToken}`;

    const userSession: Session = {
      id: session.sessionId as string,
      lastUsedAt: null,
      deviceName: null,
      biometricsPublicKey: null,
    };
    const result = {
      data: {
        user,
        userSession,
        accessToken,
      },
      success: true,
    };

    return result;
  } catch (error) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'createUserSession',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data?.message ?? '',
      },
    };
  }
};
