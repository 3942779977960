import { atom } from 'jotai';
import { getPersistedPartnerState } from '../partner-actions-atoms';
import { globalStore } from 'src/contexts/globalStore';
import { setPersistedPartnerState } from '../partner-actions-atoms';
import { Partner } from 'src/types/bizcuitApi';

const partnerAtom = atom<Nullable<Partner>>(getPersistedPartnerState().partner);

globalStore.sub(partnerAtom, () => {
  setPersistedPartnerState({
    ...getPersistedPartnerState(),
    partner: globalStore.get(partnerAtom),
  });
});

if (process.env.NODE_ENV !== 'production') {
  partnerAtom.debugLabel = 'Partner';
}

export { partnerAtom };
