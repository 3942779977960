import { atom } from 'jotai';
import {
  setPersistedPartnerState,
  setPartnerState,
  setPartnerTheme,
} from '../partner-actions-atoms';
import { PartnerInitialaizeParams, PartnerState } from 'src/contexts/partner-global-state/types';

const initializePartnerState = atom(
  null,
  async (get, set, initParams: PartnerInitialaizeParams) => {
    const { clientReference, brandingClientId, partnerAuthorisation, partner } = initParams;
    const partnerReference = clientReference ? decodeURIComponent(clientReference) : undefined;

    if (partner) {
      const brandingTheme = partner.theme;

      const updatedPartnerState = {
        partner,
        partnerReference,
        partnerId: partner.id,
        brandingPartnerId: brandingClientId,
        partnerAuthorisation,
        brandingTheme,
      } as PartnerState;

      set(setPartnerState, updatedPartnerState);
      setPersistedPartnerState(updatedPartnerState);
      if (brandingTheme) {
        set(setPartnerTheme, brandingTheme);
      }
    }
  },
);

export { initializePartnerState };
