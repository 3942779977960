import React, { FC } from 'react';
import Text from '../text/Text';
import { TextVariant, TextWeight } from '../text/Text.types';
import ParagraphProps from './Paragraph.types';

const Paragraph: FC<ParagraphProps> = ({
  children,
  className = '',
  variant = TextVariant.mediumLoose,
  color = 'text-warm-grey-800 dark:text-white',
  isBold = false,
  isCentered = false,
}) => (
  <Text
    as="p"
    variant={variant}
    weight={isBold ? TextWeight.medium : TextWeight.regular}
    color={color}
    isCentered={isCentered}
    className={className}
  >
    {children}
  </Text>
);

export default Paragraph;
