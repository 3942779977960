import { atom } from 'jotai';
import { invoiceStateAtom } from '../invoice-state-atoms';
import { InvoiceFinanceState } from 'src/interfaces/Invoice';

const setInvoiceFinanceStateAtom = atom(
  null,
  (_get, set, invoiceFinanceState: InvoiceFinanceState) => {
    set(invoiceStateAtom, {
      ..._get(invoiceStateAtom),
      invoiceFinanceState,
    });
  },
);

export { setInvoiceFinanceStateAtom };
