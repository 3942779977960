import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import { UpdateEmailResult } from './types';

export const updateEmail = async (email: string): Promise<UpdateEmailResult> => {
  const result: UpdateEmailResult = { success: false };

  try {
    await restApi.patch<UpdateEmailResult>(`/api/latest/user/update_email`, { email });

    result.success = true;
  } catch (error: unknown) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'enterUsername',
      error: error as Error,
    });

    const returnError = error as AxiosError;

    result.error = { message: returnError.response?.data ?? '' };
  }

  return result;
};
