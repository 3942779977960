import { atom } from 'jotai';
import { setBillingAddress } from 'src/services/metering';
import { deleteBillingAddress } from 'src/services/metering/deleteBillingAddress';
import { Result } from 'src/services/types';
import { updateBillingName } from 'src/services/user';
import { userAtom } from '../user-state-atoms';
import { BillingAddress } from 'src/interfaces/User';
import { setUserValuesAtom } from './setUserValuesAtom';

/*TODO:: Add better error handling in case of invalid KvKResult*/
const postBillingAddressAtom = atom(null, async (_get, set): Promise<Result> => {
  const { kvkResult } = _get(userAtom);
  let billingAddress: BillingAddress;
  if (kvkResult) {
    const { tradeName, houseNumber, houseNumberAddition, postalCode, street, city } = kvkResult;

    const { success, error } = await updateBillingName(tradeName);
    if (!success) {
      return { success, error };
    }
    billingAddress = {
      tradeName,
      country: '',
      postalCode: '',
      houseNumber: '',
      street: '',
      city: '',
    };

    if (postalCode && street && city) {
      const billingAddressFromKvk = {
        tradeName: tradeName,
        houseNumber: `${houseNumber}${houseNumberAddition ? ` ${houseNumberAddition}` : ''}`,
        country: 'NL',
        postalCode: postalCode,
        street: street,
        city: city,
      };

      const { data, success, error } = await setBillingAddress(billingAddressFromKvk);
      if (!success) {
        return { success, error };
      }
      billingAddress = { id: data?.id, ...billingAddressFromKvk };
    } else if (billingAddress.id) {
      const billingAddressId = billingAddress.id;
      const { success, error } = await deleteBillingAddress({ billingAddressId });
      if (!success) {
        return { success, error };
      }
    }
    set(setUserValuesAtom, { billingAddress });
  }

  return { success: true };
});

export { postBillingAddressAtom };
