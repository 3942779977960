import { FC, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import Paths from '../../tokens/Paths';
import LogoutTimer from 'src/organisms/logout-timer/LogoutTimer';
import { PageLayout } from 'src/atoms/layout/menu-page';
import FlowLayout from 'src/atoms/layout/menu-page/FlowLayout';
const ConnectionFailed = lazy(() => import('./ais-consent/connection-failed/ConnectionFailed'));
const ProvideConsent = lazy(() => import('./ais-consent/provide-consent/ProvideConsent'));
const SelectBankOption = lazy(() => import('./ais-consent/select-account-option/SelectBankOption'));
const SelectBank = lazy(() => import('./ais-consent/select-bank/SelectBank'));
const MultipleBankAccountsConnected = lazy(
  () => import('./verify-account-holder/multiple-bank-accounts/MultipleBankAccounts'),
);
const SelectAccountHolder = lazy(
  () => import('./verify-account-holder/select-account-holder/SelectAccountHolder'),
);
const SelectBankAccountType = lazy(
  () => import('./verify-account-holder/select-account-type/SelectBankAccountType'),
);
const VerifyLegalRepStatus = lazy(() => import('./verify-legal-rep-status/VerifyLegalRepStatus'));
const UboDetailsForm = lazy(() => import('./verify-ubos/details/UboDetails'));
const UboInformation = lazy(() => import('./verify-ubos/ubo-information/UboInformation'));
const UboOverview = lazy(() => import('./verify-ubos/ubo-overview/UboOverview'));
const ListBankAccounts = lazy(() => import('./list-bank-accounts/ListBankAccounts'));
const BankConnected = lazy(() => import('./ais-consent/bank-connected/BankConnected'));
const ListTransactions = lazy(() => import('./list-transactions/ListTransactions'));
const ConnectAccountsSuccess = lazy(
  () => import('./connect-accounts-success/ConnectAccountsSuccess'),
);
const UserBankAccounts = lazy(() => import('./ais-consent/bank-account-list/UserBankAccounts'));

const { segments } = Paths.ais;
const { kyc, verifyUbo, verifyAccountHolder } = segments;

const AisRouter: FC = () => {
  return (
    <Suspense fallback={<FlowLayout />}>
      <PageLayout>
        <LogoutTimer />
        <Routes>
          <Route path={`${segments.selectBank}`} element={<SelectBank />} />
          <Route path={`${segments.selectBankOption}`} element={<SelectBankOption />} />
          <Route path={`${segments.userBankAccounts}`} element={<UserBankAccounts />} />
          <Route path={`${segments.provideConsent}`} element={<ProvideConsent />} />
          <Route path={`${segments.bankConnected}`} element={<BankConnected />} />
          <Route path={`${segments.connectionFailed}`} element={<ConnectionFailed />} />
          <Route path={`${segments.verifyLegalRepStatus}`} element={<VerifyLegalRepStatus />} />
          <Route
            path={`${verifyUbo.root}${verifyUbo.segments.info}`}
            element={<UboInformation />}
          />
          <Route
            path={`${verifyUbo.root}${verifyUbo.segments.details}`}
            element={<UboDetailsForm />}
          />
          <Route
            path={`${verifyUbo.root}${verifyUbo.segments.overview}`}
            element={<UboOverview />}
          />
          <Route
            path={`${verifyAccountHolder.root}${verifyAccountHolder.segments.multipleBankAccounts}`}
            element={<MultipleBankAccountsConnected />}
          />
          <Route
            path={`${verifyAccountHolder.root}${verifyAccountHolder.segments.searchAccountHolder}`}
            element={<SelectAccountHolder />}
          />
          <Route
            path={`${verifyAccountHolder.root}${verifyAccountHolder.segments.selectAccountHolder}`}
            element={<SelectAccountHolder />}
          />
          <Route
            path={`${verifyAccountHolder.root}${verifyAccountHolder.segments.selectAccountType}`}
            element={<SelectBankAccountType />}
          />
          <Route
            path={`${verifyAccountHolder.root}${verifyAccountHolder.segments.creatingConnection}`}
            element={<SelectBankAccountType />}
          />
          <Route
            path={`${kyc.root}${kyc.segments.connectionSuccessful}`}
            element={<SelectBankAccountType />}
          />
          <Route path={`${segments.bankAccountSuccess}`} element={<ConnectAccountsSuccess />} />
          <Route path={`${segments.selectBankAccount}`} element={<ListBankAccounts />} />
          <Route path={`${segments.transactionList}`} element={<ListTransactions />} />
        </Routes>
      </PageLayout>
    </Suspense>
  );
};

export default AisRouter;
