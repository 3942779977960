import { atom } from 'jotai';
import { globalStore } from 'src/contexts/globalStore';
import { getPersistedInvoiceState, setPersistedInvoiceState } from '../invoice-actions-atoms';
import { InvoiceContextState } from '../../invoiceState.types';

const invoiceStateAtom = atom<InvoiceContextState>(getPersistedInvoiceState());

globalStore.sub(invoiceStateAtom, () => {
  setPersistedInvoiceState({
    ...getPersistedInvoiceState(),
    ...globalStore.get(invoiceStateAtom),
  });
});

if (process.env.NODE_ENV !== 'production') {
  invoiceStateAtom.debugLabel = 'Access Token';
}

export { invoiceStateAtom };
