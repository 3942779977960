import React, { useRef, createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EventNames, TrackUserIdentityArgs, UserTraits } from 'src/types/analytics';
import { GeneralObject } from 'src/types/generic';
import mixpanel, { type Mixpanel } from 'mixpanel-browser';
import { anonymizeEmail, anonymizeUuid, logger } from 'src/utils';

interface AnalyticsProviderProps {
  children?: ReactNode;
}

const AnalyticsContext = createContext<{
  trackEvent: (eventName: EventNames, eventData?: GeneralObject) => void;
  trackUserIdentity: (args: TrackUserIdentityArgs) => Promise<void>;
  resetUserIdentity: () => void;
  waitForMixpanelSetup?: () => Promise<void>;
}>(null as never);

const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const routerLocation = useLocation();
  const prevPage = useRef<string>();
  const prevEvent = useRef<{ eventName: string; eventData?: GeneralObject } | undefined>();
  const [mixpanelInstance, setMixpanelInstance] = useState<Mixpanel | null>(null);

  const isTrackingDisabled =
    navigator.userAgent.includes('Bizcuit Automated') || !process.env.REACT_APP_MIXPANEL_TOKEN;

  useEffect(() => {
    if (mixpanelInstance || isTrackingDisabled) {
      return;
    }

    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      logger.log('Initializing Mixpanel');
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        api_host: 'https://api-eu.mixpanel.com',
        persistence: 'localStorage',
        debug: process.env.NODE_ENV === 'development',
        loaded: (mixpanelInstance) => {
          setMixpanelInstance(mixpanelInstance);
        },
      });
    }
  }, [isTrackingDisabled, mixpanelInstance]);

  useEffect(() => {
    if (isTrackingDisabled) {
      return;
    }

    let path = routerLocation.pathname;
    path = anonymizeEmail(path);
    path = anonymizeUuid(path);
    if (path !== prevPage.current) {
      prevPage.current = path;
      try {
        mixpanel.track_pageview({ path, title: document.title });
      } catch (error) {
        logger.error(`Failed to track page view: ${path}`, error);
      }
    }
  }, [isTrackingDisabled, routerLocation]);

  const trackEvent = (eventName: string, eventData?: GeneralObject) => {
    const properties: GeneralObject = {};

    if (isTrackingDisabled) {
      return;
    }

    if (
      prevEvent.current &&
      prevEvent.current.eventName === eventName &&
      compareProperties(prevEvent.current.eventData, eventData)
    ) {
      logger.warn(`Skipping duplicate event: ${eventName}`, eventData);
      return;
    }

    prevEvent.current = { eventName, eventData };

    switch (eventName) {
      case EventNames.buttonClicked:
        properties.title = eventData?.title || 'Button';
        break;
      case EventNames.pinEntered:
        break;
      case EventNames.deviceBound:
      case EventNames.deviceBindFailed:
        properties.biometricsType = eventData?.biometricsType || 'None';
        properties.isAuthFlow = eventData?.isAuthFlow || false;
        break;
      case EventNames.ibanEntered:
        break;
      case EventNames.emailEntered:
      case EventNames.emailEnteredFailed:
        break;
      case EventNames.nameEntered:
      case EventNames.nameEnteredFailed:
        break;
      case EventNames.subscriptionSelected:
        properties.subscription = eventData?.subscription || 'Unknown';
        break;
      case EventNames.accountCreated:
        properties.isAuthFlow = eventData?.isAuthFlow || false;
        break;
      case EventNames.accountVerified:
      case EventNames.accountVerifiedFailed:
        properties.createBankAccount = eventData?.createBankAccount || false;
        properties.createAdministration = eventData?.createAdministration || false;
        break;
      case EventNames.linkOpened:
        properties.isNewWindow = eventData?.isNewWindow || false;
        properties.link = eventData?.link || 'Unknown';
        break;
      case EventNames.slideViewed:
        properties.index = eventData?.index || 0;
        properties.title = eventData?.title || 'Untitled';
        properties.description = eventData?.description || 'None';
        break;
      case EventNames.signUpResumed:
        properties.step = eventData?.step || '';
        break;
      case EventNames.startFlow:
        properties.flowId = eventData?.flowId || '';
        properties.isRequestFlow = eventData?.isRequestFlow || false;
        break;
      case EventNames.signedOut:
        properties.action = eventData?.action || 'unknown';
        if (eventData?.error) {
          properties.error = eventData.error;
        }
        break;
      case EventNames.viewedLandingPage:
        break;
    }

    try {
      mixpanel.track(eventName, properties);
    } catch (error) {
      logger.error(`Failed to track event: ${eventName}`, error);
    }
  };

  const trackUserIdentity = async ({ user, partner }: TrackUserIdentityArgs): Promise<void> => {
    if (!user.id) {
      logger.error('User ID is required to track user identity');
      return;
    }

    if (isTrackingDisabled) {
      return;
    }

    const userName = user.displayName
      ? user.displayName
      : user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : undefined;

    const userTraits: UserTraits = {
      userId: user.id,
      $name: userName,
      $email: user.email || undefined,
      $first_name: user.firstName || undefined,
      $last_name: user.lastName || undefined,
      betaTester: user.betaTester,
      active: user.active,
      ...(user.createdThrough && { createdThrough: user.createdThrough }),
      ...(user.partnerId && { partnerId: user.partnerId }),
      ...(user.partnerReference && { partnerReference: user.partnerReference }),
      ...(partner?.name && { partnerName: partner.name }),
    };

    const existingUserId = mixpanel.get_property('userId');
    if (existingUserId && existingUserId !== user.id) {
      logger.warn(`Mixpanel user ID mismatch: ${existingUserId} !== ${user.id}`);
      mixpanel.reset();
    }

    mixpanel.register({ userId: user.id });
    mixpanel.people.set(userTraits);
    await mixpanel.identify(user.id);
  };

  const resetUserIdentity = () => {
    if (isTrackingDisabled) {
      return;
    }

    mixpanel.reset();
  };

  const value = {
    trackEvent,
    trackUserIdentity,
    resetUserIdentity,
  };

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

const compareProperties = (obj1: GeneralObject | undefined, obj2: GeneralObject | undefined) => {
  if (!obj1 || !obj2) {
    return false;
  }
  const obj1Keys = Object.keys(obj1);
  return obj1Keys.every((key) => obj1[key] === obj2[key]);
};

const useAnalytics = () => useContext(AnalyticsContext);

export { AnalyticsProvider, useAnalytics };
