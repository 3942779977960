import { FlowLayout } from 'src/atoms/layout/menu-page';
import { useNavigate } from 'react-router';
import React, { useState } from 'react';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import AlertDialog from 'src/organisms/dialog/AlertDialog';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'src/atoms/buttons/tertiary-button/TertiaryButton';
import Container from 'src/atoms/layout/container/Container';
import Checkbox from 'src/atoms/inputs/checkbox/Checkbox';
import Paths from 'src/tokens/Paths';

const UbosFromDirectors = () => {
  const [showWhatIsUbo, setShowWhatIsUbo] = useState(false);
  const [selectedDirectors, setSelectedDirectors] = useState<object>();
  const navigate = useNavigate();
  const { t } = useTranslation(['cdd', 'common']);

  //TODO: Replace with actual data and ubo type
  const mockCompany = {
    company: 'Vereniging het Zonnetje B.V.',
    kvk: '60809442',
  };
  const mockUbos = ['Hessel Kuik', 'John Johnson Junior', 'Maritius Aurelius longer longer longer'];
  const uboTranslation = 'pseudoUbo';

  const descriptionTranslations = t(`cdd:ubosFromDirectors.${uboTranslation}.description`, {
    returnObjects: true,
  });

  const handleProceed = () => {
    navigate(
      `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.ubosInProgress}`,
    );
  };

  const isDisabled = () => {
    return !selectedDirectors || Object.values(selectedDirectors).every((value) => value === false);
  };

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex pb-6 items-center w-full flex-wrap flex-col">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          <SecondaryButton className="mt-4 md:mt-6" underline>
            {t('common:button.noThankYou')}
          </SecondaryButton>
          <PrimaryButton
            className="md:w-80"
            isDisabled={isDisabled()}
            onClick={() => handleProceed()}
          >
            {t('common:button.proceed')}
          </PrimaryButton>
        </footer>
      }
    >
      <>
        <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
          {t(`cdd:ubosFromDirectors.${uboTranslation}.primaryHeader`)}
        </h1>
        <div className="flex flex-col">
          {descriptionTranslations.map((description, index) => (
            <p key={index} className={`text-medium-loose${index > 0 ? ' pt-6' : ''}`}>
              {description}
            </p>
          ))}
          <TertiaryButton
            className="mt-2 mb-6"
            color="gray-800"
            underline
            onClick={() => setShowWhatIsUbo(true)}
          >
            {t(`cdd:whatIsUboDialog.${uboTranslation}.title`)}
          </TertiaryButton>
          <div className="flex p-4 justify-between mb-6">
            <p className="text-medium font-weight-500">{mockCompany.company}</p>
            <div className="flex gap-1 text-x-small items-center">
              <p className="font-weight-500">{t('common:chamberOfCommerceShortHand')}</p>
              <p>{mockCompany.kvk}</p>
            </div>
          </div>
          <p className="text-x-small font-weight-500 text-biz-gray-600 dark:text-white mb-2">
            {t('common:directors')}
          </p>
          <div className="grid md:grid-cols-2 gap-2">
            {mockUbos.map((ubo, index) => (
              <Container roundedClass="rounded-2xl" key={index}>
                <div className="flex gap-4 items-center">
                  <Checkbox
                    name="check"
                    onChecked={() => {
                      setSelectedDirectors((prevState) => {
                        return { ...prevState, [ubo]: true };
                      });
                    }}
                    onUnchecked={() => {
                      setSelectedDirectors((prevState) => {
                        return { ...prevState, [ubo]: false };
                      });
                    }}
                  />
                  <p className="text-small truncate">{ubo}</p>
                </div>
              </Container>
            ))}
          </div>
        </div>
      </>
      <AlertDialog
        isVisible={showWhatIsUbo}
        onClose={() => setShowWhatIsUbo(!showWhatIsUbo)}
        alertDialogTexts={t(`cdd:whatIsUboDialog.${uboTranslation}`, { returnObjects: true })}
      />
    </FlowLayout>
  );
};

export default UbosFromDirectors;
