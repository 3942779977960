import { useEffect } from 'react';
import RedirectToExternalSourceProps from './RedirectToExternalSource.types';

const RedirectToExternalSource = ({ url }: RedirectToExternalSourceProps) => {
  useEffect(() => {
    window.location.replace(url);
  }, [url]);

  return <></>;
};

export default RedirectToExternalSource;
