import React, { FC } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import AddButtonProps from './AddButton.types';

const AddButton: FC<AddButtonProps> = ({ children, onClick, className, ...rest }) => {
  return (
    <button
      className={`flex gap-1.5 px-3 py-2 border-2 border-primary-500 rounded-full text-medium font-weight-600 text-primary-500 dark:text-white ${
        className ? `${className}` : ''
      }`}
      onClick={onClick}
      {...rest}
    >
      <BizcuitIcon icon="plus-circle" className="w-5 h-5 text-primary-500" />
      {children}
    </button>
  );
};

export default AddButton;
