import React, { FC } from 'react';
import { IconContext as ReactIconsContext } from 'react-icons';
import IconContextProps from './IconContext.types';
import { useIsDarkMode } from 'src/hooks';

const IconContext: FC<IconContextProps> = ({
  children,
  className,
  isChecked,
  primaryColor = 'text-primary-500',
  darkColor,
  onClick,
}) => {
  const isDarkMode = useIsDarkMode();
  const getIconColor = () => {
    if (isDarkMode) {
      return !!darkColor ? darkColor : primaryColor;
    }
    return primaryColor;
  };

  return (
    <ReactIconsContext.Provider
      value={{
        className: `${getIconColor()} ${className}`,
      }}
    >
      <div onClick={onClick} className="relative">
        {children}
        {isChecked && (
          <div className="absolute right-0 -bottom-1">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6" cy="6" r="6" fill="url(#paint0_linear)" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.98866 3.84467C9.28156 4.13756 9.28156 4.61244 8.98866 4.90533L5.66575 8.22825C5.52509 8.3689 5.33433 8.44792 5.13542 8.44792C4.9365 8.44792 4.74574 8.3689 4.60509 8.22825L3.09467 6.71783C2.80178 6.42494 2.80178 5.95006 3.09467 5.65717C3.38756 5.36428 3.86244 5.36428 4.15533 5.65717L5.13542 6.63726L7.928 3.84467C8.2209 3.55178 8.69577 3.55178 8.98866 3.84467Z"
                fill="#003A1E"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="0"
                  y1="0"
                  x2="2.08006"
                  y2="13.5256"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#41FFA4" />
                  <stop offset="1" stopColor="#53FFD6" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        )}
      </div>
    </ReactIconsContext.Provider>
  );
};

export default IconContext;
