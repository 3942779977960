import { AxiosError } from 'axios';
import { SubscriptionPlans } from 'src/interfaces/Subscription';
import { CaptureErrorType } from '../../types/utils/captureError';
import { captureError } from '../../utils';
import restApi from '../restApi';
import {
  GetSubscriptionDataResponse,
  GetSubscriptionDataResult,
  GetSubscriptionPlanResult,
} from './types';

export const getUserSubscriptionPrice = async (): Promise<GetSubscriptionDataResult> => {
  try {
    const { data } = await restApi.get<GetSubscriptionDataResponse>(`/api/latest/user/pricing`);

    return { data: data, success: true };
  } catch (error: unknown) {
    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};

export const getSubscriptionPlans = async (): Promise<GetSubscriptionPlanResult> => {
  try {
    const { data } = await restApi.get<SubscriptionPlans>('/api/latest/metering/subscriptions');

    return { data: data, success: true };
  } catch (error: unknown) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'getSubscriptionData',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
