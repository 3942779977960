import { BizcuitError } from 'src/tokens';

type getDialogErrorMessageProps = Pick<BizcuitError, 'title' | 'message' | 'errorSupport'> & {
  errorMessage: string;
  isTitleBlank: boolean;
  errorSupport: {
    title: string;
    description: string;
  };
};

const getDialogErrorMessage = ({
  title,
  message,
  errorMessage,
  isTitleBlank,
  errorSupport,
}: getDialogErrorMessageProps) => {
  const errorDialogueMessage: BizcuitError = {
    title,
    message,
    errorSupport,
    isErrorMessageWithBlankTitle: isTitleBlank,
  };

  if (errorMessage.includes('401')) {
    errorDialogueMessage.message = 'sessionHasExpiredMessage';
    if (errorDialogueMessage.errorSupport) {
      errorDialogueMessage.errorSupport.title = 'sessionHasExpiredErrorSupportTitle';
      errorDialogueMessage.errorSupport.description = 'sessionHasExpiredErrorSupportDescription';
    }
  }

  return errorDialogueMessage;
};

export { getDialogErrorMessage };
