import React, { FC } from 'react';
import KvkResultContainerProps from './KvkResultContainer.types';

const KvkResultContainer: FC<KvkResultContainerProps> = ({
  className = '',
  shadowClass,
  companyName,
  suffix,
  subText,
  cocNumber,
  onClick,
}: KvkResultContainerProps) => (
  <div
    data-testid="kvk-result"
    className={`
      flex justify-between 
      p-4 
      cursor-pointer 
      text-biz-gray-800 dark:text-biz-gray-200 hover:text-biz-orange-500 
      hover:bg-biz-gray-100 dark:bg-biz-gray-800  dark:hover:bg-biz-gray-600 
      ${shadowClass ? 'shadow-sm' : ''}
      ${className}
    `}
    onClick={onClick}
  >
    <div className="flex-grow">
      <div className="flex justify-between mb-1">
        <div className={`text-base font-weight-500 ${cocNumber ? 'w-3/6' : 'w-full'}`}>
          {companyName}
        </div>
        {cocNumber && (
          <div className="font-weight-400 text-xs-plus text-biz-gray-500 dark:text-biz-gray-200 self-center w-3/6 text-right">
            <span className="font-weight-500">KvK</span> {cocNumber}
          </div>
        )}
      </div>
      {subText && (
        <div className="text-xs-plus text-biz-gray-800 dark:text-biz-gray-200 font-weight-400">
          {subText}
        </div>
      )}
    </div>
    {suffix && suffix.element && (
      <div
        className="self-center flex-none ml-4"
        onClick={suffix?.onClick}
        data-testid="kvk-result-suffix"
      >
        {suffix.element}
      </div>
    )}
  </div>
);

export default KvkResultContainer;
