import { PartnerState } from 'src/contexts/partner-global-state/types';
import { getLocalStorageObject } from 'src/helpers';
import { DEFAULT_PARTNER_ID, LocalStorageKeys } from 'src/tokens';

const getPersistedPartnerState = () => {
  const persistedPartnerState = getLocalStorageObject<PartnerState>(LocalStorageKeys.partner);

  const partnerState: PartnerState = {
    partner: persistedPartnerState?.partner || null,
    partnerId: persistedPartnerState?.partnerId || DEFAULT_PARTNER_ID,
    partnerReference: persistedPartnerState?.partnerReference,
    brandingPartnerId: persistedPartnerState?.brandingPartnerId,
    partnerAuthorisation: persistedPartnerState?.partnerAuthorisation,
  };

  return partnerState;
};

export { getPersistedPartnerState };
