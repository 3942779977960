import useUserStore from './useUserStore';
import usePartnerStore from './usePartnerStore';
import { ConnectorDetails, TrustTermsAndConditions } from 'src/types/bizcuitApi';
import { useCallback, useMemo } from 'react';

const useUserShouldApproveTerms = () => {
  const {
    state: { user },
  } = useUserStore();
  const {
    state: { partner },
  } = usePartnerStore();

  const isInitialized = Boolean(user.partnerId) && Boolean(partner?.id);
  const isSubProcessorAgreement =
    partner?.trustTermsAndConditions === TrustTermsAndConditions.SubProcessorAgreement;

  const result = {
    bizcuit: useMemo(() => {
      if (!isInitialized) return undefined;
      return !user.termsApproved && !isSubProcessorAgreement;
    }, [isInitialized, isSubProcessorAgreement, user.termsApproved]),

    bizcuitPayments: useMemo(() => {
      if (!isInitialized) return undefined;
      return !user.termsPaymentsApproved && !isSubProcessorAgreement;
    }, [isInitialized, isSubProcessorAgreement, user.termsPaymentsApproved]),
  };

  const isTermsApprovalRequiredForBankConnector = useCallback(
    (bankConnector: ConnectorDetails) => {
      if (result.bizcuit) return true;

      return result.bizcuitPayments && bankConnector.connectorType === 'PSD2';
    },
    [result.bizcuit, result.bizcuitPayments],
  );

  return { ...result, isTermsApprovalRequiredForBankConnector };
};

export { useUserShouldApproveTerms };
