import { atom } from 'jotai';
import { appsAtom } from './appsAtom';

const defaultIcon = 'https://frontend-prd-v2.s3.eu-west-1.amazonaws.com/bizcuit-logo.svg';

const getAppIconUrlAtom = atom((get) => {
  return (appId: string) => {
    if (!appId) return defaultIcon;

    const apps = get(appsAtom);
    const app = apps.find((application) => application?.id === appId);
    if (app?.icon?.startsWith('https')) {
      return app.icon;
    }
    return defaultIcon;
  };
});

if (process.env.NODE_ENV !== 'production') {
  getAppIconUrlAtom.debugLabel = 'Get app by id atom';
}

export { getAppIconUrlAtom };
