import { atom } from 'jotai';
import { Result } from 'src/services/types';
import User from 'src/interfaces/User';
import { setUserSessionAtom } from './setUserSessionAtom';
import { authorizeInFlowEmbeddedUser } from 'src/services/user/authorize-in-flow-embedded-user/authorizeInFlowEmbeddedUser';

type AuthorizeEmbeddedUserResult = Result & { user?: Partial<User> };

const authorizeInFlowEmbeddedUserAtom = atom(
  null,
  async (_get, set, token: string, userFlowId: string): Promise<AuthorizeEmbeddedUserResult> => {
    const result = await authorizeInFlowEmbeddedUser(token, userFlowId);

    const { data, success } = result;
    let error = result.error;

    if (!data) return { success: false, error };

    const { user, userSession, accessToken, tokenExpired } = data;

    if (!user.active && user.deactivatedAt !== null) {
      return {
        success: false,
        error: { message: 'user not active', error_name: 'userNotActive' },
      };
    }

    if (success) set(setUserSessionAtom, user, userSession, accessToken);
    if (tokenExpired) error = { message: 'jwt expired', error_name: 'jwtExpired' };

    return { success, error, user };
  },
);

export { authorizeInFlowEmbeddedUserAtom };
