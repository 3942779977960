const str2ab = (str: string) => {
  const chars = [];
  for (let i = 0; i < str.length; i += 1) {
    chars.push(str.charCodeAt(i));
  }
  return new Uint8Array(chars);
};

const ab2str = (buf: ArrayBuffer) =>
  String.fromCharCode.apply(null, Array.from(new Uint8Array(buf)));

const ab2hex = (buf: ArrayBuffer) => {
  if (!buf) return null;

  const arrayBuffer = new Uint8Array(buf);
  const hexBytes = [];

  for (let i = 0; i < arrayBuffer.length; i += 1) {
    let byteString = arrayBuffer[i].toString(16);
    if (byteString.length < 2) byteString = `0${byteString}`;
    hexBytes.push(byteString);
  }
  return hexBytes.join('');
};

const hex2ab = (hex: string) => {
  if (hex.length % 2 !== 0) throw new Error('Invalid hexString');

  const arrayBuffer = new Uint8Array(hex.length / 2);

  for (let i = 0; i < hex.length; i += 2) {
    const byteValue = parseInt(hex.substr(i, 2), 16);
    if (Number.isNaN(byteValue)) throw new Error('Invalid hexString');
    arrayBuffer[i / 2] = byteValue;
  }

  return arrayBuffer;
};

export { str2ab, ab2str, ab2hex, hex2ab };
