import { Result } from 'src/services/types';
import { sendApproveTermsEmail } from 'src/services/user';
import { SendApproveTermsRequest } from 'src/services/user/send-user-approve-terms-email/types';

const sendUserApproveTermsEmail = async (payload: SendApproveTermsRequest): Promise<Result> => {
  const { success, error } = await sendApproveTermsEmail(payload);

  return { success, error };
};

export { sendUserApproveTermsEmail };
