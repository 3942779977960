import { AxiosError } from 'axios';
import restApi from 'src/services/restApi';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import { GetUserSessionResponse, GetUserSessionResult } from './types';

export const getUserSession = async (): Promise<GetUserSessionResult> => {
  try {
    const { data } = await restApi.get<GetUserSessionResponse>(`/api/latest/user/user-session`);

    const result: GetUserSessionResult = {
      data,
      success: true,
    };

    return result;
  } catch (error: unknown) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'getUserSession',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
