import { FlowLayout } from 'src/atoms/layout/menu-page';
import { useNavigate } from 'react-router';
import React, { useState } from 'react';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import AlertDialog from 'src/organisms/dialog/AlertDialog';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'src/atoms/buttons/tertiary-button/TertiaryButton';
import Paths from 'src/tokens/Paths';
import { getTranslation } from 'src/helpers';
import RadioContainer from 'src/atoms/inputs/radio-container/RadioContainer';

const UbosFromAnotherCompany = () => {
  const [showWhatIsUbo, setShowWhatIsUbo] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation(['cdd', 'common']);

  //TODO: Replace with actual data and ubo type
  const mockCompany = {
    company: 'Vereniging het Zonnetje B.V.',
    kvk: '60809442',
  };
  const mockCompaniesWithUbos = [
    {
      name: 'Meyer International B.V.',
      ubos: ['Hessel Kuik', 'Franz Ferdinand', 'Ludwig van Beethoven'],
    },
    {
      name: 'Coffee and code',
      ubos: ['Hessel Kuik', 'Franz Ferdinand', 'Ludwig van Beethoven'],
    },
  ];
  const uboTranslation = 'pseudoUbo';

  const handleRefuse = () => {
    navigate(
      `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.ubosFromDirector}`,
    );
  };
  const handleProceed = () => {
    navigate(
      `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.ubosInProgress}`,
    );
  };

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex p-4 items-center w-full flex-wrap flex-col">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          <SecondaryButton underline onClick={() => handleRefuse()}>
            {t('common:button.noThankYou')}
          </SecondaryButton>
          <PrimaryButton
            className="md:w-80"
            onClick={() => handleProceed()}
            isDisabled={!selectedCompany}
          >
            {t('common:button.proceed')}
          </PrimaryButton>
        </footer>
      }
    >
      <>
        <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
          {t(`cdd:ubosFromAnotherCompany.${uboTranslation}.primaryHeader`)}
        </h1>
        <div className="flex flex-col">
          <p className="text-medium-loose mb-2">
            {getTranslation(`cdd:ubosFromAnotherCompany.${uboTranslation}.description`)}
          </p>
          <TertiaryButton
            className="mb-6"
            color="gray-800"
            underline
            onClick={() => setShowWhatIsUbo(true)}
          >
            {t(`cdd:whatIsUboDialog.${uboTranslation}.title`)}
          </TertiaryButton>
          <div className="flex p-4 justify-between mb-6">
            <p className="text-medium font-weight-500">{mockCompany.company}</p>
            <div className="flex gap-1 text-x-small items-center">
              <p className="font-weight-500">{t('common:chamberOfCommerceShortHand')}</p>
              <p>{mockCompany.kvk}</p>
            </div>
          </div>
          <p className="text-x-small font-weight-500 text-biz-gray-600 dark:text-white mb-2">
            {t('common:companies')}
          </p>
          <div className="grid md:grid-cols-2 gap-2">
            {mockCompaniesWithUbos.map((company, index) => (
              <RadioContainer
                key={index}
                value={company.name}
                name={company.name}
                onSelected={() => setSelectedCompany(company.name)}
                isChecked={selectedCompany === company.name}
              >
                <div className="flex flex-col gap-1">
                  <p className="text-small font-weight-500">{company.name}</p>
                  {company.ubos.map((ubo, index) => (
                    <p className="text-x-small" key={index}>
                      {ubo}
                    </p>
                  ))}
                </div>
              </RadioContainer>
            ))}
          </div>
        </div>
      </>
      <AlertDialog
        isVisible={showWhatIsUbo}
        onClose={() => setShowWhatIsUbo(!showWhatIsUbo)}
        alertDialogTexts={t(`cdd:whatIsUboDialog.${uboTranslation}`, { returnObjects: true })}
        showContactSupport
      />
    </FlowLayout>
  );
};

export default UbosFromAnotherCompany;
