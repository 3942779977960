enum CookieKeys {
  accessToken = 'accessToken',
  partnerAuthorisation = 'partnerAuthorisation',
  loginTo = 'loginTo',
  redirectUrl = 'redirectUrl',
  // V1 compatibility:
  appLocation = 'appLocation',
  client = 'client',
}

export default CookieKeys;
