import { atom } from 'jotai';
import { partnerAuthorisationAtom } from '../partner-state-atoms';
import { PartnerScopes } from 'src/types/bizcuitApi';

const areScopesInPartnerAuthorization = atom(null, (_get, set, scopeList: PartnerScopes[]) => {
  const partnerAuthorisation = _get(partnerAuthorisationAtom);
  let isScopesRequested = false;
  if (partnerAuthorisation) {
    const { scopes } = partnerAuthorisation.consent;

    isScopesRequested = scopeList.every((scope) => scopes.includes(scope));
  }
  return isScopesRequested;
});

export { areScopesInPartnerAuthorization };
