import { Outlet, useNavigate } from 'react-router-dom';
import { Paths } from 'src/tokens';
import { useUserStore } from 'src/hooks';
import { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import { orchestratorAtom } from 'src/contexts/orchestrator-global-state/orchestrator-atoms/orchestrator-global-atoms';

const EmbeddedUserAccessGuard = () => {
  const navigate = useNavigate();
  const [orchestratorState] = useAtom(orchestratorAtom);

  const {
    state: { user },
  } = useUserStore();

  const getOverviewUrl = (flowId: string) => {
    return `${Paths.orchestrator.root}${Paths.orchestrator.segments.overview}/${flowId}`.replace(
      ':flowId',
      flowId,
    );
  };

  useEffect(() => {
    if (!user) return;

    if (user.embeddedUser) {
      const flowId = orchestratorState?.flow?.id;
      if (flowId) navigate(getOverviewUrl(flowId));
    }
  }, [navigate, user, orchestratorState?.flow?.id]);

  return <Outlet />;
};

export default EmbeddedUserAccessGuard;
