import { FC, ReactNode } from 'react';

interface PageLayoutProps {
  children: ReactNode;
}

const PageLayout: FC<PageLayoutProps> = ({ children }) => (
  <div className="flex flex-col relative h-screen w-full mx-auto overflow-hidden sm:bg-biz-off-white dark:bg-biz-black lg:ml-0 lg:mr-auto">
    {children}
  </div>
);

export default PageLayout;
