import { RequestStatus } from 'src/types/bizcuitApi';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';

const getRequestStatusIconMapBySize = (size = 28) => ({
  [RequestStatus.Available]: <BizcuitIcon icon={'hourglass'} size={size} />,
  [RequestStatus.Declined]: <BizcuitIcon icon={'fi-alert-triangle'} size={size} />,
  [RequestStatus.Revoked]: <BizcuitIcon icon={'fi-alert-triangle'} size={size} />,
  [RequestStatus.Completed]: (
    <BizcuitIcon icon={'fi-check-circle'} size={size} className="text-biz-green-500" />
  ),
  [RequestStatus.Waiting]: <BizcuitIcon icon={'fi-alert-triangle'} size={size} />,
  [RequestStatus.Expired]: <BizcuitIcon icon={'fi-alert-triangle'} size={size} />,
});

export default getRequestStatusIconMapBySize;
