import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import NavigationContainer from 'src/atoms/layout/navigation-container/NavigationContainer';

// TODO: this will be removed as part of: https://minoxsoftware.atlassian.net/browse/BR-250
const TempExampleScreen: FC = () => {
  const { param1 = '' } = useParams();
  const navigate = useNavigate();

  const isStepPage = ['example-verify-email', 'example-verify-phone'].includes(param1);

  const handleButtonClick = () => {
    navigate(`/o/finish-action/${param1}`);
  };

  return (
    <FlowLayout pageHeaderText="Orchestrator Example" showBackButton={false} spaceBetween>
      <h1 className="heading-100 text-warm-grey-800 dark:text-grey-100">{param1}</h1>
      {isStepPage && (
        <NavigationContainer>
          <PrimaryButton onClick={handleButtonClick} isDisabled={false} showSpinner={false}>
            Continue
          </PrimaryButton>
        </NavigationContainer>
      )}
    </FlowLayout>
  );
};

export default TempExampleScreen;
