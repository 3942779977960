import { getDeviceIdentifier } from 'src/helpers';
import { EnrichedSession } from 'src/interfaces/Session';
import { ab2hex, ab2str, hex2ab, str2ab } from './arrayConvertors';
import { generateDerivedKey, generateDeviceFingerprint } from './deviceUtil';
import { BindSessionKeys } from 'src/tokens';

const pkcs4096 = {
  name: 'RSASSA-PKCS1-v1_5',
  modulusLength: 4096,
  publicExponent: new Uint8Array([1, 0, 1]),
  hash: 'SHA-256',
};

const encryptSessionId = async (sessionId: string, key: CryptoKey, iv: Uint8Array) => {
  const encryptedSessionId = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    str2ab(sessionId),
  );

  return ab2hex(encryptedSessionId);
};

const decryptSessionId = async (encryptedSessionId: string, key: CryptoKey, iv: Uint8Array) =>
  ab2str(await crypto.subtle.decrypt({ name: 'AES-GCM', iv }, key, hex2ab(encryptedSessionId)));

const generateKeyPair = async (): Promise<CryptoKeyPair> => {
  return crypto.subtle.generateKey(pkcs4096, true, ['sign', 'verify']);
};

const unwrapKey = async (encryptedKey: Uint8Array, derivedKey: CryptoKey, iv: Uint8Array) =>
  await crypto.subtle.unwrapKey(
    'pkcs8',
    encryptedKey,
    derivedKey,
    { name: 'AES-GCM', iv },
    pkcs4096,
    false,
    ['sign'],
  );

const decryptSession = async (pin: string): Promise<EnrichedSession> => {
  const iv = str2ab(localStorage.getItem(BindSessionKeys.userRandom) || '');
  const encryptedPrivateKey = localStorage.getItem(BindSessionKeys.encryptedPrivateKey);
  const encryptedSessionId = localStorage.getItem(BindSessionKeys.encryptedSessionId);
  const derivedKey = await generateDerivedKey(pin, iv, '');

  if (!iv || !encryptedPrivateKey || !encryptedSessionId || !derivedKey) {
    throw Error('Invalid session.');
  }

  const [sessionId, key] = await Promise.all([
    decryptSessionId(encryptedSessionId, derivedKey, iv),
    unwrapKey(hex2ab(encryptedPrivateKey), derivedKey, iv),
  ]);

  const date = new Date().toISOString();
  const nonce = ab2hex(crypto.getRandomValues(new Uint8Array(16)));
  const signature = await crypto.subtle.sign(
    'RSASSA-PKCS1-v1_5',
    key,
    str2ab(`${date}${sessionId}${nonce}`),
  );

  return {
    id: sessionId,
    signature: window.btoa(ab2str(signature)),
    date,
    nonce,
  };
};

const validateSession = async () => {
  const random = localStorage.getItem(BindSessionKeys.userRandom);
  const encryptedPrivateKey = localStorage.getItem(BindSessionKeys.encryptedPrivateKey);
  const encryptedSessionId = localStorage.getItem(BindSessionKeys.encryptedSessionId);
  const storedFingerprintHash = localStorage.getItem(BindSessionKeys.fingerprintHash);

  if (!random || !encryptedPrivateKey || !encryptedSessionId) return false;

  const randomAb = hex2ab(random);
  const deviceName = (await getDeviceIdentifier()) || '';
  const { fingerprintHash } = await generateDeviceFingerprint(randomAb, deviceName);

  const isSessionValid = fingerprintHash === storedFingerprintHash;
  return isSessionValid;
};

export { encryptSessionId, decryptSessionId, generateKeyPair, decryptSession, validateSession };
