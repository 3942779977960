import { Provider } from 'jotai';
import { ReactNode, useEffect } from 'react';
import { setPersistedPartnerState } from 'src/contexts/partner-global-state/partner-atoms';
import { globalStore } from '../globalStore';

import {
  partnerAtom,
  partnerIdAtom,
  partnerReferenceAtom,
  brandingPartnerIdAtom,
  partnerAuthorisationAtom,
} from './partner-atoms';

const PartnerProvider = ({
  children,
  store = globalStore,
}: {
  children: ReactNode;
  store?: typeof globalStore;
}) => {
  useEffect(() => {
    setPersistedPartnerState({
      partner: store.get(partnerAtom),
      partnerId: store.get(partnerIdAtom),
      partnerReference: store.get(partnerReferenceAtom),
      brandingPartnerId: store.get(brandingPartnerIdAtom),
      partnerAuthorisation: store.get(partnerAuthorisationAtom),
    });
  }, [store]);
  return <Provider store={store}>{children}</Provider>;
};

export { PartnerProvider };
