import { atom } from 'jotai';
import { globalStore } from 'src/contexts/globalStore';
import { getPersistedUserState, setPersistedUserState } from '../user-actions-atoms';
import User from 'src/interfaces/User';

const userAtom = atom<User>(getPersistedUserState().user);

globalStore.sub(userAtom, () => {
  setPersistedUserState({
    ...getPersistedUserState(),
    user: globalStore.get(userAtom),
  });
});

if (process.env.NODE_ENV !== 'production') {
  userAtom.debugLabel = 'User';
}

export { userAtom };
