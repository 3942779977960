import { useState } from 'react';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import ButtonProps from 'src/atoms/buttons/primary-button/PrimaryButton.types';
import { delay, logger } from 'src/utils';

type CopyToClipboardButtonProps = ButtonProps & {
  clipboardText: string;
  copyActionText: string;
  copySuccessText: string;
  successDisplayTimeMs?: number;
};

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
  const [buttonText, setButtonText] = useState(props.copyActionText);
  const [disabled, setDisabled] = useState(false);
  const { clipboardText, copyActionText, copySuccessText, successDisplayTimeMs, ...buttonProps } =
    props;

  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(clipboardText);
      setDisabled(true);
      setButtonText(copySuccessText);
      await delay(successDisplayTimeMs || 3000);
      setButtonText(copyActionText);
      setDisabled(false);
    } catch (error) {
      logger.error('Error copying to clipboard', error);
    }
  };

  return (
    <PrimaryButton onClick={copyCode} {...buttonProps} isDisabled={disabled}>
      {buttonText}
    </PrimaryButton>
  );
};

export default CopyToClipboardButton;
