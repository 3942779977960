import { FlowLayout } from 'src/atoms/layout/menu-page';
import { useNavigate } from 'react-router';
import React, { useState } from 'react';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import AlertDialog from 'src/organisms/dialog/AlertDialog';
import AddButton from 'src/atoms/buttons/add-button/AddButton';
import Label from 'src/atoms/text/label/Label';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import Container from 'src/atoms/layout/container/Container';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'src/atoms/buttons/tertiary-button/TertiaryButton';
import Paths from 'src/tokens/Paths';

const UbosInProgress = () => {
  const [showAlert, setShowAlert] = useState({
    whatIsUbo: false,
    nobodyHasMoreThan25Percent: false,
    deleteUbo: false,
  });
  const navigate = useNavigate();
  const { t } = useTranslation(['cdd', 'common']);

  //TODO: Replace with actual data and ubo type
  const mockCompany = {
    company: 'Vereniging het Zonnetje B.V.',
    kvk: '60809442',
  };
  const mockUbos = [
    {
      name: 'Hessel Kuik',
      status: 'Complete',
      actualControl: false,
      ownershipPercentage: 20,
      votingRightPercentage: 20,
    },
    {
      name: 'John Johnson Junior',
      status: 'Complete',
      actualControl: false,
      ownershipPercentage: 20,
      votingRightPercentage: 20,
    },
    {
      name: 'Maritius Aurelius',
      status: 'Complete',
      actualControl: false,
      ownershipPercentage: 20,
      votingRightPercentage: 20,
    },
  ];
  const uboTranslation = 'pseudoUbo';
  const mockUboTotal = 120 > 100;

  const handleAdd = () => {
    navigate(
      `${Paths.ais.root}${Paths.ais.segments.cdd.root}${Paths.ais.segments.cdd.segments.uboform}`,
    );
  };
  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex pb-6 pt-4 items-center w-full flex-wrap flex-col">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          <SecondaryButton
            underline
            onClick={() =>
              setShowAlert((prevState) => ({ ...prevState, nobodyHasMoreThan25Percent: true }))
            }
          >
            {t(`cdd:ubosInProgress.${uboTranslation}.secondaryButton`)}
          </SecondaryButton>
          <PrimaryButton className="md:w-80">
            {t(`cdd:ubosInProgress.${uboTranslation}.primaryButton`)}
          </PrimaryButton>
          <p className="text-small-loose md:text-x-small-loose text-center text-primary-900 dark:text-white mt-1">
            {mockUboTotal
              ? t('cdd:ubosInProgress.ubo.uboTotalExceeds')
              : t('cdd:ubosInProgress.ubo.uboWarning')}
          </p>
        </footer>
      }
    >
      <>
        <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
          {t(`cdd:ubosInProgress.${uboTranslation}.primaryHeader`)}
        </h1>
        <div className="flex flex-col">
          <TertiaryButton
            className="mb-6"
            color="gray-800"
            underline
            onClick={() => setShowAlert((prevState) => ({ ...prevState, whatIsUbo: true }))}
          >
            {t(`cdd:whatIsUboDialog.${uboTranslation}.title`)}
          </TertiaryButton>
          <div className="flex p-4 justify-between mb-6">
            <p className="text-medium font-weight-500">{mockCompany.company}</p>
            <div className="flex gap-1 text-x-small items-center">
              <p className="font-weight-500">{t('common:chamberOfCommerceShortHand')}</p>
              <p>{mockCompany.kvk}</p>
            </div>
          </div>
          <AddButton className="self-start w-auto mb-6" onClick={() => handleAdd()}>
            {t(`cdd:ubosInProgress.${uboTranslation}.addUbo`)}
          </AddButton>
          <p className="text-x-small font-weight-500 text-biz-gray-600 dark:text-white mb-2">
            {t(`cdd:ubos.${uboTranslation}`)}
          </p>
          <div className="flex flex-col gap-2">
            {mockUbos.map((ubo, index) => (
              <div className="flex items-center" key={index}>
                <Container
                  className="flex items-center justify-between flex-grow"
                  roundedClass="rounded-2xl"
                >
                  <p className="text-small font-weight-500 text-gray-800 dark:text-white truncate">
                    {ubo.name}
                  </p>
                  <Label className="text-nowrap ml-4">{t('common:complete')}</Label>
                </Container>
                <div
                  className="cursor-pointer ml-4"
                  onClick={() => {
                    setShowAlert((prevState) => ({ ...prevState, deleteUbo: true }));
                  }}
                >
                  <BizcuitIcon
                    icon="trash"
                    className="text-warm-grey-800 dark:text-white"
                    size={24}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
      <AlertDialog
        isVisible={showAlert.whatIsUbo}
        onClose={() =>
          setShowAlert((prevstate) => ({ ...prevstate, whatIsUbo: !showAlert.whatIsUbo }))
        }
        alertDialogTexts={t(`cdd:whatIsUboDialog.${uboTranslation}`, { returnObjects: true })}
      />
      <AlertDialog
        isVisible={showAlert.deleteUbo}
        onClose={() =>
          setShowAlert((prevstate) => ({
            ...prevstate,
            deleteUbo: !showAlert.deleteUbo,
          }))
        }
        alertDialogTexts={t('cdd:deleteUboDialog', { returnObjects: true })}
      />
      <AlertDialog
        isVisible={showAlert.nobodyHasMoreThan25Percent}
        onClose={() =>
          setShowAlert((prevState) => ({ ...prevState, nobodyHasMoreThan25Percent: false }))
        }
        alertDialogTexts={t('cdd:processOverview.alertDialog', { returnObjects: true })}
        primaryColorContinueButton
      />
    </FlowLayout>
  );
};

export default UbosInProgress;
