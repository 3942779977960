import { Provider } from 'jotai';
import { ReactNode, useEffect } from 'react';
import { appsAtom, setPersistedAppsState } from './apps-atoms';
import { globalStore } from '../globalStore';

const AppsProvider = ({
  children,
  store = globalStore,
}: {
  children: ReactNode;
  store?: typeof globalStore;
}) => {
  useEffect(() => {
    setPersistedAppsState({
      apps: store.get(appsAtom),
    });
  }, [store]);

  return <Provider store={store}>{children}</Provider>;
};

export { AppsProvider };
