export const v1MiniApps = {
  home: 'home',
  balance: 'balance',
  documents: 'documents',
  payments: 'payments',
  scan: 'scan',
  settings: 'settings',
  contacts: 'contacts',
  euro: 'euro',
  apps: 'apps',
  dynamic_feed: 'dynamic_feed',
  check_circle: 'check_circle',
};

export const v2MiniApps = {
  hix: 'hix',
  raboInvoiceFinancing: 'rabo-invoice-financing',
};

export const miniApps = {
  ...v1MiniApps,
  ...v2MiniApps,
};

export const defaultAppIds: string[] = [
  miniApps.home,
  miniApps.balance,
  miniApps.documents,
  miniApps.payments,
  miniApps.scan,
  miniApps.settings,
  miniApps.contacts,
  miniApps.raboInvoiceFinancing,
  miniApps.payments,
  miniApps.euro,
  miniApps.dynamic_feed,
  miniApps.check_circle,
];

export const bzV1AppBaseUrl = `${process.env.REACT_APP_LEGACY_APP_URL}/app`;
