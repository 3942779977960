import { Partner, User } from './bizcuitApi';

export type UserAnalytics = {
  id?: string;
  partnerId?: string;
  betaTester?: boolean;
  active?: boolean;
  partnerReference?: string;
  partnerName?: string;
  $name?: string;
  $first_name?: string;
  $last_name?: string;
  $email?: string;
  createdThrough?: string;
};

export type UserTraits = Omit<UserAnalytics, 'id'> & { userId: string };

export type TrackUserIdentityArgs = {
  user: Partial<User>;
  partner?: Partial<Partner>;
};

export enum EventNames {
  buttonClicked = 'Button Clicked',
  pinEntered = 'Pin Entered',
  deviceBound = 'Device Bound',
  deviceBindFailed = 'Device Bind Failed',
  ibanEntered = 'IBAN Entered',
  emailEntered = 'Email Entered',
  emailEnteredFailed = 'Email Entered Failed',
  nameEntered = 'Name Entered',
  nameEnteredFailed = 'Name Entered Failed',
  subscriptionSelected = 'Subscription Selected',
  accountCreated = 'Account Created',
  accountVerified = 'Account Verified',
  accountVerifiedFailed = 'Account Verified Failed',
  linkOpened = 'Link Opened',
  slideViewed = 'Slide Viewed',
  signedUpInactive = 'Signed Up Inactive',
  signUpResumed = 'Sign Up Resumed',
  loggedIn = 'Logged In',
  startFlow = 'Start Flow',
  signedOut = 'Signed out',
  viewedLandingPage = 'Viewed /',
  invoiceFinanceWidgetStart = 'InvoiceFinanceWidgetStart',
  invoiceFinanceWidgetAbandon = 'InvoiceFinanceWidgetAbandon',
  invoiceFinanceWidgetSuccess = 'InvoiceFinanceWidgetSuccess',
  invoiceFinanceWidgetError = 'InvoiceFinanceWidgetError',
  invoiceFinanceWidgetExit = 'InvoiceFinanceWidgetExit',
}
