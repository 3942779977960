// All values for field `bank_accounts.bank`
export enum BanksEnum {
  ABN = 'abn',
  ASN = 'asn',
  BUNQ = 'bunq',
  BUNQ_SHARE = 'bunq_share',
  ING = 'ing',
  ING_WB = 'ing_wb',
  KNAB = 'knab',
  ONELINQ = 'onelinq', // NOT A BANK
  OPENAPI = 'openapi', // NOT A BANK
  RABO = 'rabo',
  RABO_COMMERCIAL = 'rabo_commercial',
  RABO_RDC = 'rabo_rdc',
  REGIOBANK = 'regio',
  SNS = 'sns',
  TRIODOS = 'triodos',
}

export const acceptedFundrBanks: string[] = [
  BanksEnum.ABN,
  BanksEnum.ING,
  BanksEnum.ING_WB,
  BanksEnum.RABO,
  BanksEnum.RABO_COMMERCIAL,
  BanksEnum.KNAB,
  BanksEnum.BUNQ,
  BanksEnum.BUNQ_SHARE,
  BanksEnum.REGIOBANK,
  BanksEnum.ASN,
  BanksEnum.SNS,
  BanksEnum.TRIODOS,
];

export const raboInvoiceFinanceBanks = [...acceptedFundrBanks, BanksEnum.RABO_RDC];
