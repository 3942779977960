export enum GeneralScopeCombinations {
  OpenidEmailAndProfile = 'openid-email-profile',
}

export enum BankAccountsCombination {
  ManageAccountsOnly,
  AccountInformationOnly,
  PaymentsOnly,
  AccountManageAndInformation,
  AccountManageAndPayments,
  AccountInformationAndPayments,
  AccountManageInformationAndPayments,
}
