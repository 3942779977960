import { atom } from 'jotai';
import { userAtom } from '../user-state-atoms';
import User from 'src/interfaces/User';

const setUserValuesAtom = atom(null, (_get, set, update: Partial<User>) => {
  const prevUser = _get(userAtom);
  if (!update.id) {
    update.id = prevUser.id;
  }

  set(userAtom, { ...prevUser, ...update });
});

export { setUserValuesAtom };
