import { CustomIconProps } from '../icons.types';

const Loading = ({ widthClassName = 'w-32', heightClassName = 'h-32' }: CustomIconProps) => (
  <div className={`bg-gradient-to-b rounded-full relative ${widthClassName} ${heightClassName}`}>
    <svg
      viewBox="0 0 130 130"
      fill="none"
      className={`animate-spin absolute ${widthClassName} ${heightClassName}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.9589 62.0692V64.5038C91.9557 70.2104 90.1078 75.763 86.691 80.3336C83.2742 84.9042 78.4714 88.2478 72.9991 89.8658C67.5267 91.4838 61.6779 91.2895 56.325 89.3119C50.972 87.3342 46.4018 83.6792 43.2958 78.892C40.1899 74.1047 38.7146 68.4417 39.0901 62.7475C39.4656 57.0533 41.6716 51.633 45.3793 47.295C49.087 42.9571 54.0976 39.9338 59.6639 38.6762"
        stroke="#003A1E"
        strokeWidth="12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

export default Loading;
