import { SVGProps } from 'react';

const SvgSpinner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="animate-spin"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.508 2.74a2.267 2.267 0 0 1-1.712 2.712 7.733 7.733 0 1 0 9.437 7.547v-.92a2.267 2.267 0 1 1 4.534 0v.922A12.267 12.267 0 1 1 9.797 1.03a2.267 2.267 0 0 1 2.71 1.711Z"
      fill={props.fill ?? '#003A1E'}
    />
  </svg>
);

export default SvgSpinner;
