import { atom } from 'jotai';
import { invoiceStateAtom } from '../invoice-state-atoms';
import { FundrApiState } from 'src/types/fundrApi';

const setFundrApiStateAtom = atom(null, (_get, set, fundrApiState: FundrApiState) => {
  if (fundrApiState.validationError && fundrApiState.validationError.code === 'session_expired') {
    console.warn(fundrApiState.validationError.code);
    fundrApiState.validationError = null;
    fundrApiState.accessToken = '';
  }
  set(invoiceStateAtom, {
    ..._get(invoiceStateAtom),
    fundrApiState: { ..._get(invoiceStateAtom).fundrApiState, ...fundrApiState },
  });
});

export { setFundrApiStateAtom };
