import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import PrimaryHeader from 'src/atoms/text/primary-header/PrimaryHeader';
import TextInput from 'src/atoms/inputs/text-input/TextInput';
import * as Sentry from '@sentry/react';
import { getLocalStorageObject, setLocalStorageObject } from 'src/helpers';
import { EmailsSent, PERSISTENT_EMAIL_KEY } from 'src/tokens';
import { useSendForm } from 'src/types/bizcuitApi';
import { useErrorDialog, useUserStore } from 'src/hooks';

const ProvideKvk = () => {
  const [sendForm, { loading, error }] = useSendForm();
  const errorDialog = useErrorDialog();
  const { t } = useTranslation(['cdd', 'common']);
  const [formData, setFormData] = useState({ kvkNumber: '', note: '' });
  const emailsSent = getLocalStorageObject<EmailsSent>(PERSISTENT_EMAIL_KEY);
  const {
    state: { user },
  } = useUserStore();

  //TODO replace with real data
  const mockCompany = 'Vereniging het Zonnetje';

  const handleConfirm = async () => {
    const { data } = await sendForm({
      variables: {
        fields: JSON.stringify({
          kvkNumber: formData.kvkNumber,
          ...(formData.note && {
            note: formData.note,
          }),
        }),
      },
    });

    if (!data?.sendForm || error) {
      Sentry.captureException('Manually provide kvk failed');
      return errorDialog.open();
    }

    if (data?.sendForm) {
      setLocalStorageObject(PERSISTENT_EMAIL_KEY, {
        ...emailsSent,
        [user.id]: { [`kvk_provided`]: true, ...emailsSent?.[user.id] },
      });
    }
  };

  const description = t('cdd:kvkNotListed.description', { returnObjects: true });

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex justify-center pb-6">
          <PrimaryButton
            className="md:w-80"
            showSpinner={loading}
            type="submit"
            form="provide-kvk"
            isDisabled={!formData.kvkNumber}
          >
            {t('common:support.sendToSupport')}
          </PrimaryButton>
        </footer>
      }
    >
      <>
        <PrimaryHeader className="text-warm-grey-800 dark:text-white">
          {t('cdd:kvkNotListed.primaryHeader')}
        </PrimaryHeader>
        <div className="flex flex-col gap-6">
          {description.map((desc, index) => (
            <p key={index} className="text-medium-loose">
              {desc}
            </p>
          ))}
          <p className="text-medium font-weight-500 text-grey-900 dark:text-white">{mockCompany}</p>
          <form
            className="flex flex-col gap-6"
            autoComplete="off"
            id="provide-kvk"
            onSubmit={(e) => {
              e.preventDefault();
              handleConfirm();
            }}
          >
            <TextInput
              placeHolder={t('common:typeChamberOfCommerceNumber')}
              label={t('common:chamberOfCommerceNumber')}
              name="kvkNumber"
              onChange={(value) => setFormData((prevState) => ({ ...prevState, kvkNumber: value }))}
              value={formData.kvkNumber}
            />
            <TextInput
              placeHolder={t('common:typeComment')}
              label={t('common:comment')}
              name="comment"
              onChange={(value) => setFormData((prevState) => ({ ...prevState, note: value }))}
              value={formData.note}
            />
          </form>
        </div>
      </>
    </FlowLayout>
  );
};

export default ProvideKvk;
