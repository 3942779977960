import { setLocalStorageObject } from 'src/helpers';
import { CookieKeys, LocalStorageKeys } from 'src/tokens';
import { PartnerState } from 'src/contexts/partner-global-state/types';
import { setCookie } from 'src/utils';

const setPersistedPartnerState = (state?: PartnerState) => {
  if (!state) return;

  setLocalStorageObject(LocalStorageKeys.partner, state);

  const { partnerAuthorisation } = state;

  if (partnerAuthorisation) {
    const { consent } = partnerAuthorisation;

    const cookiePartnerAuth = {
      ...partnerAuthorisation,
      consent: {
        ...consent,
        bank_accounts: consent.bankAccounts,
      },
    };

    setCookie(CookieKeys.partnerAuthorisation, JSON.stringify(cookiePartnerAuth));
  }
};

export { setPersistedPartnerState };
