import { FlowLayout } from 'src/atoms/layout/menu-page';
import React, { useState } from 'react';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import AlertDialog from 'src/organisms/dialog/AlertDialog';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'src/atoms/buttons/tertiary-button/TertiaryButton';
import TextInput from 'src/atoms/inputs/text-input/TextInput';
import Checkbox from 'src/atoms/inputs/checkbox/Checkbox';
import DateInput from 'src/atoms/inputs/date-input/DateInput';
import Select from 'src/atoms/inputs/select/Select';
import { isDate } from 'date-fns';

const UboForm = () => {
  const [showAlert, setShowAlert] = useState({
    whatIsUbo: false,
    nobodyHasMoreThan25Percent: false,
    deleteUbo: false,
  });
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    dateOfBirth: '',
    nationality: '',
    livingIn: '',
    actualControl: false,
    ownershipPercentage: '',
    votingRightPercentage: '',
  });

  const [birthDate, setBirthDate] = useState('');
  const [birthDateValid, setBirthDateValid] = useState(isDate(birthDate));

  const { t } = useTranslation(['cdd', 'common']);

  //TODO: Replace with actual data and ubo type
  const mockCompany = {
    company: 'Vereniging het Zonnetje B.V.',
    kvk: '60809442',
  };
  const uboType = 'ubo';

  const handleSubmit = () => {
    console.log(formData);
  };

  const isDisabled = () => {
    const valuesNotSet = Object.values(formData).some((value) => value === '');
    const percentagesBelow25 =
      parseFloat(formData.ownershipPercentage) < 25 ||
      parseFloat(formData.votingRightPercentage) < 25;

    return valuesNotSet || percentagesBelow25;
  };

  return (
    <FlowLayout
      pageHeaderText={t('cdd:shortTitle')}
      spaceBetween={false}
      footer={
        <footer className="flex py-6 items-center w-full flex-wrap flex-col gap-1">
          <hr className="absolute top-0 bg-gradient-to-b from-transparent to-primary-100 dark:to-primary-800 dark:opacity-40 h-4 w-full -mt-4 -ml-4 dark:h-2 dark:-mt-2 md:ml-0 border-none" />
          {uboType === 'ubo' && (
            <p className="text-x-small-loose text-center text-grey-500">
              {t('cdd:uboForm.uboRequirements')}
            </p>
          )}
          <PrimaryButton
            className="md:w-80"
            type="submit"
            form="ubo-form"
            isDisabled={isDisabled()}
          >
            {t(`cdd:uboForm.primaryButton`)}
          </PrimaryButton>
        </footer>
      }
    >
      <>
        <h1 className="heading-100 my-6 text-warm-grey-800 dark:text-grey-100">
          {t(`cdd:uboForm.${uboType}.primaryHeader`)}
        </h1>
        <div className="flex mb-6 p-4 justify-between">
          <p className="text-medium font-weight-500">{mockCompany.company}</p>
          <div className="flex gap-1 text-x-small items-center">
            <p className="font-weight-500">{t('common:chamberOfCommerceShortHand')}</p>
            <p>{mockCompany.kvk}</p>
          </div>
        </div>
        <div className="flex flex-col">
          <TertiaryButton
            className="mb-4"
            color="gray-800"
            underline
            onClick={() => setShowAlert((prevState) => ({ ...prevState, whatIsUbo: true }))}
          >
            {t('common:helpNeededFillingOut')}
          </TertiaryButton>
          <form
            id="ubo-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="grid md:grid-cols-2 gap-4 md:gap-2">
              <TextInput
                label={t('common:name')}
                placeHolder={t('common:typeName')}
                name="name"
                value={formData.name}
                onChange={(value) => {
                  setFormData((prevState) => ({ ...prevState, name: value }));
                }}
              />
              <TextInput
                label={t('common:surname')}
                placeHolder={t('common:typeSurname')}
                name="surname"
                value={formData.surname}
                onChange={(value) => {
                  setFormData((prevState) => ({ ...prevState, surname: value }));
                }}
              />
              <DateInput
                name="dateOfBirth"
                label={t('common:dateOfBirth')}
                showError={birthDateValid}
                onValidated={setBirthDateValid}
                onChange={setBirthDate}
              />
            </div>
            <div className="flex flex-col mt-4 gap-4 md:gap-2">
              <Select
                name="nationality"
                label={t('common:nationality')}
                placeHolder=""
                options={[
                  { label: 'Nederland', value: 'NL' },
                  { label: 'Belgie', value: 'BE' },
                  { label: 'Duitsland', value: 'DE' },
                ]}
                onChange={(value) =>
                  setFormData((prevState) => ({ ...prevState, nationality: value }))
                }
              />
              <Select
                name="living-in"
                label={t('common:livingIn')}
                placeHolder=""
                options={[
                  { label: 'Nederland', value: 'NL' },
                  { label: 'Belgie', value: 'BE' },
                  { label: 'Duitsland', value: 'DE' },
                ]}
                onChange={(value) =>
                  setFormData((prevState) => ({ ...prevState, livingIn: value }))
                }
              />
            </div>
            <div className="border border-gray-20 dark:border-gray-800 my-8" />
            <div>
              {uboType === 'ubo' && (
                <div className="flex flex-col gap-4">
                  <p className="text-medium-loose text-gray-800 dark:text-white">
                    {t('cdd:uboForm.fillInWhatsApplicable')}
                  </p>
                  <div className="flex gap-4">
                    <Checkbox
                      name="actualControl"
                      onChecked={() =>
                        setFormData((prevState) => ({ ...prevState, actualControl: true }))
                      }
                      onUnchecked={() =>
                        setFormData((prevState) => ({ ...prevState, actualControl: false }))
                      }
                    />
                    <p className="text-medium text-gray-800 dark:text-white">
                      {t('cdd:uboForm.personHasActualControl')}
                    </p>
                  </div>
                  <div className="grid md:grid-cols-2 gap-4 md:gap-2">
                    <TextInput
                      placeHolder={t('cdd:uboForm.ownershipPercentage')}
                      label={t('cdd:uboForm.ownershipPercentage')}
                      name="ownershipPercentage"
                      type="number"
                      value={formData.ownershipPercentage}
                      onChange={(value) =>
                        setFormData((prevState) => ({ ...prevState, ownershipPercentage: value }))
                      }
                    />
                    <TextInput
                      placeHolder={t('cdd:uboForm.votingRightsPercentage')}
                      label={t('cdd:uboForm.votingRightsPercentage')}
                      name="votingRightPercentage"
                      type="number"
                      value={formData.votingRightPercentage}
                      onChange={(value) =>
                        setFormData((prevState) => ({ ...prevState, votingRightPercentage: value }))
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </>
      <AlertDialog
        isVisible={showAlert.whatIsUbo}
        onClose={() =>
          setShowAlert((prevstate) => ({ ...prevstate, whatIsUbo: !showAlert.whatIsUbo }))
        }
        alertDialogTexts={t(`cdd:whatIsUboDialog.${uboType}`, { returnObjects: true })}
      />
      <AlertDialog
        isVisible={showAlert.deleteUbo}
        onClose={() =>
          setShowAlert((prevstate) => ({
            ...prevstate,
            deleteUbo: !showAlert.deleteUbo,
          }))
        }
        alertDialogTexts={t('cdd:deleteUboDialog', { returnObjects: true })}
      />
      <AlertDialog
        isVisible={showAlert.nobodyHasMoreThan25Percent}
        onClose={() =>
          setShowAlert((prevState) => ({ ...prevState, nobodyHasMoreThan25Percent: false }))
        }
        alertDialogTexts={t('cdd:processOverview.alertDialog', { returnObjects: true })}
        primaryColorContinueButton
      />
    </FlowLayout>
  );
};

export default UboForm;
