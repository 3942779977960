import { AxiosError } from 'axios';
import { captureError, keysToCamel } from 'src/utils';
import restApi from 'src/services/restApi';
import { Session } from 'src/interfaces/Session';
import { CaptureErrorType } from 'src/types/utils/captureError';
import { authorizeInFlowEmbeddedUserResponse, authorizeInFlowEmbeddedUserResult } from './types';
import { GeneralObject } from 'src/types/generic';

export const authorizeInFlowEmbeddedUser = async (
  token: string,
  userFlowId: string,
): Promise<authorizeInFlowEmbeddedUserResult> => {
  try {
    const { data } = await restApi.post<authorizeInFlowEmbeddedUserResponse>(
      '/api/latest/user/authorize_in_flow_embedded_user',
      {
        user_flow_id: userFlowId,
      },
      {
        headers: {
          'embedded-user-token': `${token}`,
        },
      },
    );

    const user = keysToCamel(data.user);
    const session = keysToCamel(data.userSession) as GeneralObject;
    const accessToken = `${data.accessToken}`;

    const userSession: Session = {
      id: session.sessionId as string,
      lastUsedAt: session.lastUsedAt as Date,
      deviceName: session.deviceName as string,
      biometricsPublicKey: session.biometricsPublicKey as string,
    };

    return {
      data: {
        user,
        userSession,
        accessToken,
        tokenExpired: data.tokenExpired,
      },
      success: true,
    };
  } catch (error) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'authorizeEmbeddedUser',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data || returnError.message || '',
      },
    };
  }
};
