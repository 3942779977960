import { atom } from 'jotai';
import { getPersistedPartnerState, setPersistedPartnerState } from '../partner-actions-atoms';
import { globalStore } from 'src/contexts/globalStore';

const partnerIdAtom = atom<string>(getPersistedPartnerState()?.partnerId || '');

globalStore.sub(partnerIdAtom, () => {
  setPersistedPartnerState({
    ...getPersistedPartnerState(),
    partnerId: globalStore.get(partnerIdAtom),
  });
});

if (process.env.NODE_ENV !== 'production') {
  partnerIdAtom.debugLabel = 'Partner Id';
}

export { partnerIdAtom };
