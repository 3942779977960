import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';

interface getAppIconProps {
  id?: string;
  icon: string;
  className: string;
}

export const getAppIcon = ({ id, icon, className = '' }: getAppIconProps) => {
  const adjustedIcon = icon.replaceAll('_', '-');
  switch (adjustedIcon) {
    case 'payments':
      return id !== 'payments' ? (
        <BizcuitIcon icon="payments" className={className} />
      ) : (
        <BizcuitIcon icon="send" className={className} />
      );
    default:
      return <BizcuitIcon dynamic icon={adjustedIcon} id={id} className={className} />;
  }
};
