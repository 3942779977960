import React, { FC, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Paths from '../../tokens/Paths';
const StepSelectDeviceQR = lazy(() => import('./select-device-qr/SelectDeviceQR'));
const StepSelectDevice = lazy(() => import('./select-device/SelectDevice'));

const SelectDeviceRouter: FC = () => {
  const { segments } = Paths.selectDevice;

  return (
    <Routes>
      <Route index element={<StepSelectDevice />} />
      <Route path={segments.qr} element={<StepSelectDeviceQR />} />
    </Routes>
  );
};

export default SelectDeviceRouter;
