import { logger } from 'src/utils';

const getSessionStorageObject = <T>(entry: string) => {
  try {
    return JSON.parse(sessionStorage.getItem(entry) || '') as T;
  } catch (error) {
    logger.log(`No session storage object found with entry: ${entry}`);
  }

  return null;
};

export default getSessionStorageObject;
