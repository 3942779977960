import { ReactNode } from 'react';

interface TextProps {
  variant: TextVariant;
  weight: TextWeight;
  color: string;
  className?: string;
  isCentered?: boolean;
  as?: ElementVariant;
  children?: ReactNode;
}

enum TextVariant {
  xxLarge = 'text-xx-large',
  xxLargeLoose = 'text-xx-large-loose',
  xLarge = 'text-x-large',
  large = 'text-large',
  medium = 'text-medium',
  mediumLoose = 'text-medium-loose',
  small = 'text-small',
  smallLoose = 'text-small-loose',
  smallXLoose = 'text-small-x-loose',
  xSmall = 'text-x-small',
  xsPlus = 'text-xs-plus',
  xSmallLoose = 'text-x-small-loose',
  xxSmall = 'text-xx-small',
  smPlus = 'text-sm-plus',
}

enum TextWeight {
  regular = 'font-weight-400',
  medium = 'font-weight-500',
  bold = 'font-weight-700',
}

enum TextColor {
  white = 'text-white',
  black = 'text-black',
  primary500 = 'text-primary-500',
  primary600 = 'text-primary-600',
  primary900 = 'text-primary-900',
  warmGrey300 = 'text-grey-300',
  warmGrey600 = 'text-grey-600',
  warmGrey800 = 'text-grey-800',
  bizGray200 = 'text-biz-gray-200',
  bizGray300 = 'text-biz-gray-300',
  bizGray500 = 'text-biz-gray-500',
  bizGray700 = 'text-biz-gray-700',
  bizGray800 = 'text-biz-gray-800',
  bizGray900 = 'text-biz-gray-900',
  bizGreen500 = 'text-biz-green-500',
  bizGreen800 = 'text-biz-green-800',
  bizYellow800 = 'text-biz-yellow-800',
  bizRed500 = 'text-biz-red-500',
  bizOldGrey300 = 'biz-old-grey-300',
}

type ElementVariant = 'h1' | 'h2' | 'h3' | 'p' | 'span';

export default TextProps;
export { TextVariant, TextWeight, TextColor };
