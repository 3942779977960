import { FC } from 'react';
import ContainerProps from './Container.types';

// TODO: This container looks very similar to the Paper component. Maybe we can migrate Container to that component.
const Container: FC<ContainerProps> = ({
  children,
  className = '',
  shadowClass = 'shadow-md',
  hasPadding = true,
  isOverflowHidden = false,
  roundedClass = 'rounded-3xl',
  onClick,
  isDisabled,
  ...rest
}) => (
  <div
    className={`bg-white dark:bg-biz-gray-800 border border-biz-gray-100 dark:border-biz-gray-900 ${roundedClass} ${shadowClass} ${
      hasPadding ? 'p-4' : ''
    } ${isDisabled ? 'opacity-40 cursor-not-allowed' : ''} ${
      isOverflowHidden ? 'overflow-hidden' : ''
    } ${className}`}
    onClick={onClick}
    {...rest}
  >
    {children}
  </div>
);

export default Container;
