import { createElement } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { PermissionConsent, PermissionGroup } from 'src/interfaces/Permissions';
import { ConsentTranslations } from 'src/interfaces/Permissions';
import { Administration, BankAccount, PartnerScopes } from 'src/types/bizcuitApi';
import showScopeCheck from './showScopeCheck';

const getBankAccountsPermissions = (
  bankAccounts: BankAccount[],
  administrations: Administration[],
  scopes: PartnerScopes[] = [],
  trustedScopes: PartnerScopes[] = [],
): Nullable<PermissionConsent> => {
  const showScope = showScopeCheck(scopes, trustedScopes);
  const bankAccountScopes = [
    PartnerScopes.AccountInformation,
    PartnerScopes.ManageBankAccounts,
    PartnerScopes.PaymentInitiation,
  ];
  const hasBankAccountScopes = scopes.some((scope) => {
    return bankAccountScopes.includes(scope);
  });
  const hasVisibleScopes = bankAccountScopes.some((scope) => showScope(scope));

  if (!hasBankAccountScopes || !hasVisibleScopes) return null;

  const permissions = bankAccounts.reduce((groups: Array<PermissionGroup>, bankAccount) => {
    if (bankAccount?.iban) {
      let groupIndex = groups.findIndex((group) => group.id === bankAccount.administrationId);

      if (groupIndex < 0) {
        const administration = administrations.find(
          (administration) => administration.id === bankAccount.administrationId,
        );
        if (administration) {
          groups.push({
            id: administration.id,
            title: administration.name,
            permissions: [],
          });
          groupIndex = groups.length - 1;
        }
      }
      groups[groupIndex].permissions.push({
        name: bankAccount.id,
        title: bankAccount.displayName || bankAccount.iban,
        secondaryText: bankAccount.displayName ? bankAccount.iban : undefined,
      });
    }
    return groups;
  }, []);

  const isEmptyPermissions = permissions.length === 0;

  let title: ConsentTranslations = 'addAndManageBankAccounts';
  let description: ConsentTranslations = 'givePartnerPermissionAddAndManageBankAccountsAndPayments';

  if (showScope(PartnerScopes.AccountInformation)) {
    title = 'bankAccounts';
    description = 'givePermissionUseFollowingBankAccountsAndRetrieveTransactions';
  }

  if (showScope(PartnerScopes.PaymentInitiation)) {
    title = 'bankAccounts';
    description = 'givePermissionUseFollowingBankAccountsAndPayments';
  }

  if (showScope(PartnerScopes.ManageBankAccounts)) {
    title = 'addAndManageBankAccounts';
    description = isEmptyPermissions
      ? 'givePartnerPermissionAddAndManageBankAccounts'
      : 'givePartnerPermissionManageAndAddNewBankAccountsAndPayments';
  }

  if (showScope(PartnerScopes.AccountInformation) && showScope(PartnerScopes.PaymentInitiation)) {
    title = 'bankAccounts';
    description = 'givePermissionUseFollowingBankAccounts';
  }

  if (showScope(PartnerScopes.ManageBankAccounts) && showScope(PartnerScopes.AccountInformation)) {
    title = 'addAndManageBankAccounts';
    description = isEmptyPermissions
      ? 'givePermissionAddAndManageBankAccounts'
      : 'givePermissionManageFollowingBankAccountsAndAddNew';
  }

  if (showScope(PartnerScopes.ManageBankAccounts) && showScope(PartnerScopes.PaymentInitiation)) {
    title = 'addAndManageBankAccounts';
    description = isEmptyPermissions
      ? 'givePartnerPermissionAddAndManageBankAccountsAndPayments'
      : 'givePartnerPermissionManageAndAddNewBankAccounts';
  }

  if (
    showScope(PartnerScopes.ManageBankAccounts) &&
    showScope(PartnerScopes.PaymentInitiation) &&
    showScope(PartnerScopes.AccountInformation)
  ) {
    title = 'addAndManageBankAccounts';
    description = isEmptyPermissions
      ? 'givePermissionAddBankAccounts'
      : 'givePartnerPermissionManageAndAddNewBankAccountsAndPayments';
  }

  return {
    scope: PartnerScopes.AccountInformation,
    title,
    description,
    icon: createElement(BizcuitIcon, { icon: 'payment', className: 'w-6 h-6' }),
    permissions,
  };
};

export default getBankAccountsPermissions;
