import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { parseQueryParamsString } from 'src/helpers';
import { useErrorDialog, useErrorTranslation, usePartnerStore, useUserStore } from 'src/hooks';
import { getJwtPayload, logger, removeCookie, setCookie } from 'src/utils';
import { useOrchestrator } from 'src/hooks/useOrchestrator';
import FullPageLoader from 'src/atoms/loaders/full-page-loader/FullPageLoader';
import { useNavigate } from 'react-router';
import { Maybe, Partner, useGetPartnerLazyQuery, useUpdateUser } from 'src/types/bizcuitApi';
import { BindSessionKeys, BizcuitError, CookieKeys, LocalStorageKeys, Paths } from 'src/tokens';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import getTranslation from 'src/helpers/getTranslation';
import i18next from 'src/i18next';
import { RequireAtLeastOne } from 'src/types/generic';
import { useAtom } from 'jotai';
import * as Sentry from '@sentry/react';
import { orchestratorAtom } from 'src/contexts/orchestrator-global-state/orchestrator-atoms/orchestrator-global-atoms';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { EventNames } from 'src/types/analytics';
import { useApolloClient } from '@apollo/client';
import { AuthTokenPayload, FlowFilter } from './types';
import { availableLanguages } from 'src/i18next';

function resetClientSideStorage() {
  Object.values(CookieKeys).forEach((cookieKey) => {
    removeCookie(cookieKey);
  });

  if (window.localStorage) {
    Object.values(LocalStorageKeys).forEach((localStorageKey) => {
      window.localStorage.removeItem(localStorageKey);
    });
    Object.values(BindSessionKeys).forEach((sessionKey) => {
      window.localStorage.removeItem(sessionKey);
    });
  }
}

const AuthorizeInFlowEmbeddedUser: FC = () => {
  const client = useApolloClient();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { flowId = '' } = useParams();
  const queryParams = parseQueryParamsString(search);
  const [getPartner] = useGetPartnerLazyQuery();
  const [updateUser] = useUpdateUser();
  const [orchestratorState, setOrchestratorState] = useAtom(orchestratorAtom);
  const {
    actions: { authorizeInFlowEmbeddedUser },
  } = useUserStore();
  const {
    methods: { startFlow, navigateToAction, updateActionState },
    error: { startFlow: startFlowError },
  } = useOrchestrator();
  const {
    state: { partnerId },
    actions: { initializePartnerState },
  } = usePartnerStore();
  const { trackEvent, trackUserIdentity } = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const [userAuthorizationError, setUserAuthorizationError] = useState<Maybe<BizcuitError>>(null);
  const [readyToNavigate, setReadyToNavigate] = useState(false);
  const [nextStep, setNextStep] =
    useState<RequireAtLeastOne<{ nextActionId: string; nextUrl: string }>>();
  const redirectUrl = queryParams.redirect_url
    ? decodeURIComponent(queryParams.redirect_url)
    : undefined;
  const errorDialog = useErrorDialog();
  const expiredError = useErrorTranslation('sessionExpired');
  const notAuthorizedError = useErrorTranslation('notAuthorized');
  const accountNotActiveError = useErrorTranslation('accountNotActive', {
    errorTitle: getTranslation(`accountingHub:${flowId}.shortTitle`),
  });
  const orchestratorGenericError = useErrorTranslation('somethingWentWrong');

  useEffect(() => {
    setTimeout(() => {
      setReadyToNavigate(true);
    }, 800);
  }, []);

  useEffect(() => {
    if (readyToNavigate && nextStep && orchestratorState?.flow) {
      if (nextStep.nextActionId) {
        navigateToAction({
          actionId: nextStep.nextActionId,
          partnerId,
          flow: orchestratorState.flow,
          request: orchestratorState?.approverRequest,
        });
      } else if (nextStep.nextUrl) {
        navigate(nextStep.nextUrl);
      } else {
        logger.warn('No nextActionId or nextUrl found');
      }
      return;
    }

    if (isLoading) return;

    client.resetStore().catch((error) => {
      errorDialog.open(orchestratorGenericError);
      Sentry.captureException('Something went wrong while resetting the apollo store', error);
    });

    resetClientSideStorage();
    if (redirectUrl) setCookie(CookieKeys.redirectUrl, redirectUrl);
    setOrchestratorState(null);

    if (availableLanguages.includes(i18next.language)) {
      updateUser({ variables: { updateUserArgs: { language: i18next.language } } });
    }

    const authorizeUserAndStartFlow = async () => {
      setIsLoading(true);
      const token = queryParams.code;

      const authTokenPayload = getJwtPayload<AuthTokenPayload>(token);
      const userFlowFilter: {
        [key in keyof FlowFilter]: Record<string, string[]>;
      } = {};

      if (authTokenPayload?.filter) {
        Object.keys(authTokenPayload.filter).forEach((filterKey) => {
          const key = filterKey as keyof FlowFilter;

          userFlowFilter[key] = authTokenPayload.filter[key];
        });
      }

      if (authTokenPayload?.params) {
        setOrchestratorState({
          flowParams: authTokenPayload.params,
        });
      }

      const { data: getPartnerResponse } = await getPartner({
        variables: {
          partnerId: authTokenPayload?.partnerId,
        },
      });

      if (getPartnerResponse?.getPartner?.partner?.__typename === 'NonExistingPartner') return;
      const partner = getPartnerResponse?.getPartner?.partner as Partner;

      await initializePartnerState({
        clientId: partner?.id,
        partner,
      });

      const userAuthorizationResult = await authorizeInFlowEmbeddedUser(token, flowId);

      if (userAuthorizationResult.error) {
        if (userAuthorizationResult.error.message === 'user not active') {
          setUserAuthorizationError({
            ...accountNotActiveError,
            redirectUrl: redirectUrl ? redirectUrl : '/o/start-flow-demo',
          });
          return;
        }

        if (userAuthorizationResult.error.message === 'jwt expired') {
          if (queryParams.requestId) {
            navigate(
              Paths.orchestrator.root +
                Paths.orchestrator.segments.request.root +
                Paths.orchestrator.segments.request.segments.linkExpired.replace(
                  ':requestId',
                  queryParams.requestId,
                ),
            );
            return;
          }

          setUserAuthorizationError({
            ...expiredError,
            redirectUrl: redirectUrl ? redirectUrl : '/o/start-flow-demo',
          });
          return;
        }

        setUserAuthorizationError({
          ...notAuthorizedError,
          redirectUrl: redirectUrl ? redirectUrl : '/o/start-flow-demo',
        });
        return;
      }

      if (userAuthorizationResult.user) {
        await trackUserIdentity({ user: userAuthorizationResult.user, partner });
        trackEvent(EventNames.startFlow, { flowId, isRequestFlow: !!queryParams.requestId });
      }

      if (queryParams.requestId) {
        navigate(
          Paths.orchestrator.root +
            Paths.orchestrator.segments.request.root +
            Paths.orchestrator.segments.request.segments.details.replace(
              ':requestId',
              queryParams.requestId,
            ),
        );
        return;
      }

      let startFlowResult = null;

      if (!nextStep) {
        startFlowResult = await startFlow({
          flowId,
          shouldNavigate: false,
          redirectUrl,
          flowFilter: userFlowFilter,
        });
      }

      if (!startFlowResult) return;

      if (!readyToNavigate) {
        setNextStep(startFlowResult);
        return;
      }

      if (!nextStep) return;

      if (nextStep.nextActionId && orchestratorState?.flow) {
        navigateToAction({
          actionId: nextStep.nextActionId,
          partnerId: partner.id,
          flow: orchestratorState.flow,
          request: orchestratorState?.approverRequest,
        });
      } else if (nextStep.nextUrl) {
        navigate(nextStep.nextUrl);
      }
      setIsLoading(false);
    };

    authorizeUserAndStartFlow();
  }, [
    accountNotActiveError,
    authorizeInFlowEmbeddedUser,
    client,
    errorDialog,
    expiredError,
    flowId,
    getPartner,
    initializePartnerState,
    isLoading,
    navigate,
    navigateToAction,
    nextStep,
    notAuthorizedError,
    orchestratorGenericError,
    orchestratorState?.approverRequest,
    orchestratorState?.flow,
    partnerId,
    queryParams.code,
    queryParams.requestId,
    readyToNavigate,
    redirectUrl,
    setOrchestratorState,
    startFlow,
    trackEvent,
    trackUserIdentity,
    updateActionState,
    updateUser,
  ]);

  useEffect(() => {
    if (!userAuthorizationError && !startFlowError) return;

    Sentry.captureException('Something went wrong while trying to authorize user and start flow');
    errorDialog.open(
      userAuthorizationError || {
        ...orchestratorGenericError,
        redirectUrl: redirectUrl ? redirectUrl : '/o/start-flow-demo',
      },
    );
  }, [userAuthorizationError, startFlowError, errorDialog, orchestratorGenericError, redirectUrl]);

  if (userAuthorizationError || startFlowError) return <FlowLayout />;

  return <FullPageLoader />;
};

export default AuthorizeInFlowEmbeddedUser;
