import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import { SendApproveTermsRequest, SendApproveTermsResponse, SendApproveTermsResult } from './types';

export const sendApproveTermsEmail = async (
  approveTermsReqPayload: SendApproveTermsRequest,
): Promise<SendApproveTermsResult> => {
  const payload = {
    full_name: approveTermsReqPayload.fullName,
    billing_iban: approveTermsReqPayload.billingIban,
    billing_city: approveTermsReqPayload.billingCity,
    billing_company_name: approveTermsReqPayload.billingCompanyName,
    billing_accepted: approveTermsReqPayload.billingAccepted,
    create_bank_account: approveTermsReqPayload.createBankAccount,
    create_administration: approveTermsReqPayload.createAdministration,
  };

  try {
    const { data } = await restApi.post<SendApproveTermsResponse>(
      `/api/latest/user/approve_terms`,
      payload,
    );

    return {
      success: true,
      data,
    };
  } catch (error) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'sendApproveTermsEmail',
      error: error as Error,
    });

    const returnError = error as AxiosError;
    return {
      success: false,
      error: {
        message: returnError.response?.data ?? '',
      },
    };
  }
};
