import { atom } from 'jotai';
import { getPersistedPartnerState } from '../partner-actions-atoms';
import { globalStore } from 'src/contexts/globalStore';
import { setPersistedPartnerState } from '../partner-actions-atoms';

const partnerReferenceAtom = atom<string | undefined>(getPersistedPartnerState()?.partnerReference);

globalStore.sub(partnerReferenceAtom, () => {
  setPersistedPartnerState({
    ...getPersistedPartnerState(),
    partnerReference: globalStore.get(partnerReferenceAtom),
  });
});

if (process.env.NODE_ENV !== 'production') {
  partnerReferenceAtom.debugLabel = 'Partner Reference';
}

export { partnerReferenceAtom };
