import { atom } from 'jotai';
import { CookieKeys } from 'src/tokens';
import { Theme } from 'src/types/bizcuitApi';
import { isEmpty, setCookie, getCookie } from 'src/utils';

const isSafariBefore162 = (userAgent: string): boolean => {
  if (
    !window.navigator.userAgent.includes('Safari') ||
    window.navigator.userAgent.includes('Chrome')
  ) {
    return false;
  }
  const safariVersionMatch = userAgent.match(/Version\/(\d+\.\d+)/);
  if (safariVersionMatch) {
    const safariVersion = parseFloat(safariVersionMatch[1]);
    return safariVersion <= 16.1;
  }
  return false;
};

const setPartnerTheme = atom(null, (_get, set, theme: Theme) => {
  let updatedTheme = theme;

  // v1 Theme compatibility
  if (!updatedTheme || isEmpty(updatedTheme)) {
    const clientStr = getCookie('client');
    if (clientStr) {
      updatedTheme = JSON.parse(window.atob(clientStr)) as Theme;
    }
  }

  if (updatedTheme.color) {
    // Work around for old safari https://caniuse.com/?search=color-mix
    if (isSafariBefore162(window.navigator.userAgent)) {
      document.documentElement.style.setProperty('--color-primary-100', `${updatedTheme.color}66`);
      document.documentElement.style.setProperty('--color-primary-200', `${updatedTheme.color}88`);
      document.documentElement.style.setProperty('--color-primary-300', `${updatedTheme.color}aa`);
      document.documentElement.style.setProperty('--color-primary-400', `${updatedTheme.color}cc`);
      document.documentElement.style.setProperty('--color-primary-500', `${updatedTheme.color}ff`);
      document.documentElement.style.setProperty('--color-primary-600', `${updatedTheme.color}cc`);
      document.documentElement.style.setProperty('--color-primary-700', `${updatedTheme.color}aa`);
      document.documentElement.style.setProperty('--color-primary-800', `${updatedTheme.color}88`);
      document.documentElement.style.setProperty('--color-primary-900', `${updatedTheme.color}66`);
      return;
    }

    document.documentElement.style.setProperty(
      '--color-primary-100',
      `color-mix(in srgb, ${updatedTheme.color}, white 90%)`,
    );
    document.documentElement.style.setProperty(
      '--color-primary-200',
      `color-mix(in srgb, ${updatedTheme.color}, white 80%)`,
    );
    document.documentElement.style.setProperty(
      '--color-primary-300',
      `color-mix(in srgb, ${updatedTheme.color}, white 60%)`,
    );
    document.documentElement.style.setProperty(
      '--color-primary-400',
      `color-mix(in srgb, ${updatedTheme.color}, white 40%)`,
    );
    document.documentElement.style.setProperty('--color-primary-500', `${updatedTheme.color}ff`);
    document.documentElement.style.setProperty(
      '--color-primary-600',
      `color-mix(in srgb, ${updatedTheme.color}, black 10%)`,
    );
    document.documentElement.style.setProperty(
      '--color-primary-700',
      `color-mix(in srgb, ${updatedTheme.color}, black 20%)`,
    );
    document.documentElement.style.setProperty(
      '--color-primary-800',
      `color-mix(in srgb, ${updatedTheme.color}, black 30%)`,
    );
    document.documentElement.style.setProperty(
      '--color-primary-900',
      `color-mix(in srgb, ${updatedTheme.color}, black 40%)`,
    );
    // Storing the theme in client object cookie is required to keep partner theme consistent also on V1
    setCookie(CookieKeys.client, window.btoa(JSON.stringify(updatedTheme)));
  } else {
    // Reset Bizcuit color scheme (from index.css)
    document.documentElement.style.setProperty('--color-primary-100', '#fff6ed');
    document.documentElement.style.setProperty('--color-primary-200', '#ffd6ad');
    document.documentElement.style.setProperty('--color-primary-300', '#ffc182');
    document.documentElement.style.setProperty('--color-primary-400', '#ffa347');
    document.documentElement.style.setProperty('--color-primary-500', '#ff8000');
    document.documentElement.style.setProperty('--color-primary-600', '#d36a00');
    document.documentElement.style.setProperty('--color-primary-700', '#9e4f00');
    document.documentElement.style.setProperty('--color-primary-800', '#5b2e00');
    document.documentElement.style.setProperty('--color-primary-900', '#241200');
  }
});

export { setPartnerTheme };
