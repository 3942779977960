import { atom } from 'jotai';
import { arrayConvertors, getDeviceIdentifier, sessionUtil } from 'src/helpers';
import { generateDerivedKey, generateDeviceFingerprint } from 'src/helpers/deviceUtil';
import setAccessToken from 'src/helpers/setAccessToken';
import { Result } from 'src/services/types';
import { bindSession as bindSessionService } from 'src/services/user';
import { userSessionAtom } from '../user-state-atoms';
import { getCookie } from 'src/utils';
import { CookieKeys } from 'src/tokens';
import { BindSessionKeys } from 'src/tokens';

const bindSessionAtom = atom(null, async (_get, set, pinCode: string): Promise<Result> => {
  const sessionId = _get(userSessionAtom)?.id;

  const deviceName = navigator.userAgent;
  const deviceIdentifier = await getDeviceIdentifier();
  const accessToken = getCookie(CookieKeys.accessToken);
  let devicePublicKey = '';
  let successful = false;
  let errorObject;

  if (sessionId && accessToken && deviceIdentifier) {
    const iv = crypto.getRandomValues(new Uint8Array(16));
    const algorithemOption = { name: 'AES-GCM', iv };
    const { fingerprint, fingerprintHash } = await generateDeviceFingerprint(iv, deviceIdentifier);

    if (fingerprint && fingerprintHash) {
      const derivedKey = await generateDerivedKey(pinCode, iv, fingerprint);
      const encryptedSessionId = await sessionUtil.encryptSessionId(sessionId, derivedKey, iv);
      const keyPair = await sessionUtil.generateKeyPair();
      const pubKey = await crypto.subtle.exportKey('spki', keyPair.publicKey);
      devicePublicKey = window.btoa(arrayConvertors.ab2str(pubKey));

      const wrappedKey = await crypto.subtle.wrapKey(
        'pkcs8',
        keyPair.privateKey,
        derivedKey,
        algorithemOption,
      );

      localStorage.setItem(BindSessionKeys.encryptedSessionId, encryptedSessionId ?? '');
      localStorage.setItem(BindSessionKeys.fingerprintHash, fingerprintHash ?? '');
      localStorage.setItem(
        BindSessionKeys.encryptedPrivateKey,
        arrayConvertors.ab2hex(wrappedKey) ?? '',
      );
      localStorage.setItem(BindSessionKeys.userRandom, arrayConvertors.ab2hex(iv) ?? '');

      const { success, error } = await bindSessionService({
        sessionId,
        deviceName,
        devicePublicKey,
      });
      successful = success;
      errorObject = error;
      if (successful) {
        setAccessToken(accessToken);
      } else {
        setAccessToken('');
      }
    }
  }
  return { success: successful, error: errorObject };
});

export { bindSessionAtom };
