import React, { useEffect } from 'react';
import { filterJwtTokenMetadata, getJwtPayload, logger } from 'src/utils';
import { useErrorDialog, useErrorTranslation, usePartnerStore } from 'src/hooks';
import * as Sentry from '@sentry/react';
import { DemoNames, JwtPayload } from './OrchestratorFlowDemo.types';
import { defaultPartnerId, defaultUserData, flows, mockAdministrations } from './MockData';
import { getExternalId, setPocSetup } from './utils';
import { FlowLayout } from 'src/atoms/layout/menu-page';
import Select from 'src/atoms/inputs/select/Select';
import { OptionProps } from 'src/atoms/inputs/select/Select.types';
import EmailInput from 'src/atoms/inputs/email-input/EmailInput';
import PrimaryButton from 'src/atoms/buttons/primary-button/PrimaryButton';
import CollapsibleContainer from './CollapsibleContainer';
import TextInput from 'src/atoms/inputs/text-input/TextInput';
import Checkbox from 'src/atoms/inputs/checkbox/Checkbox';
import SecondaryButton from 'src/atoms/buttons/secondary-button/SecondaryButton';
import SpinLoader from '../../atoms/loaders/spin-loader/SpinLoader';
import CopyToClipboardButton from './CopyToClipboardButton';
import SkeletonEmbeddedFlowsTestPageLoader from 'src/atoms/loaders/skeleton-loader/SkeletonEmbeddedFlowsTestPageLoader';
import {
  BetaProgramMemberType,
  Theme,
  useAddBetaMemberToPrograms,
  useAuthorizeAdminEmbeddedFlowsTester,
  useGetActiveBetaPrograms,
  useGetPartnerLazyQuery,
} from 'src/types/bizcuitApi';
import useOrchestratorFlowDemo from './useOrchestratorFlowDemo';
import createUser from './api/createUser';
import getAccessToken from './api/getAccessToken';
import createAdministration from './api/createAdministration';
import createRedirectUrl from './api/createRedirectUrl';
import deleteTestUser from './api/deleteTestUser';
import { Paths } from 'src/tokens';

const StartPocFlow = () => {
  const { orchestratorFlowDemoState, setOrchestratorFlowDemoState } = useOrchestratorFlowDemo();
  const {
    redirectUrl,
    partnerId,
    themePartnerId,
    demoPartner,
    flowId,
    email,
    externalId,
    isBusy,
    termsAccepted,
    selectBetaPrograms,
    userId,
    extendJwtDuration,
    jwtExpiresSeconds,
    restrictedAccountingSystemList,
    bankConnectorList,
    bankList,
    flowParams,
  } = orchestratorFlowDemoState;

  const [getPartner, { loading: getPartnerLoading }] = useGetPartnerLazyQuery();
  const errorDialog = useErrorDialog();
  const errorTranslations = useErrorTranslation('somethingWentWrong');
  const partnerNotAllowedSettingTermsApproved = useErrorTranslation('somethingWentWrong', {
    techDescription: 'PartnerNotAllowedSettingTermsApproved',
  });

  const [addMemberToBetaProgramsMutation] = useAddBetaMemberToPrograms();

  const {
    actions: { setPartnerTheme, setPartnerState },
  } = usePartnerStore();
  const {
    data: authorizeAdminEmbeddedFlowsTesterData,
    loading: authorizeAdminEmbeddedFlowsTesterLoading,
    error: authorizeAdminEmbeddedFlowsTesterError,
  } = useAuthorizeAdminEmbeddedFlowsTester({
    variables: {},
    skip: process.env.NODE_ENV === 'development',
  });

  const { data: activeBetaPrograms, loading: loadingActiveBetaPrograms } = useGetActiveBetaPrograms(
    {
      fetchPolicy: 'network-only',
      onError: (error) => {
        errorDialog.open({ title: 'Failed to get active beta programs', message: error.message });
      },
    },
  );

  useEffect(() => {
    const initPartner = async () => {
      const { data: getPartnerData } = await getPartner({
        variables: { partnerId: themePartnerId },
        fetchPolicy: 'cache-first',
      });
      if (
        getPartnerData?.getPartner?.partner &&
        getPartnerData?.getPartner?.partner.__typename === 'Partner' &&
        !getPartnerLoading
      ) {
        setPartnerState({
          partner: getPartnerData.getPartner.partner,
          partnerId: getPartnerData.getPartner.partner.id,
        });
        const theme: Theme = {
          name: getPartnerData.getPartner.partner.name,
          icon: getPartnerData.getPartner.partner.icon,
          ...(getPartnerData.getPartner.partner.theme && {
            ...getPartnerData.getPartner.partner.theme,
          }),
        };
        setPartnerTheme(theme);
      }
    };
    if (!getPartnerLoading) {
      initPartner();
    }
  }, [getPartner, getPartnerLoading, themePartnerId, setPartnerState, setPartnerTheme]);

  if (!orchestratorFlowDemoState.partnerId) {
    setOrchestratorFlowDemoState({
      partnerId: defaultUserData[demoPartner].partnerId,
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (redirectUrl?.includes('?code=')) {
        const payload = getJwtPayload<JwtPayload>(redirectUrl.split('?code=')[1]);
        if (payload) {
          setOrchestratorFlowDemoState({
            jwtExpiresSeconds: payload.exp - Math.floor(Date.now() / 1000),
          });
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [redirectUrl, setOrchestratorFlowDemoState]);

  const handleCreateUser = async () => {
    setOrchestratorFlowDemoState({
      isBusy: true,
    });

    const response = await createUser({
      partnerId,
      demoPartner,
      flowId,
      termsAccepted,
      email,
      externalId,
    });
    const responseJSON = await response.json();
    setOrchestratorFlowDemoState({
      isBusy: false,
    });

    if (responseJSON.error === 'Partner is not allowed to set terms_approved on behalf of user') {
      Sentry.captureException('Partner is not allowed to set terms_approved on behalf of user');
      errorDialog.open(partnerNotAllowedSettingTermsApproved);
      return;
    }

    const userId: string = responseJSON.id;

    if (userId && selectBetaPrograms.length > 0) {
      await addMemberToBetaProgramsMutation({
        variables: {
          memberId: userId,
          memberType: BetaProgramMemberType.User,
          betaProgramIds: selectBetaPrograms,
        },
      });
    }

    setOrchestratorFlowDemoState({
      themePartnerId: partnerId,
    });

    const canCreateAdministrations = termsAccepted;
    const demoWantsExtraAdmnistrations = demoPartner === DemoNames.ConnectAccountingSystem;
    if (canCreateAdministrations && demoWantsExtraAdmnistrations) {
      setOrchestratorFlowDemoState({
        isBusy: true,
      });
      await handleCreateAdministrations(userId);
      setOrchestratorFlowDemoState({
        isBusy: false,
      });
    }

    setPocSetup('userId', userId);
    setPocSetup('partnerId', partnerId);

    setOrchestratorFlowDemoState({
      userId,
    });

    return userId;
  };

  const handleCreateAdministrations = async (userId: string) => {
    const accessToken = await getAccessToken(userId, partnerId);
    if (!accessToken) {
      Sentry.captureException('Failed to get access token');
      errorDialog.open({
        ...errorTranslations,
        message: 'Failed to get access token',
        errorSupport: { provideToSupport: 'getAccessToken' },
      });
      return;
    }

    const promises = mockAdministrations.map((administration) => {
      return createAdministration(accessToken, administration);
    });

    try {
      const administrationIds = await Promise.all(promises);
      logger.log('Created administrations:', administrationIds);
    } catch (e) {
      Sentry.captureException('Failed to create administrations');
      errorDialog.open({
        ...errorTranslations,
        message: 'Failed to create administrations',
        errorSupport: { provideToSupport: 'handleCreateAdministration' },
      });
    }
  };

  const handleBetaProgramsSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBetaPrograms = Array.from(event.target.selectedOptions).map(
      (option) => option.value,
    );

    setOrchestratorFlowDemoState({
      selectBetaPrograms: selectedBetaPrograms,
    });
  };

  const handleCreateRedirectUrl = async () => {
    const userId = orchestratorFlowDemoState.userId
      ? orchestratorFlowDemoState.userId
      : await handleCreateUser();

    if (!userId) {
      return errorDialog.open({
        ...errorTranslations,
        message: 'Invalid User ID',
      });
    }

    setOrchestratorFlowDemoState({
      isBusy: true,
      redirectUrl: null,
    });

    const response = await createRedirectUrl({
      flowId,
      partnerId,
      userId,
      extendJwtDuration,
      restrictedAccountingSystemList,
      bankConnectorList,
      bankList,
      flowParams,
    });

    const responseData = await response.json();
    if (response.status !== 200 || !responseData?.bizcuit_redirect_url) {
      Sentry.captureException('Failed to create redirect url');
      errorDialog.open({
        ...errorTranslations,
        message: responseData?.error ?? 'Failed to create redirect url',
      });
      setOrchestratorFlowDemoState({
        isBusy: false,
      });
      return;
    }

    const url = new URL(responseData.bizcuit_redirect_url);
    url.hostname = window.location.hostname;
    setOrchestratorFlowDemoState({
      isBusy: false,
      redirectUrl: url.toString(),
    });
  };

  const handleDeleteTestUser = async () => {
    if (!userId) {
      return;
    }
    setOrchestratorFlowDemoState({
      isBusy: true,
    });

    const response = await deleteTestUser(userId, partnerId);

    if (response.status !== 204) {
      Sentry.captureException('Failed to delete user');
      errorDialog.open({ ...errorTranslations, message: 'Failed to delete user' });
    }

    setPocSetup('userId', null);
    setPocSetup('partnerId', null);
    setPocSetup('partnerSecret', null);
    setOrchestratorFlowDemoState({
      redirectUrl: null,
      userId: '',
      themePartnerId: defaultPartnerId,
      isBusy: false,
      selectBetaPrograms: [],
    });
  };

  const flowOptions = Object.entries(flows).map(([k, v]) => ({
    label: v,
    value: k,
    selected: k === flowId,
  }));

  const generateDefaultUserDataOptions = (): OptionProps[] => {
    const filteredUserDataKeys = Object.entries(defaultUserData)
      .filter(([_, value]) => {
        const { prodOnly } = value;

        // TODO: Refactor and add env indicatore to env files as part of BIZCUIT-18869
        return process.env.REACT_APP_ALLOW_TEST_FEATURES !== 'TRUE' ? prodOnly : !prodOnly;
      })
      .map(([key]) => key);

    return filteredUserDataKeys.map((key) => ({
      label: key,
      value: key,
      selected: key === demoPartner,
    }));
  };

  const isRedirectUrlValid = redirectUrl && jwtExpiresSeconds > 0;

  if (authorizeAdminEmbeddedFlowsTesterError) {
    Sentry.captureException('Failed to get security roles');
    errorDialog.open(errorTranslations);
  }

  const isFetchingSecurityRoles =
    process.env.NODE_ENV !== 'development' &&
    (authorizeAdminEmbeddedFlowsTesterLoading ||
      !authorizeAdminEmbeddedFlowsTesterData?.authorizeAdminEmbeddedFlowsTester);

  return (
    <FlowLayout
      pageHeaderText={isFetchingSecurityRoles ? 'Loading...' : 'Bizcuit Demo'}
      showBackButton={false}
      spaceBetween
      showLanguagePicker={false}
    >
      {isFetchingSecurityRoles ? (
        <SkeletonEmbeddedFlowsTestPageLoader />
      ) : (
        <>
          <div>
            <h1 className="heading-100 my-6 text-center text-warm-grey-800 dark:text-grey-100">
              Start Demo for {demoPartner}
            </h1>
            <div className="flex flex-col items-center w-full my-5">
              <img
                src={defaultUserData[demoPartner].icon}
                alt="Partner icon"
                className="w-1/6 mb-5"
              />
            </div>
            <div className="flex flex-col gap-8">
              <Select
                key="select-demo"
                name="select-demo"
                label="Partner"
                placeHolder=""
                options={generateDefaultUserDataOptions()}
                onChange={(value) => {
                  setOrchestratorFlowDemoState({
                    demoPartner: value as DemoNames,
                    partnerId: defaultUserData[value as DemoNames].partnerId,
                    externalId: getExternalId(),
                    flowId: defaultUserData[value as DemoNames].defaultFlow,
                  });
                  if (value === DemoNames.ConnectAccountingSystem) {
                    setOrchestratorFlowDemoState({
                      termsAccepted: true,
                    });
                  }
                }}
              />

              <Select
                key="select-flow"
                name="select-flow"
                label="Flow"
                placeHolder=""
                options={flowOptions}
                onChange={(value) => setOrchestratorFlowDemoState({ flowId: value })}
              />

              {![
                DemoNames.Visa,
                DemoNames.ConnectAccountingSystemMollieApAr,
                DemoNames.ConnectAccountingSystem,
              ].includes(demoPartner) && (
                <div>
                  <TextInput
                    value={bankList.join(',')}
                    onChange={(value) => {
                      setOrchestratorFlowDemoState({
                        bankList: value.split(',').map((s) => s.toLowerCase().trim()),
                        redirectUrl: null,
                      });
                    }}
                    placeHolder="input"
                    label="List of banks (separated by comma)"
                    name="bank-list"
                  />
                  <br />
                  <TextInput
                    value={bankConnectorList.join(',')}
                    onChange={(value) => {
                      setOrchestratorFlowDemoState({
                        bankConnectorList: value.split(',').map((s) => s.toLowerCase().trim()),
                        redirectUrl: null,
                      });
                    }}
                    placeHolder="input"
                    label="List of bank connectors (separated by comma)"
                    name="bank-connector-list"
                  />
                </div>
              )}

              {![DemoNames.ConnectBankAccountsFlow].includes(demoPartner) && (
                <TextInput
                  value={restrictedAccountingSystemList.join(',')}
                  onChange={(value) => {
                    setOrchestratorFlowDemoState({
                      restrictedAccountingSystemList: value
                        .split(',')
                        .map((accountingSystem) => accountingSystem.toLowerCase().trim()),
                      redirectUrl: null,
                    });
                  }}
                  placeHolder="input"
                  label="List of bookkeeping systems (separated by comma)"
                  name="restrict-accounting-systems-input"
                />
              )}

              <EmailInput
                value={email}
                label="E-mail (optional)"
                name="email-input"
                placeHolder="Enter your email address"
                onChange={(email) => setOrchestratorFlowDemoState({ email })}
                validateOnBlur
              />

              {process.env.REACT_APP_ALLOW_TEST_FEATURES === 'TRUE' && (
                <div className="flex flex-col items-left">
                  <Checkbox
                    name="extend-jwt-duration"
                    isCentered
                    onChecked={() =>
                      setOrchestratorFlowDemoState({
                        extendJwtDuration: true,
                      })
                    }
                    onUnchecked={() =>
                      setOrchestratorFlowDemoState({
                        extendJwtDuration: false,
                      })
                    }
                  >
                    <span className="text-base font-weight-600 dark:text-gray-100">
                      Extend redirect URL expiry to 72 hours
                    </span>
                  </Checkbox>
                </div>
              )}

              <div className="flex flex-col items-left">
                <Checkbox
                  name="terms-accepted"
                  isChecked={termsAccepted}
                  isCentered
                  onChecked={() => setOrchestratorFlowDemoState({ termsAccepted: true })}
                  onUnchecked={() => setOrchestratorFlowDemoState({ termsAccepted: false })}
                >
                  <span className="text-base font-weight-600 dark:text-gray-100">
                    User has accepted terms and conditions
                  </span>
                </Checkbox>
              </div>

              <>
                {activeBetaPrograms?.getActiveBetaPrograms &&
                  activeBetaPrograms.getActiveBetaPrograms.length > 0 && (
                    <div>
                      <label
                        htmlFor="beta_programs"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Select beta programs ( Hold Shift key to select multiple or use CMD/WIN key
                        + A to select all)
                      </label>
                      <select
                        multiple
                        id="beta_programs"
                        className="bg-biz-gray-100 dark:bg-biz-gray-700 shadow-biz font-weight-500 text-base w-full focus:outline-none"
                        onChange={handleBetaProgramsSelectChange}
                      >
                        {activeBetaPrograms.getActiveBetaPrograms.map((program) => (
                          <option key={program?.title} value={program?.betaProgramId}>
                            {program?.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                {loadingActiveBetaPrograms && (
                  <div className="flex flex-col items-center">
                    <p>Checking for available beta programs</p>
                    <SpinLoader />
                  </div>
                )}
              </>

              <div className="flex flex-col items-center">
                <PrimaryButton
                  className="w-full sm:w-[343px] mb-4"
                  onClick={handleCreateRedirectUrl}
                  isDisabled={isBusy}
                  showSpinner={isBusy}
                  size={!isRedirectUrlValid ? 'default' : 'small'}
                >
                  {isRedirectUrlValid ? 'Refresh Redirect Url' : 'Create Redirect Url'}
                </PrimaryButton>
                {isRedirectUrlValid && (
                  <>
                    <div className="flex flex-col items-center w-full mb-4">
                      <CopyToClipboardButton
                        clipboardText={redirectUrl}
                        copyActionText="Copy URL to Clipboard"
                        copySuccessText="Copied!"
                        className="w-full sm:w-[343px]"
                        successDisplayTimeMs={2000}
                      />
                    </div>
                    <PrimaryButton
                      className="w-full sm:w-[343px] mb-4"
                      onClick={() => {
                        window.open(
                          `${window.location.origin}/bz-v2${Paths.orchestrator.root}${Paths.orchestrator.segments.renewBankConsentDemo}`,
                        );
                      }}
                      isDisabled={isBusy}
                      showSpinner={isBusy}
                      size={!isRedirectUrlValid ? 'default' : 'small'}
                    >
                      Renew Bank Consent
                    </PrimaryButton>
                  </>
                )}
                {orchestratorFlowDemoState.userId && (
                  <SecondaryButton underline onClick={handleDeleteTestUser}>
                    Reset and start over
                  </SecondaryButton>
                )}
              </div>
            </div>

            <CollapsibleContainer title="Tech details">
              <div className="my-4">
                <p>Details</p>
                <div className="whitespace-pre font-mono text-xs">
                  {JSON.stringify({ flowId, userId }, null, '  ')}
                </div>
              </div>
              {isRedirectUrlValid && (
                <div className="flex flex-col gap-4">
                  <div>
                    <p>Bizcuit Redirect URL (expires in {jwtExpiresSeconds} seconds):</p>
                    <div
                      className="text-xs text-blue-600 dark:text-blue-400 cursor-pointer underline whitespace-pre-line break-all font-monospace"
                      onClick={() => window.open(redirectUrl, '_bizcuit')}
                    >
                      {redirectUrl}
                    </div>
                  </div>
                  <div>
                    <p>Payload:</p>
                    <div className="whitespace-pre font-mono text-xs">
                      {JSON.stringify(
                        filterJwtTokenMetadata(
                          getJwtPayload<JwtPayload>(redirectUrl.split('?code=')[1]),
                          ['iat', 'exp'],
                        ),
                        null,
                        '  ',
                      )}
                    </div>
                  </div>
                </div>
              )}
            </CollapsibleContainer>
          </div>
        </>
      )}
    </FlowLayout>
  );
};

export default StartPocFlow;
