import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmail } from '../../../utils';
import TextInput from '../text-input/TextInput';
import EmailInputProps from './EmailInput.types';

const EmailInput: FC<EmailInputProps> = ({
  label,
  name,
  value,
  placeHolder,
  subText,
  disabled,
  onValidated,
  isEmailValid,
  errorMessage,
  showError,
  validateOnBlur,
  focusOnRender,
  onChange,
  testId,
}) => {
  const [valid, setValid] = useState(false);
  const { t } = useTranslation('common');

  const validateEmail = (email: string) => {
    const isValid = isEmail(email.toLowerCase());

    setValid(isValid);
    if (onValidated) {
      onValidated(isValid);
    }
  };

  const handleChange = (value: string) => {
    if (!validateOnBlur) validateEmail(value);
    if (validateOnBlur && showError) validateEmail(value);
    if (onChange) onChange(value);
  };

  return (
    <TextInput
      onBlur={(value) => {
        if (validateOnBlur && value) validateEmail(value);
      }}
      label={label ?? t('email')}
      name={name ?? t('email')}
      value={value?.trim()}
      placeHolder={placeHolder ?? t('type_your_email')}
      isDisabled={disabled}
      errorMessage={errorMessage ?? t('email_appears_invalid')}
      showError={showError}
      isValid={isEmailValid !== undefined ? isEmailValid : valid}
      onChange={handleChange}
      subText={subText}
      focusOnRender={focusOnRender}
      testId={testId ?? t('email')}
    />
  );
};

export default EmailInput;
