import React, { FC, useState } from 'react';
import Container from 'src/atoms/layout/container/Container';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { ReactNode } from 'react';

interface CollapsibleContainerProps {
  className?: string;
  title: string;
  openOnRender?: boolean;
  children: ReactNode;
}

const CollapsibleContainer: FC<CollapsibleContainerProps> = ({
  children,
  className = '',
  title,
  openOnRender,
}) => {
  const [collapsed, setCollapsed] = useState(!openOnRender);

  return (
    <Container
      shadowClass="shadow-sm"
      className={`rounded-2xl text-small-loose font-weight-500 text-biz-gray-700 dark:text-white ${className}`}
    >
      <div className="flex mb-0">
        <div
          className="flex flex-grow justify-between hover:cursor-pointer"
          onClick={() => setCollapsed((state) => !state)}
        >
          <div>{title}</div>
        </div>
        <div className={'flex-none ml-4'} onClick={() => setCollapsed((state) => !state)}>
          <BizcuitIcon
            icon="chevron-down"
            className={
              'w-6 h-6 text-black dark:text-white transform-gpu transition-all hover:cursor-pointer ' +
              (collapsed ? '' : 'rotate-180')
            }
          />
        </div>
      </div>
      <div
        className={`overflow-hidden transform-gpu origin-top transition-all ease-in-out duration-500 ${
          collapsed ? 'max-h-0' : ''
        }`}
      >
        {children}
      </div>
    </Container>
  );
};

export default CollapsibleContainer;
