import Paths from 'src/tokens/Paths';
import { BiometricsType } from 'src/interfaces/Biometrics';
import { User } from 'src/types/bizcuitApi';

/* This function is being called from the wrapper app communication layer with relevant events */
window.nativeCommunication = (encodedEvent: string) => {
  const event = JSON.parse(encodedEvent);

  switch (event.title) {
    case 'BIOMETRIC_SETUP':
      storeBiometricsPublicKey(event.publicKey);
      window.location.href = `${Paths.biometrics.root}${Paths.biometrics.segments.confirm}`;
      return;
    case 'BIOMETRIC_LOGIN':
      /*
        To be added once we have proper login flow:
          1) Login with biometrics flow.
        Event properties:
          - sessionId
          - signature
          - date
          - nonce
      */
      return;
    case 'DOCUMENTS_SCANNED':
      /*
      To be added once scan documents with new FE:
        1) Add pages to document
      Event properties:
        - documents
        - split
      */
      return;
  }

  console.warn('UNKNOWN_EVENT', { encodedEvent });
};

const postNativeMessage = (message: string): void =>
  window.ReactNativeWebView && window.ReactNativeWebView.postMessage(message);

export const getStoredBiometricsPublicKey = () => localStorage.getItem('biometricsPublicKey');

export const cleanStoredBiometricsPublicKey = () => localStorage.setItem('biometricsPublicKey', '');

export const storeBiometricsPublicKey = (publicKey: string) =>
  localStorage.setItem('biometricsPublicKey', publicKey);

export const useNativeCommunication = () => {
  const setBiometrics = (): void => postNativeMessage('BIOMETRIC_SETUP');
  const setNativeAppUser = (userData: Partial<User>): void =>
    postNativeMessage(JSON.stringify({ title: 'SET_USER', data: userData }));
  const loginWithBiometrics = (): void => postNativeMessage('BIOMETRIC_LOGIN');
  const disableBiometrics = (): void => postNativeMessage('BIOMETRIC_DISABLE');
  const storeBiometricsSessionId = (sessionId: string) =>
    postNativeMessage(`BIOMETRIC_STORE_SESSION:${sessionId}`);

  const isBiometricsConfigured = (): boolean => window.biometricsConfigured;
  const getUniqueDeviceId = (): string => window.uniqueDeviceID;
  const getAppVersion = (): number => window.appVersion || 1;
  const isNativeApp = (): boolean => window.isNativeApp;
  const getAvailableBiometrics = (): Nullable<BiometricsType> => {
    switch (window.biometricsAvailable) {
      case 'TouchID':
        return BiometricsType.TouchID;
      case 'FaceID':
        return BiometricsType.FaceID;
      case 'Biometrics':
        return BiometricsType.Biometrics;
      default:
        return null;
    }
  };

  return {
    setNativeAppUser,
    setBiometrics,
    loginWithBiometrics,
    disableBiometrics,
    storeBiometricsSessionId,
    getUniqueDeviceId,
    getAppVersion,
    getAvailableBiometrics,
    isNativeApp,
    isBiometricsConfigured,
  };
};
