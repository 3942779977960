import React, { FC, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Paths from '../../tokens/Paths';

const EnterPincode = lazy(() => import('./authenticate/enter-pincode/EnterPincode'));
const FailedPincode = lazy(() => import('./authenticate/failed-pincode/FailedPincode'));
const ForgotPincode = lazy(() => import('./authenticate/forgot-pincode/ForgotPincode'));
const ChooseAccount = lazy(() => import('./select-sign-in-account/choose-account/ChooseAccount'));
const Deregistered = lazy(() => import('./select-sign-in-account/deregistered/Deregistered'));
const EnterEmail = lazy(() => import('./select-sign-in-account/enter-email/EnterEmail'));
const EnterVerificationCode = lazy(
  () => import('./verify-account/enter-verification-code/EnterVerificationCode'),
);
const Failed = lazy(() => import('./verify-account/failed/Failed'));
const Success = lazy(() => import('./verify-account/success/Success'));

const SignInRouter: FC = () => {
  const { segments } = Paths.signIn;

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={`${segments.enterEmail}`} element={<EnterEmail />} />
        <Route path={`${segments.chooseAccount}`} element={<ChooseAccount />} />
        <Route path={`${segments.deregistered}`} element={<Deregistered />} />
        <Route path={`${segments.enterVerificationCode}`} element={<EnterVerificationCode />} />
        <Route path={`${segments.verificationCodeFailed}`} element={<Failed />} />
        <Route path={`${segments.verificationCodeSuccess}`} element={<Success />} />
        <Route path={`${segments.enterPincode}`} element={<EnterPincode />} />
        <Route path={`${segments.forgotPincode}`} element={<ForgotPincode />} />
        <Route path={`${segments.failedPincode}`} element={<FailedPincode />} />
      </Routes>
    </Suspense>
  );
};

export default SignInRouter;
