import { findSubstrings, scopeValidation } from 'src/utils';
import { PartnerAuthorisationValidation } from 'src/interfaces/Partner';
import { BizcuitError, BizcuitErrors } from 'src/tokens/BizcuitErrors';
import { PartnerScopes } from 'src/types/bizcuitApi';

const parseScopesQueryParam = (
  scopesQueryParam: string,
): {
  scopes: PartnerScopes[];
  error: Nullable<BizcuitError>;
} => {
  const possibleScopes = Object.values(PartnerScopes);

  const requestedScopes = findSubstrings(scopesQueryParam, possibleScopes);

  if (!requestedScopes.length) return { scopes: [], error: BizcuitErrors.scopeIsMissing };

  const scopesValidationErr = scopeValidation(requestedScopes);

  return {
    scopes: requestedScopes,
    error: scopesValidationErr,
  };
};

const validatePartnerAuthQueryParams = (
  params: Record<string, string>,
): PartnerAuthorisationValidation => {
  const {
    scope,
    state,
    prompt,
    clientId,
    clientReference,
    brandingClientId,
    redirectUri,
    responseType,
    codeChallenge,
    codeChallengeMethod,
    step,
    userId,
  } = params;

  let error;
  const steps = ['enter-name', 'enter-subscription', 'enter-iban'];

  if (!scope) error = BizcuitErrors.scopeIsMissing;

  const { scopes, error: scopesErr } = parseScopesQueryParam(scope);
  if (scopesErr) error = scopesErr;

  if (!clientId) error = BizcuitErrors.clientIdIsMissing;

  if (!responseType) error = BizcuitErrors.responseTypeIsMissing;

  if (!redirectUri) error = BizcuitErrors.redirectUriIsMissing;

  if (responseType !== 'code') error = BizcuitErrors.onlyResponseTypeCode;

  if (codeChallenge) {
    if (!codeChallengeMethod) error = BizcuitErrors.codeChallengeMethodIsRequired;
    if (codeChallengeMethod !== 'S256') error = BizcuitErrors.supportedCodeChallengeMethod;
  }
  if (!steps.includes(step)) error = BizcuitErrors.invalidStep;

  const validationResult = {
    error,
    partnerAuthorisation: {
      consent: {
        scopes,
        bankAccounts: [],
        administrations: [],
      },
      state: state,
      prompt: prompt,
      clientId: clientId,
      clientReference: clientReference,
      brandingClientId: brandingClientId,
      responseType: responseType,
      redirectUri: decodeURIComponent(redirectUri),
      codeChallenge: codeChallenge,
      codeChallengeMethod: codeChallengeMethod,
      step,
      userId,
    },
  };

  return validationResult;
};

export { validatePartnerAuthQueryParams };
