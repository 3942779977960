import { atom } from 'jotai';
import { invoiceStateAtom } from '../invoice-state-atoms';
import { AdministrationList } from 'src/interfaces/Invoice';

const setAdministrationListAtom = atom(
  null,
  (_get, set, administrationList: AdministrationList) => {
    set(invoiceStateAtom, { ..._get(invoiceStateAtom), administrationList });
  },
);

export { setAdministrationListAtom };
