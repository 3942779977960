import { setLocalStorageObject } from 'src/helpers';
import { LocalStorageKeys } from 'src/tokens';
import { UserState } from '../../types';

const setPersistedUserState = (state?: UserState) => {
  if (!state) return;

  setLocalStorageObject(LocalStorageKeys.user, {
    user: state.user,
    userSession: state.userSession,
  });
};

export { setPersistedUserState };
