import { FC, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router';
import Paths from 'src/tokens/Paths';
import TempExampleScreen from './TempExampleScreen';
import MainContainer from 'src/atoms/layout/main-container/MainContainer';
import { PageLayout } from 'src/atoms/layout/menu-page';
import LogoutTimer from 'src/organisms/logout-timer/LogoutTimer';
import FlowLayout from 'src/atoms/layout/menu-page/FlowLayout';

const OrchestratorStartFlow = lazy(() => import('./OrchestratorStartFlow'));
const Orchestrator = lazy(() => import('./Orchestrator'));
const OrchestratorFinishAction = lazy(() => import('./OrchestratorFinishAction'));
const OrchestratorOverview = lazy(() => import('./OrchestratorOverview'));
const OrchestratorSuccess = lazy(() => import('./OrchestratorSuccess'));
const RequestCreate = lazy(() => import('./RequestCreate'));
const RequestDetails = lazy(() => import('./RequestDetails'));
const RequestStatusPage = lazy(() => import('./RequestStatusPage'));
const RequestLinkExpired = lazy(() => import('./RequestLinkExpired'));

const OrchestratorRouter: FC = () => {
  const { segments } = Paths.orchestrator;

  return (
    <MainContainer>
      <PageLayout>
        <LogoutTimer />
        <Suspense fallback={<FlowLayout />}>
          <Routes>
            {/* TODO: remove this as part of the https://minoxsoftware.atlassian.net/browse/BR-251 */}
            <Route path={`/examples/:param1`} element={<TempExampleScreen />} />
            <Route path={segments.startFlow} element={<OrchestratorStartFlow />} />
            <Route path={segments.finishFlowAction} element={<OrchestratorFinishAction />} />
            <Route path={segments.overview} element={<OrchestratorOverview />} />
            <Route path={segments.success} element={<OrchestratorSuccess />} />
            <Route
              path={segments.request.root + segments.request.segments.create}
              element={<RequestCreate />}
            />
            <Route
              path={segments.request.root + segments.request.segments.details}
              element={<RequestDetails />}
            />
            <Route
              path={segments.request.root + segments.request.segments.status}
              element={<RequestStatusPage />}
            />
            <Route
              path={segments.request.root + segments.request.segments.linkExpired}
              element={<RequestLinkExpired />}
            />
            <Route path="*" element={<Orchestrator />} />
          </Routes>
        </Suspense>
      </PageLayout>
    </MainContainer>
  );
};

export default OrchestratorRouter;
