import { FC } from 'react';
import PageContainerProps from './PageContainer.types';
import isSamsungInternet from 'src/helpers/isSamsungInternet';

const PageContainer: FC<PageContainerProps> = ({ children }) => {
  const getPageCotainerMinHeigh = () => {
    const doesOverflowingSheetExist = [isSamsungInternet()].includes(true);

    if (doesOverflowingSheetExist) {
      return 'min-h-[calc(100vh-theme(padding.8)*4)]';
    }
    return 'min-h-[calc(100vh-theme(padding.8)*2)]';
  };

  return (
    <div
      className={`
        flex flex-col relative 
        sm:max-w-lg h-full 
      sm:bg-biz-off-white dark:bg-biz-gray-900
        sm:rounded-3xl sm:shadow-xl
        lg:ml-0 lg:mr-auto mx-auto 
        ${getPageCotainerMinHeigh()}
      `}
    >
      {children}
    </div>
  );
};

export default PageContainer;
