import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import { UpdateBillingNameResult } from './types';

export const updateBillingName = async (billing_name: string): Promise<UpdateBillingNameResult> => {
  const result: UpdateBillingNameResult = { success: false };

  try {
    await restApi.patch<UpdateBillingNameResult>(`/api/latest/user/update_billing_name`, {
      billing_name,
    });

    result.success = true;
  } catch (error: unknown) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'updateBillingName',
      error: error as Error,
    });

    const returnError = error as AxiosError;

    result.error = { message: returnError.response?.data ?? '' };
  }

  return result;
};
