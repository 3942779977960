import { useEffect, useState } from 'react';
import { LocalStorageKeys } from 'src/tokens';

const useIsDarkMode = () => {
  const initialDarkMode =
    localStorage.getItem(LocalStorageKeys.appearanceMode) === 'dark' ||
    document.documentElement.classList.contains('dark');
  const [isDarkMode, setIsDarkMode] = useState(initialDarkMode);

  useEffect(() => {
    const config = { attributes: true, childList: false, subtree: false };

    const callback: MutationCallback = (mutationList) => {
      const appearanceMode = localStorage.getItem(LocalStorageKeys.appearanceMode);
      if (appearanceMode && ['dark', 'light'].includes(appearanceMode)) {
        setIsDarkMode(appearanceMode === 'dark');
        return;
      }
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const darkMode =
            document.documentElement.classList.contains('dark') ||
            document.body.classList.contains('dark');
          setIsDarkMode(darkMode);
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(document.documentElement, config);
    observer.observe(document.body, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  return isDarkMode;
};

export default useIsDarkMode;
