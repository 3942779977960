import { AxiosError } from 'axios';
import { CaptureErrorType } from '../../../types/utils/captureError';
import { captureError } from '../../../utils';
import restApi from '../../restApi';
import { CreateInactiveRequest, CreateInactiveResponse, CreateInactiveResult } from './types';

export const inactiveSignup = async ({
  email,
  partnerId,
  partnerReference,
  brandingPartnerId,
}: CreateInactiveRequest): Promise<CreateInactiveResult> => {
  try {
    const { data } = await restApi.post<CreateInactiveResponse>(`/api/latest/user/signupinactive`, {
      email,
      partner_id: partnerId,
      partner_reference: partnerReference,
      branding_partner_id: brandingPartnerId,
    });
    const { user, userSession: session, accessToken } = data;
    const userSession = {
      id: session.session_id,
      lastUsedAt: null,
      deviceName: null,
      biometricsPublicKey: null,
    };

    return {
      success: true,
      user,
      userSession,
      accessToken,
    };
  } catch (error) {
    captureError({
      type: CaptureErrorType.Request,
      request: 'inactiveSignup',
      error: error as Error,
    });
    const returnError = error as AxiosError<{ error_name: string; message: string }>;
    return {
      success: false,
      error: {
        error_name: returnError.response?.data.error_name,
        message: returnError.response?.data.message ?? '',
      },
    };
  }
};
