import { FC } from 'react';
import { BizcuitIcon } from 'src/atoms/icons/BizcuitIcon';
import { SpinLoaderProps } from './SpinLoader.types';

const SpinLoader: FC<SpinLoaderProps> = ({ succeeded, failed }) => (
  <div className="flex items-center justify-center mb-6 mt-9">
    <div
      className={`bg-gradient-to-b ${
        failed && !succeeded ? 'from-red-300 to-red-500' : 'from-green-300 to-green-500'
      } h-32 w-32 rounded-full relative`}
    >
      <BizcuitIcon icon={succeeded ? 'succeeded' : failed ? 'error-loading' : 'loading'} />
    </div>
  </div>
);

export default SpinLoader;
